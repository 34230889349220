import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import closePauseModal from '@redux/thunks/collections/closePauseModal';
import pause from '@redux/thunks/collections/pause';
import { COLLECTION_FREQUENCIES } from 'config/collections';
import { modalKeys, modalTrackingKeys } from 'config/modals';
import { COLLECTION_ACTIONS, LUXON_FREQUENCY_MAP, formatCollectionFullDate, } from 'helpers/collections';
import Debug from 'helpers/debug';
import { translateApiErrors } from 'helpers/errors';
import { useModal, useTimeout } from 'hooks/application';
import { DEBOUNCE_TIMEOUT } from 'components/Baskets/List/Content/Article/ArticleQuantity';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import { ModalFooter, ModalTitle } from 'components/common/Modal/ConfirmationModal';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
const debug = Debug('components:collections:actions:pause-confirmation');
const PauseConfirmation = ({ collectionId, storeId }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isPauseButtonPressed, setIsPauseButtonPressed] = useState(false);
    const { closeModal } = useModal(modalKeys.PAUSE_COLLECTION);
    const { businessId, name: storeName, timezone: selectedStoreTimezone, } = useAppSelector((state) => state.stores.items[storeId]);
    const { name: businessName } = useAppSelector((state) => state.stores.businesses[businessId]);
    const { frequency, validEndDates } = useAppSelector((state) => state.collections.items[collectionId]);
    const { selectedDate, selectedIncrement } = useAppSelector((state) => state.collections[COLLECTION_ACTIONS.PAUSE]);
    const frequencyKey = LUXON_FREQUENCY_MAP[frequency];
    const dateFrom = useMemo(() => {
        if (selectedDate === 'immediate') {
            const firstValidDate = validEndDates[0];
            if (frequency === COLLECTION_FREQUENCIES.FORTNIGHTLY) {
                return DateTime.fromISO(firstValidDate).minus({ week: 2 });
            }
            return DateTime.fromISO(firstValidDate).minus({ [frequencyKey]: 1 });
        }
        else {
            return selectedDate;
        }
    }, [selectedDate]);
    const skippedDates = useMemo(() => {
        const result = [];
        for (let i = 1; i <= selectedIncrement; i++) {
            if (frequency === COLLECTION_FREQUENCIES.FORTNIGHTLY) {
                const date = DateTime.fromISO(dateFrom).plus({ week: i * 2 });
                result.push(date);
            }
            else {
                const date = DateTime.fromISO(dateFrom).plus({ [frequencyKey]: i });
                result.push(date);
            }
        }
        return result;
    }, [selectedIncrement, dateFrom, frequency]);
    const startDate = useMemo(() => {
        if (frequency === COLLECTION_FREQUENCIES.FORTNIGHTLY) {
            return DateTime.fromISO(dateFrom).plus({ week: (selectedIncrement + 1) * 2 });
        }
        return DateTime.fromISO(dateFrom).plus({ [frequencyKey]: selectedIncrement + 1 });
    }, [selectedIncrement, dateFrom, frequency]);
    const errors = useAppSelector((state) => state.collections.pause.errors);
    const handleConfirmClick = () => {
        setIsPauseButtonPressed(true);
        dispatch(pause({ collectionId, selectedDate, selectedIncrement })).catch(debug);
    };
    const handleCloseClick = () => {
        try {
            closeModal();
            dispatch(closePauseModal());
        }
        catch {
            debug();
        }
    };
    useTimeout(() => {
        setIsPauseButtonPressed(false);
    }, DEBOUNCE_TIMEOUT, isPauseButtonPressed === true);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(ModalTitle, { children: t('your-collections.page.show.pause-modal.confirmation-modal.title') }), _jsxs("div", { className: "pause-confirmation-details", children: [_jsx("p", { dangerouslySetInnerHTML: {
                                    __html: t('your-collections.page.show.pause-modal.confirmation-modal.pause-details_html', { store: `${businessName}, ${storeName}` }),
                                } }), _jsx("ul", { children: skippedDates.map((skippedDate, index) => (_jsx("li", { children: _jsx("span", { children: formatCollectionFullDate({
                                            date: skippedDate,
                                            timezone: selectedStoreTimezone,
                                        }) }) }, index))) }), _jsx("p", { children: t('your-collections.page.show.pause-modal.confirmation-modal.start-details') }), _jsx("span", { children: formatCollectionFullDate({
                                    date: startDate,
                                    timezone: selectedStoreTimezone,
                                }) })] }), errors && (_jsx(Alert, { variant: "error", children: _jsx(SetInnerHtmlSafe, { children: translateApiErrors({ t, errors }) }) }))] }), _jsxs(ModalFooter, { children: [_jsx(Button, { "data-tracking": modalTrackingKeys.reject, onClick: handleCloseClick, secondary: true, children: t('your-collections.page.show.pause-modal.confirmation-modal.buttons.cancel') }), _jsx(Button, { "data-tracking": modalTrackingKeys.confirm, onClick: handleConfirmClick, disabled: isPauseButtonPressed === true, children: t('your-collections.page.show.pause-modal.confirmation-modal.buttons.confirm') })] })] }));
};
export default PauseConfirmation;
