// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LandingPageScene-module--commonPadding--hKUdr{max-width:58.375rem;margin:0 auto;padding:0 .75rem}.LandingPageScene-module--highlightBackground--pJEoG{background-color:#f9f9f9}.LandingPageScene-module--landingPageScene--FGSAU{padding:2.5rem 0 5rem}@media(min-width: 600px){.LandingPageScene-module--landingPageScene--FGSAU{padding-top:3rem;padding-bottom:7.5rem}}@media(max-width: 599px){.LandingPageScene-module--landingPageScene--FGSAU .LandingPageScene-module--volunteeringInfo--syled{margin-top:1.5rem;margin-bottom:2rem}}.LandingPageScene-module--landingPageScene--FGSAU .LandingPageScene-module--landingLoginCard--nFUTb{margin-top:2.5rem;max-width:100%}.LandingPageScene-module--landingPageScene--FGSAU .LandingPageScene-module--landingLoginCard--nFUTb .LandingPageScene-module--loginIcon--Lko9O{color:#ff4c70}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commonPadding": "LandingPageScene-module--commonPadding--hKUdr",
	"highlightBackground": "LandingPageScene-module--highlightBackground--pJEoG",
	"landingPageScene": "LandingPageScene-module--landingPageScene--FGSAU",
	"volunteeringInfo": "LandingPageScene-module--volunteeringInfo--syled",
	"landingLoginCard": "LandingPageScene-module--landingLoginCard--nFUTb",
	"loginIcon": "LandingPageScene-module--loginIcon--Lko9O"
};
export default ___CSS_LOADER_EXPORT___;
