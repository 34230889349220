import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import loginMagic from '@redux/thunks/users/loginMagic';
import { ERROR } from 'config/alert';
import { LOGIN_TYPES } from 'config/loginTypes';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import SceneHeader from 'components/common/SceneHeader';
import { Spacer } from 'components/layout/primitives';
const { MAGIC } = LOGIN_TYPES;
const MagicLoginScene = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const authenticationError = useAppSelector((state) => state.users.authenticationError);
    const { token } = useParams();
    const handleLogin = () => {
        dispatch(loginMagic(token));
    };
    return (_jsxs("div", { className: "container", children: [_jsx(SceneHeader, { title: t('magic_login.title') }), authenticationError === MAGIC && (_jsxs(_Fragment, { children: [_jsx(Spacer, { x: "1rem" }), _jsx(Alert, { variant: ERROR, children: t('login.attempt_magic_login.error') }), _jsx(Spacer, { x: "1rem" })] })), _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-content", children: _jsx("p", { children: t('magic_login.description') }) }), _jsx("div", { className: "card-action", children: _jsx(Button, { onClick: handleLogin, children: t('magic_login.login') }) })] })] }));
};
export default MagicLoginScene;
