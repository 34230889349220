import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdCheckCircleOutline, MdEvent, MdHistory, MdLoop, MdOutlineRecommend, } from 'react-icons/md';
import clsx from 'clsx';
import { COLLECTION_TYPES } from 'config/collections';
import { DISTANCE_PREFERENCE_KEY } from 'config/user';
import { getCollectionFrequencyTranslation, getCollectionTypeTranslation, } from 'helpers/collections';
import { formatLongDate } from 'helpers/time';
import { useIsMobile } from 'hooks/application';
import { usePreference } from 'hooks/preferences';
import Distance from 'components/AvailableCollections/Distance';
import Label from 'components/common/Label';
import styles from './AvailableCollectionInformation.module.scss';
import InductionProgress from './InductionProgress';
import InformationIcon from './InformationIcon';
import Location from './Location';
export const BUSINESS_ICELAND = 'Iceland';
export const BUSINESS_TESCO = 'Tesco';
const AvailableCollectionInformation = ({ type, homeLocation, storeLocation, frequency, nextCollection, storeAddress, storeTimezone, completedInductionsCount, inductionsCount, businessName, className, donationConfirmedAt, }) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const { preference: distance } = usePreference(DISTANCE_PREFERENCE_KEY);
    const distanceUnits = distance.value;
    const getTypeIcon = () => {
        if (type === COLLECTION_TYPES.ONGOING) {
            return (_jsx(InformationIcon, { Icon: MdLoop, collectionType: type, testId: "loopIcon" }));
        }
        else if (type === COLLECTION_TYPES.ADHOC || type === COLLECTION_TYPES.TEMPORARY) {
            return (_jsx(InformationIcon, { Icon: MdHistory, collectionType: type, testId: "historyIcon" }));
        }
        return '';
    };
    const getDateText = () => {
        if (type === COLLECTION_TYPES.ONGOING) {
            return _jsx("div", { children: t('collection_slots.partials.slot.next_collection') });
        }
        else if (type === COLLECTION_TYPES.ADHOC) {
            return _jsx("div", { children: t('collection_slots.partials.slot.last_collection') });
        }
        return null;
    };
    const isCollectionTesco = businessName === BUSINESS_TESCO;
    const isIdealFirstCollection = type === COLLECTION_TYPES.ADHOC || businessName === BUSINESS_ICELAND;
    return (_jsxs("div", { "data-testid": `collection-type-${type}`, children: [donationConfirmedAt !== null && (_jsx(Label, { icon: _jsx(MdCheckCircleOutline, {}), text: t('common.label.food-confirmed'), variant: "green", className: styles.foodConfirmedLabel })), _jsxs("div", { className: clsx(styles.availableCollectionInformation, {
                    [className]: className !== undefined,
                }), children: [_jsxs("div", { children: [_jsxs("div", { className: styles.collectionLocationDetails, children: [_jsx(Location, { type: type, storeAddress: storeAddress }), _jsx(Distance, { baseLocation: homeLocation, destinationLocation: storeLocation, units: distanceUnits })] }), frequency && (_jsxs("div", { className: clsx(styles.collectionTypeAndFrequency, styles[type]), children: [_jsx("span", { children: getTypeIcon() }), _jsxs("div", { children: [_jsx("div", { children: _jsx("strong", { children: getCollectionTypeTranslation({ t, type }) }) }), type === COLLECTION_TYPES.ONGOING && (_jsx("div", { className: styles.frequency, children: `(${getCollectionFrequencyTranslation({ t, frequency })})` }))] })] })), isMobile === false && isIdealFirstCollection === true && isCollectionTesco !== true && (_jsx(Label, { icon: _jsx(MdOutlineRecommend, {}), text: t('common.label.ideal-for-new') }))] }), _jsxs("div", { children: [_jsxs("div", { className: clsx(styles.info, styles.nextCollection), children: [_jsx("span", { children: _jsx(InformationIcon, { testId: "eventIcon", Icon: MdEvent, collectionType: type }) }), _jsxs("div", { children: [_jsx("span", { className: styles.nextCollectionText, children: getDateText() }), _jsx("strong", { "data-testid": "available-collection-date", children: formatLongDate(nextCollection, storeTimezone) })] })] }), inductionsCount > 0 && (_jsx(InductionProgress, { completedInductionsCount: completedInductionsCount, inductionsCount: inductionsCount, collectionType: type })), isMobile === true && isIdealFirstCollection === true && (_jsx(Label, { icon: _jsx(MdOutlineRecommend, {}), text: t('common.label.ideal-for-new') }))] })] })] }));
};
export default AvailableCollectionInformation;
