import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Requester from 'components/Baskets/List/Header/Requester';
const Header = ({ section, active }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: "card-header", children: [_jsx(Requester, { basketId: section.id }), _jsx("a", { className: "card-header-content-label", children: active === true
                    ? t('baskets.content-button.collapse')
                    : t('baskets.content-button.expand') })] }));
};
export default memo(Header);
