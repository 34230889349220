import { STORES_FETCH_START, STORES_FETCH_SUCCESS } from '@redux/actionTypes';
import { cleanStores } from '@redux/utilities/handleStoreData';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { CANCELED_ERROR, loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:stores:fetchMyStores');
const fetchMyStores = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                if (error.name === CANCELED_ERROR) {
                    debug(`Cancelled duplicate request`);
                    return;
                }
                const reason = loggableError(error);
                debug(`Failed fetching user stores`, reason);
                reject(reason);
            };
            const baseUrl = window.location.origin;
            const url = new URL(`${baseUrl}/api/v1/stores`);
            dispatch({
                type: STORES_FETCH_START,
            });
            return axios
                .get(url.toString())
                .then((response) => {
                const { data } = response;
                const stores = cleanStores(data);
                dispatch({
                    type: STORES_FETCH_SUCCESS,
                    payload: {
                        stores,
                        onlyMyStores: true,
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
export default fetchMyStores;
