// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Link-module--iconAndTitle--ZEUTY{display:flex;padding-left:.375rem;margin-left:.375rem;align-items:center}@media(min-width: 992px){.Link-module--iconAndTitle--ZEUTY{padding-left:.5rem;margin-left:.5rem}}.Link-module--iconAndTitle--ZEUTY:hover{background-color:#f3f1f4;border-radius:10px;width:fit-content}.Link-module--iconAndTitle--ZEUTY:hover .Link-module--link--KVCxO,.Link-module--iconAndTitle--ZEUTY:hover .Link-module--icon--YW5Q6{color:#3b1869}.Link-module--iconAndTitle--ZEUTY:hover .Link-module--link--KVCxO{padding-right:.5rem}.Link-module--iconAndTitle--ZEUTY:hover a{color:#fff !important}.Link-module--iconAndTitle--ZEUTY .Link-module--link--KVCxO{color:#6f6f6f;font-weight:500;line-height:2.125rem;font-size:.875rem}@media(min-width: 992px){.Link-module--iconAndTitle--ZEUTY .Link-module--link--KVCxO{font-size:1rem}}.Link-module--iconAndTitle--ZEUTY .Link-module--icon--YW5Q6{margin-right:.25rem;color:#6f6f6f;width:1.625rem}@media only screen and (min-width: 992px){.Link-module--iconAndTitle--ZEUTY .Link-module--icon--YW5Q6{margin-right:.5rem;width:initial;font-size:1.5rem}}.Link-module--active--WvDlr .Link-module--iconAndTitle--ZEUTY{background-color:#f3f1f4;border-radius:10px;width:fit-content}.Link-module--active--WvDlr .Link-module--iconAndTitle--ZEUTY .Link-module--link--KVCxO,.Link-module--active--WvDlr .Link-module--iconAndTitle--ZEUTY .Link-module--icon--YW5Q6{color:#3b1869}.Link-module--active--WvDlr .Link-module--iconAndTitle--ZEUTY .Link-module--link--KVCxO{padding-right:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconAndTitle": "Link-module--iconAndTitle--ZEUTY",
	"link": "Link-module--link--KVCxO",
	"icon": "Link-module--icon--YW5Q6",
	"active": "Link-module--active--WvDlr"
};
export default ___CSS_LOADER_EXPORT___;
