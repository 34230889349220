import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdErrorOutline, MdOutlineCheckCircle } from 'react-icons/md';
import { trackEvent } from 'services/Analytics';
import Badge from 'components/common/Badge';
import { Stack } from 'components/layout/primitives';
const QuizQuestion = ({ index, questionText, answers, questionCount, isInductionCompleted, isRetry, answersChanged, setAnswersChanged, setCorrectAnswerAlert, }) => {
    const { t } = useTranslation();
    const { register, getValues } = useFormContext();
    const handleAnswerSelection = (answer) => {
        if (isAnswerCorrect() === false) {
            setAnswersChanged({
                ...answersChanged,
                [index]: true,
            });
        }
        trackEvent('INDUCTION_QUIZ_ANSWER', {
            question: questionText,
            answer: answer.body,
            refresh: isInductionCompleted,
        });
    };
    const isAnswerCorrect = () => {
        const correctAnswer = answers.find(({ isCorrect }) => isCorrect === true);
        const userAnswer = getValues()[`question-${index}`];
        if (correctAnswer.body === userAnswer) {
            return true;
        }
        return false;
    };
    const displayBadge = () => {
        if (isAnswerCorrect() === true) {
            return (_jsx(Badge, { icon: _jsx(MdOutlineCheckCircle, {}), text: t('inductions.page.show.quiz.success_badge'), variant: "success" }));
        }
        else if (isAnswerCorrect() === false) {
            return (_jsx(Badge, { icon: _jsx(MdErrorOutline, {}), text: t('inductions.page.show.quiz.error_badge'), variant: "error" }));
        }
        return null;
    };
    useEffect(() => {
        if (isAnswerCorrect() === true && isRetry === true && answersChanged[index] === undefined) {
            setCorrectAnswerAlert(true);
        }
    }, []);
    return (_jsx("div", { className: "card", style: { padding: '1rem' }, children: _jsxs(Stack, { gap: "1rem", children: [_jsxs(Stack, { gap: "0.5rem", children: [_jsx("h2", { className: "quiz-question-heading", children: t('inductions.page.show.quiz.question', { count: index + 1 }) }), _jsx("strong", { className: "quiz-question-count", children: t('inductions.page.show.quiz.of', { count: questionCount }) })] }), _jsx("h3", { children: questionText }), _jsxs("div", { children: [answers.map((answer, answerIndex) => {
                            return (_jsx("div", { className: "quiz-answer", children: _jsxs("label", { children: [_jsx("input", { ...register(`question-${index}`, { required: true }), className: "with-gap", type: "radio", value: answer.body, onClick: () => handleAnswerSelection(answer), disabled: isRetry && isAnswerCorrect() && answersChanged[index] === undefined }), _jsx("span", { children: answer.body })] }) }, answerIndex));
                        }), isRetry === true && answersChanged[index] === undefined && displayBadge()] })] }) }));
};
export default QuizQuestion;
