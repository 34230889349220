// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegisteringIsEasy-module--registeringIsEasy--X9rum{display:flex;flex-direction:column;text-align:center;margin-top:2.5rem;color:#474747}.RegisteringIsEasy-module--registeringIsEasy--X9rum h3{font-weight:600}@media(min-width: 600px){.RegisteringIsEasy-module--registeringIsEasy--X9rum h3{margin-bottom:.5rem}}.RegisteringIsEasy-module--registeringIsEasy--X9rum p{font-weight:400}@media(min-width: 600px){.RegisteringIsEasy-module--registeringIsEasy--X9rum p{display:flex;align-self:center;max-width:34.375rem}}.RegisteringIsEasy-module--registeringIsEasy--X9rum a{display:flex;justify-content:center}.RegisteringIsEasy-module--registeringIsEasy--X9rum button{width:fit-content;display:flex;align-self:center;margin-top:1rem}@media(min-width: 600px){.RegisteringIsEasy-module--registeringIsEasy--X9rum button{width:22.25rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registeringIsEasy": "RegisteringIsEasy-module--registeringIsEasy--X9rum"
};
export default ___CSS_LOADER_EXPORT___;
