import { createSelector } from '@reduxjs/toolkit';
const selectPreferenceFetchingStatus = (state) => {
    return state.preferences.preferencesFetchingStatus;
};
const selectAllPreferences = (state) => {
    return state.preferences.userPreferences;
};
const selectPreference = (allPreferences, preferenceName) => {
    return allPreferences.find((userPreference) => userPreference.name === preferenceName);
};
const selectPreferenceWithFetchingStatus = createSelector([
    selectPreferenceFetchingStatus,
    selectAllPreferences,
    (_state, preferenceName) => preferenceName,
], (fetchingStatus, allPreferences, preferenceName) => {
    let userPreference = selectPreference(allPreferences, preferenceName);
    if (userPreference === undefined) {
        userPreference = false;
    }
    return { userPreference, fetchingStatus };
});
export { selectPreferenceWithFetchingStatus };
