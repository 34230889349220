import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
const Collapsible = ({ collapsedToggle, expandedToggle, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    if (isExpanded === true) {
        return (_jsxs(_Fragment, { children: [_jsx("div", { children: children }), _jsx("button", { className: "no-button-styling", type: "button", onClick: () => setIsExpanded(false), children: expandedToggle })] }));
    }
    return (_jsx(_Fragment, { children: _jsx("button", { className: "no-button-styling", type: "button", onClick: () => setIsExpanded(true), children: collapsedToggle }) }));
};
export default Collapsible;
