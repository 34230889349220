// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyCollectionCard-module--root--Ld7v_{margin-bottom:1rem}.MyCollectionCard-module--root--Ld7v_ .MyCollectionCard-module--content--Tyt7R{display:flex;flex-direction:column;border-radius:16px;margin-top:1.5rem}@media(min-width: 600px){.MyCollectionCard-module--root--Ld7v_ .MyCollectionCard-module--content--Tyt7R{flex-direction:row;justify-content:space-between}}.MyCollectionCard-module--root--Ld7v_ .MyCollectionCard-module--content--Tyt7R .MyCollectionCard-module--buttons--Xvnn8{gap:1rem;display:flex;flex-direction:row}@media(min-width: 600px){.MyCollectionCard-module--root--Ld7v_ .MyCollectionCard-module--content--Tyt7R .MyCollectionCard-module--buttons--Xvnn8{justify-content:flex-end;flex-direction:column;gap:.75rem;margin-left:1.5rem}}.MyCollectionCard-module--root--Ld7v_ .MyCollectionCard-module--content--Tyt7R .MyCollectionCard-module--buttons--Xvnn8 .MyCollectionCard-module--btn--qK91q{display:flex;align-items:center;justify-content:center;height:40px;line-height:18px;font-size:.875rem}@media(min-width: 600px){.MyCollectionCard-module--root--Ld7v_ .MyCollectionCard-module--content--Tyt7R .MyCollectionCard-module--buttons--Xvnn8 .MyCollectionCard-module--btn--qK91q{width:212px;height:51px;font-size:1.125rem}}.MyCollectionCard-module--root--Ld7v_ .MyCollectionCard-module--content--Tyt7R .MyCollectionCard-module--collectionInfo--KkKaf{flex-grow:1}@media(max-width: 599px){.MyCollectionCard-module--root--Ld7v_ .MyCollectionCard-module--content--Tyt7R .MyCollectionCard-module--collectionInfo--KkKaf{padding-bottom:2.5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "MyCollectionCard-module--root--Ld7v_",
	"content": "MyCollectionCard-module--content--Tyt7R",
	"buttons": "MyCollectionCard-module--buttons--Xvnn8",
	"btn": "MyCollectionCard-module--btn--qK91q",
	"collectionInfo": "MyCollectionCard-module--collectionInfo--KkKaf"
};
export default ___CSS_LOADER_EXPORT___;
