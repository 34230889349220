import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchInduction from '@redux/thunks/inductions/fetchInduction';
import { trackEvent } from 'services/Analytics';
import { FETCHING_STATUSES } from 'config/api';
import { INDUCTIONS_URL } from 'config/routes';
import { hasYoutubeVideo } from 'helpers/inductions';
import carrotWithStore from 'images/carrotWithStore.webp';
import AboutInduction from 'components/Inductions/AboutInduction';
import AdminContent from 'components/Inductions/AdminContent';
import InductionQuiz from 'components/Inductions/InductionQuiz';
import Button from 'components/common/Button';
import CardWithButton from 'components/common/Cards/CardWithButton';
import LoadingSpinner from 'components/common/LoadingSpinner';
import SceneHeader from 'components/common/SceneHeader';
const { LOADING, SUCCESS, IDLE, FAILED } = FETCHING_STATUSES;
const InductionDetailsScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { inductionId } = useParams();
    const [isQuizVisible, setIsQuizVisible] = useState(false);
    const [isRetryLocked, setIsRetryLocked] = useState(true);
    const [isRetry, setIsRetry] = useState(false);
    const [isRewatch, setIsRewatch] = useState(false);
    const videoRef = useRef(null);
    const inductionsFetchingStatus = useAppSelector((state) => state.inductions.inductionsFetchingStatus);
    const inductionData = useAppSelector((state) => state.inductions.items[inductionId]);
    const handleStartQuizClick = () => {
        setIsQuizVisible(true);
        trackEvent('INDUCTION_QUIZ_START', {
            induction_name: inductionData.title,
            refresh: inductionData.isComplete,
        });
    };
    useEffect(() => {
        dispatch(fetchInduction(inductionId));
    }, []);
    const isLoadingInduction = inductionsFetchingStatus === IDLE || inductionsFetchingStatus === LOADING;
    const playYoutubeVideo = () => {
        videoRef.current?.playVideo();
    };
    const pauseYoutubeVideo = () => {
        videoRef.current?.pauseVideo();
    };
    return (_jsxs("div", { id: "induction-details-scene", children: [isLoadingInduction === true && _jsx(LoadingSpinner, { containerHeight: "400px" }), inductionsFetchingStatus === SUCCESS && inductionData?.content !== undefined && (_jsxs(_Fragment, { children: [_jsx(SceneHeader, { title: inductionData.title }), _jsx(AdminContent, { content: inductionData.content, inductionId: Number(inductionId), inductionTitle: inductionData.title, ref: videoRef, setIsRetryLocked: setIsRetryLocked, isRewatch: isRewatch }), isQuizVisible === true ? (_jsx(InductionQuiz, { inductionId: Number(inductionId), isRetryLocked: isRetryLocked, setIsRetryLocked: setIsRetryLocked, pauseYoutubeVideo: pauseYoutubeVideo, playYoutubeVideo: playYoutubeVideo, isRetry: isRetry, setIsRetry: setIsRetry, setIsRewatch: setIsRewatch })) : hasYoutubeVideo(inductionData.content) ? (_jsx(AboutInduction, { handleStartQuizClick: handleStartQuizClick, questionCount: inductionData?.questions.length })) : (_jsx(Button, { className: "induction-start-quiz-button", onClick: handleStartQuizClick, children: t('inductions.page.show.button-start') }))] })), inductionsFetchingStatus === FAILED && (_jsx(CardWithButton, { title: t('inductions.page.show.unavailable.card.title'), description: t('inductions.page.show.unavailable.card.description'), description2: t('inductions.page.show.unavailable.card.description2'), buttonText: t('inductions.page.show.unavailable.card.button-text'), buttonLink: INDUCTIONS_URL, icon: carrotWithStore }))] }));
};
export default memo(InductionDetailsScene);
