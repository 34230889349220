import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchUser from '@redux/thunks/users/fetchUser';
import Debug from 'helpers/debug';
import { getCurrentUserId } from 'helpers/user';
const debug = Debug('components:common:user-avatar');
const UserAvatar = ({ className = '', userId, imageUrl }) => {
    const { t } = useTranslation();
    let avatarUrl = `https://cdn.olioex.com/${t('defaults.default-avatar')}`;
    const user = useAppSelector((state) => state.users?.items[userId]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (imageUrl === undefined && userId !== undefined && Number(getCurrentUserId()) !== userId) {
            dispatch(fetchUser(userId)).catch(debug);
        }
    }, []);
    if (imageUrl !== undefined) {
        avatarUrl = imageUrl;
    }
    else if (user !== undefined) {
        avatarUrl = user?.avatar?.small;
    }
    return (_jsx("div", { className: `icon ${className}`, children: _jsx("img", { alt: "", "data-testid": "userAvatar", src: avatarUrl }) }));
};
export default UserAvatar;
