// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClaimCollectionSuccessModal-module--claimCollectionSuccessModal--mUFzF .ClaimCollectionSuccessModal-module--viewCollectionDetailsButton--RStbi{position:fixed;bottom:2.5rem}@media(min-width: 600px){.ClaimCollectionSuccessModal-module--claimCollectionSuccessModal--mUFzF .ClaimCollectionSuccessModal-module--viewCollectionDetailsButton--RStbi{position:unset}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"claimCollectionSuccessModal": "ClaimCollectionSuccessModal-module--claimCollectionSuccessModal--mUFzF",
	"viewCollectionDetailsButton": "ClaimCollectionSuccessModal-module--viewCollectionDetailsButton--RStbi"
};
export default ___CSS_LOADER_EXPORT___;
