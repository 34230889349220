// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoBannerWithButton-module--infoBannerWithButton--BYa4V{margin-bottom:40px}.InfoBannerWithButton-module--infoBannerWithButton--BYa4V .InfoBannerWithButton-module--cardContent--P4pr7{display:flex;flex-direction:row;justify-content:space-between;align-items:center;padding:1rem}.InfoBannerWithButton-module--infoBannerWithButton--BYa4V .InfoBannerWithButton-module--cardContent--P4pr7 p{padding:0;padding-right:1rem}@media(min-width: 992px){.InfoBannerWithButton-module--infoBannerWithButton--BYa4V .InfoBannerWithButton-module--cardContent--P4pr7 p{padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBannerWithButton": "InfoBannerWithButton-module--infoBannerWithButton--BYa4V",
	"cardContent": "InfoBannerWithButton-module--cardContent--P4pr7"
};
export default ___CSS_LOADER_EXPORT___;
