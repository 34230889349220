// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes GenericModal-module--fade-in-animation--VByas{0%{opacity:0}100%{opacity:1}}.GenericModal-module--modalContainer--REmfS{position:fixed;display:flex;justify-content:center;height:100%;width:100%;left:0;top:0;z-index:1000;animation:GenericModal-module--fade-in-animation--VByas ease .15s;animation-iteration-count:1;animation-fill-mode:forwards}.GenericModal-module--modalContainer--REmfS .GenericModal-module--genericModal--PPmzA{position:fixed;max-height:100%;z-index:1000;padding:0 1rem}@media only screen and (max-width: 777px){.GenericModal-module--modalContainer--REmfS .GenericModal-module--genericModal--PPmzA{inset:0;height:100%;width:100%;overflow:scroll}}@media only screen and (min-width: 777px){.GenericModal-module--modalContainer--REmfS .GenericModal-module--genericModal--PPmzA{top:50%;transform:translateY(-50%);width:777px;box-shadow:0 4px 10px rgba(0,0,0,.1);border-radius:16px}}@media only screen and (min-width: 777px){.GenericModal-module--modalContainer--REmfS .GenericModal-module--genericModal--PPmzA{padding:1.5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "GenericModal-module--modalContainer--REmfS",
	"fadeInAnimation": "GenericModal-module--fade-in-animation--VByas",
	"genericModal": "GenericModal-module--genericModal--PPmzA"
};
export default ___CSS_LOADER_EXPORT___;
