// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Distance-module--root--AKB7c{display:flex}.Distance-module--root--AKB7c .Distance-module--collectionDistance--jdpWe{margin-left:2rem;padding-top:.5rem}.Distance-module--root--AKB7c .Distance-module--collectionDistance--jdpWe a{color:#ff4c70}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Distance-module--root--AKB7c",
	"collectionDistance": "Distance-module--collectionDistance--jdpWe"
};
export default ___CSS_LOADER_EXPORT___;
