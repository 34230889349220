import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAutoFixHigh } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchMyInductions from '@redux/thunks/inductions/fetchMyInductions';
import { MESSAGE } from 'config/alert';
import { FETCHING_STATUSES } from 'config/api';
import { getCompletedInductions, getNonCompletedInductions } from 'helpers/inductions';
import LemonIcon from 'images/Lemon.svg';
import InductionsList from 'components/Inductions/InductionsList';
import CollapsibleAlert from 'components/common/CollapsibleAlert';
import InfoItem, { infoItemTypes } from 'components/common/InfoItem';
import LoadingSpinner from 'components/common/LoadingSpinner';
import SceneHeader from 'components/common/SceneHeader';
import { Stack } from 'components/layout/primitives';
import styles from './InductionsListScene.module.scss';
const { IDLE, LOADING } = FETCHING_STATUSES;
const { EMPTY } = infoItemTypes;
const InductionsListScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const inductions = useAppSelector((state) => state.inductions.items);
    const activeInductions = useMemo(() => Object.values(inductions).filter((induction) => induction.isActive), [inductions]);
    const completedInductions = useMemo(() => getCompletedInductions(activeInductions), [activeInductions]);
    const nonCompletedInductions = useMemo(() => getNonCompletedInductions(activeInductions), [activeInductions]);
    const noInductions = completedInductions.length === 0 && nonCompletedInductions.length === 0;
    useEffect(() => {
        dispatch(fetchMyInductions());
    }, []);
    const inductionsFetchingStatus = useAppSelector((state) => state.inductions.inductionsFetchingStatus);
    return (_jsxs("div", { id: "inductions-scene", children: [_jsx(SceneHeader, { title: t('inductions.page.index.new-title') }), _jsx("div", { className: styles.collapsibleAlert, children: _jsx(CollapsibleAlert, { title: t('inductions.scene.alert.title'), description: t('inductions.scene.alert.description'), variant: MESSAGE, icon: _jsx(MdAutoFixHigh, {}), children: t('inductions.scene.alert.extended-description') }) }), inductionsFetchingStatus === IDLE || inductionsFetchingStatus === LOADING ? (_jsx(LoadingSpinner, { containerHeight: "400px" })) : noInductions === true ? (_jsx(InfoItem, { type: EMPTY, title: t('inductions.page.index.empty-title'), description1: t('inductions.page.index.empty-text'), icon: LemonIcon })) : (_jsxs(Stack, { gap: "1rem", children: [_jsx(InductionsList, { title: t('inductions.page.index.to-do-title'), inductions: nonCompletedInductions }), _jsx(InductionsList, { title: t('inductions.page.index.completed-title'), inductions: completedInductions })] }))] }));
};
export default InductionsListScene;
