// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JoinIntroScene-module--joinIntroScene--FW86b{padding:2.5rem .75rem 5rem}@media(min-width: 600px){.JoinIntroScene-module--joinIntroScene--FW86b{padding-top:3rem;padding-bottom:11.875rem;max-width:58.375rem;margin:0 auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"joinIntroScene": "JoinIntroScene-module--joinIntroScene--FW86b"
};
export default ___CSS_LOADER_EXPORT___;
