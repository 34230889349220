// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wrapper-module--sidebarWrapper--oyPrB{background-color:#fff;position:relative;padding-right:.5rem;width:250px;flex-shrink:0}.Wrapper-module--sidebarWrapper--oyPrB ul{margin:0}.Wrapper-module--sidebarWrapper--oyPrB li{padding-bottom:1rem}.Wrapper-module--sidebarWrapper--oyPrB li .Wrapper-module--active--IoPj1{background-color:rgba(0,0,0,0)}@media(min-width: 992px){.Wrapper-module--sidebarWrapper--oyPrB li{padding-bottom:19px}}.Wrapper-module--sidebarWrapper--oyPrB ul li{background-color:#fff}@media(max-width: 991px){.Wrapper-module--sidebarWrapper--oyPrB{width:214px;z-index:1000;position:absolute;left:0;top:0;height:100%;overflow-y:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarWrapper": "Wrapper-module--sidebarWrapper--oyPrB",
	"active": "Wrapper-module--active--IoPj1"
};
export default ___CSS_LOADER_EXPORT___;
