import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MdOutlineCancel } from 'react-icons/md';
import clsx from 'clsx';
import Check from 'images/checkInCircleColour.svg';
import GenericModal from 'components/common/Modal/GenericModal';
import styles from './SuccessModal.module.scss';
const SuccessModal = ({ className, onClose, children, modalKey }) => {
    return (_jsxs(GenericModal, { closeModal: onClose, className: clsx(styles.successModal, className), modalKey: modalKey, children: [_jsx("button", { type: "button", className: clsx('no-button-styling', styles.modalClose), onClick: onClose, "aria-label": "Close", children: _jsx(MdOutlineCancel, { "data-testid": "closeIcon" }) }), _jsx("img", { src: Check, alt: "" }), children] }));
};
export default SuccessModal;
