import { BASKET_ARTICLE_FETCH_SUCCESS } from '@redux/actionTypes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { CANCELED_ERROR, loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:baskets:fetchBasketArticle');
export default (basketArticleId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                if (error.name === CANCELED_ERROR) {
                    debug(`Cancelled duplicate request`);
                    return;
                }
                const reason = loggableError(error);
                debug(`Failed fetching basket article ${basketArticleId}`, reason);
                reject(reason);
            };
            return axios
                .get(`/api/v1/basket_articles/${basketArticleId}`)
                .then((response) => {
                const { data } = response;
                dispatch({
                    type: BASKET_ARTICLE_FETCH_SUCCESS,
                    payload: {
                        basketArticleId,
                        articleId: data.article_id,
                        available: data.remaining_items,
                        quantity: data.items,
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
