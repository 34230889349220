import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import styles from './Inline.module.scss';
const Inline = ({ children, gap }) => {
    const style = { '--gap': gap };
    const childrenArray = React.Children.toArray(children);
    const childrenWithClass = childrenArray.map((child) => {
        return React.cloneElement(child, {
            className: clsx(child.props.className, styles.child),
        });
    });
    return (_jsx("div", { className: styles.root, style: style, children: childrenWithClass }));
};
export default Inline;
