import { BUSINESSES_FETCH_START, BUSINESSES_FETCH_SUCCESS } from '@redux/actionTypes';
import { cleanBusinesses } from '@redux/utilities/handleBusinessData';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { CANCELED_ERROR, loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:stores:fetchBusinesses');
const fetchBusinesses = (businessIds) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                if (error.name === CANCELED_ERROR) {
                    debug(`Cancelled duplicate request`);
                    return;
                }
                const reason = loggableError(error);
                debug(`Failed fetching businesses ${businessIds}`, reason);
                reject(reason);
            };
            const baseUrl = window.location.origin;
            const url = new URL(`${baseUrl}/api/v1/businesses`);
            if (businessIds !== undefined) {
                businessIds.forEach((businessId) => {
                    url.searchParams.append('ids[]', businessId);
                });
            }
            dispatch({
                type: BUSINESSES_FETCH_START,
            });
            return axios
                .get(url.toString())
                .then((response) => {
                const { data } = response;
                const businesses = cleanBusinesses(data);
                dispatch({
                    type: BUSINESSES_FETCH_SUCCESS,
                    payload: {
                        businesses,
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
export default fetchBusinesses;
