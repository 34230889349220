import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import toggleSidebar from '@redux/thunks/application/toggleSidebar';
import { useIsMobile } from 'hooks/application';
import Footer from 'components/layout/Footer/Footer';
import Navigation from 'components/layout/Navigation';
import Sidebar from 'components/layout/Sidebar/Wrapper';
const Layout = () => {
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const isSidebarOpen = useAppSelector((state) => state.application.isSidebarOpen);
    const onBackDropClick = () => {
        dispatch(toggleSidebar());
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: clsx('sidebar-backdrop', { overlay: isSidebarOpen === true }), "aria-label": "layouts.authenticated.sidebar_backdrop", onClick: onBackDropClick, onKeyPress: onBackDropClick, tabIndex: 0, role: "button" }), _jsxs("div", { id: "authenticated-layout", className: clsx({
                    'overflow-hidden': isSidebarOpen === true && isMobile === true,
                }), children: [_jsx(Navigation, {}), _jsxs("main", { className: "content-wrapper", children: [_jsx(Sidebar, {}), _jsx("div", { className: "scene-content-wrapper", children: _jsx("div", { className: "scene-content", children: _jsx(Outlet, {}) }) })] }), _jsx(Footer, {})] })] }));
};
export default Layout;
