// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BecomeAFoodWasteHero-module--container--_Iad1{display:flex;flex-direction:column;align-items:center}.BecomeAFoodWasteHero-module--container--_Iad1 .BecomeAFoodWasteHero-module--image--RjQQq{width:100%;border-radius:8px;vertical-align:middle}.BecomeAFoodWasteHero-module--container--_Iad1 .BecomeAFoodWasteHero-module--heading--u2Hha{margin-top:1.5rem;text-align:center}.BecomeAFoodWasteHero-module--container--_Iad1 .BecomeAFoodWasteHero-module--button--XNdgl{margin-top:1.5rem;width:fit-content}.BecomeAFoodWasteHero-module--container--_Iad1 .BecomeAFoodWasteHero-module--button--XNdgl span{padding-bottom:.125rem}.BecomeAFoodWasteHero-module--container--_Iad1 .BecomeAFoodWasteHero-module--buttonHint--tBcvf{margin-top:1rem;font-size:14px;font-style:italic}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BecomeAFoodWasteHero-module--container--_Iad1",
	"image": "BecomeAFoodWasteHero-module--image--RjQQq",
	"heading": "BecomeAFoodWasteHero-module--heading--u2Hha",
	"button": "BecomeAFoodWasteHero-module--button--XNdgl",
	"buttonHint": "BecomeAFoodWasteHero-module--buttonHint--tBcvf"
};
export default ___CSS_LOADER_EXPORT___;
