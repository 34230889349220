// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Address-module--address--qkbuM{font-weight:600;line-height:18px;color:#ff4c70}.Address-module--address--qkbuM>span:not(:last-of-type)::after{content:\", \"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"address": "Address-module--address--qkbuM"
};
export default ___CSS_LOADER_EXPORT___;
