// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClaimCollectionModal-module--modalContent--K3ynr{height:100%;display:flex;flex-direction:column;justify-content:space-between}.ClaimCollectionModal-module--modalContent--K3ynr .ClaimCollectionModal-module--spaceDisclaimer--cAKJn{margin-top:2.5rem;margin-bottom:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "ClaimCollectionModal-module--modalContent--K3ynr",
	"spaceDisclaimer": "ClaimCollectionModal-module--spaceDisclaimer--cAKJn"
};
export default ___CSS_LOADER_EXPORT___;
