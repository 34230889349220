// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InductionsListScene-module--collapsibleAlert--Tg6mX{padding-top:1.5rem;padding-bottom:1.5rem}@media(min-width: 600px){.InductionsListScene-module--collapsibleAlert--Tg6mX{padding-top:1rem;padding-bottom:2rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsibleAlert": "InductionsListScene-module--collapsibleAlert--Tg6mX"
};
export default ___CSS_LOADER_EXPORT___;
