import { FETCHING_STATUSES } from 'config/api';
const { SUCCESS } = FETCHING_STATUSES;
export const cleanCollection = (collection) => {
    return {
        collectionId: collection.id,
        type: collection.slot_type,
        frequency: collection.frequency,
        nextCollection: collection.next_collection,
        endDate: collection.end,
        storeId: collection.store_id,
        userId: collection.user_id,
        collectionState: collection.state,
        notes: collection.notes,
        requiredInductionsIds: collection.required_induction_ids,
        derestricted: collection.derestricted,
        donationConfirmedAt: collection.donation_confirmed_at,
    };
};
export const cleanCollections = (collections, { available = undefined } = {}) => {
    return collections.map((collection) => {
        if (available === true) {
            return {
                ...cleanCollection(collection),
                available: true,
            };
        }
        return cleanCollection(collection);
    });
};
export const saveCollectionDataToState = (collection, state) => {
    const { collectionId, type, frequency, nextCollection, endDate, storeId, userId, collectionState, notes, available, requiredInductionsIds, derestricted, donationConfirmedAt, } = collection;
    return {
        ...state,
        collectionsFetchingStatus: SUCCESS,
        items: {
            ...state.items,
            [collectionId]: {
                ...state.items[collectionId],
                available: available || state.items[collectionId]?.available,
                type: type || state.items[collectionId]?.type,
                frequency: frequency || state.items[collectionId]?.frequency,
                nextCollection: nextCollection || state.items[collectionId]?.nextCollection,
                endDate: endDate ?? state.items[collectionId]?.endDate,
                storeId: storeId || state.items[collectionId]?.storeId,
                userId: userId || state.items[collectionId]?.userId,
                collectionState: collectionState || state.items[collectionId]?.collectionState,
                notes: notes || state.items[collectionId]?.notes,
                requiredInductionsIds: requiredInductionsIds || state.items[collectionId]?.requiredInductionsIds,
                derestricted,
                donationConfirmedAt,
            },
        },
    };
};
