import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdHelpOutline } from 'react-icons/md';
import { useAppDispatch } from '@redux/hooks';
import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses';
import fetchMyStores from '@redux/thunks/stores/fetchMyStores';
import { MESSAGE } from 'config/alert';
import Debug from 'helpers/debug';
import { useMyStores } from 'hooks/stores';
import PeachIcon from 'images/Peach.svg';
import StoreList from 'components/Stores/StoreList';
import CollapsibleAlert from 'components/common/CollapsibleAlert';
import InfoItem, { infoItemTypes } from 'components/common/InfoItem';
import LoadingSpinner from 'components/common/LoadingSpinner';
import SceneHeader from 'components/common/SceneHeader';
const debug = Debug('scenes:stores-scene');
const StoresScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data: stores, isLoading } = useMyStores();
    const storeIds = useMemo(() => stores.map((store) => store.id), [stores]);
    const businessIds = useMemo(() => stores.map((store) => store.businessId), [stores]);
    const uniqueBusinessIds = useMemo(() => [...new Set(businessIds)], [businessIds.length]);
    useEffect(() => {
        dispatch(fetchMyStores()).catch(debug);
    }, []);
    useEffect(() => {
        if (uniqueBusinessIds !== undefined && uniqueBusinessIds.length > 0) {
            dispatch(fetchBusinesses(uniqueBusinessIds)).catch(debug);
        }
    }, [uniqueBusinessIds.length]);
    return (_jsxs("div", { id: "stores-scene", children: [_jsx(SceneHeader, { title: t('stores.page.title'), description: t('stores.page.description') }), _jsx("div", { className: "squad-captain-card", children: _jsxs(CollapsibleAlert, { icon: _jsx(MdHelpOutline, { "data-testid": "helpIcon" }), title: t('stores.page.squad_captain_card.title'), description: t('stores.page.squad_captain_card.description_collapsed'), variant: MESSAGE, children: [_jsx("p", { className: "list-header", children: t('stores.page.squad_captain_card.description_expanded.header') }), _jsxs("ul", { children: [_jsx("li", { children: t('stores.page.squad_captain_card.description_expanded.point1') }), _jsx("li", { children: t('stores.page.squad_captain_card.description_expanded.point2') }), _jsx("li", { children: t('stores.page.squad_captain_card.description_expanded.point3') })] })] }) }), _jsx("h2", { children: t('stores.page.stores_section_heading') }), isLoading === true && _jsx(LoadingSpinner, { containerHeight: "400px" }), isLoading === false && stores.length > 0 && _jsx(StoreList, { storeIds: storeIds }), isLoading === false && stores.length === 0 && (_jsx(InfoItem, { type: infoItemTypes.EMPTY, title: t('stores.page.no_collection_card.title'), description1: t('stores.page.no_collection_card.description'), icon: PeachIcon }))] }));
};
export default StoresScene;
