import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdOutlineSchool } from 'react-icons/md';
import styles from './InductionProgress.module.scss';
import InformationIcon from './InformationIcon';
const InductionProgress = ({ completedInductionsCount, inductionsCount, collectionType, }) => {
    const { t } = useTranslation();
    if (inductionsCount === undefined || completedInductionsCount === undefined) {
        return null;
    }
    return (_jsxs("div", { className: styles.inductionProgress, children: [_jsx("span", { children: _jsx(InformationIcon, { Icon: MdOutlineSchool, collectionType: collectionType }) }), _jsxs("span", { className: styles.inductionProgressValues, children: [`${completedInductionsCount}/${inductionsCount} `, t('collections.available.inductions')] })] }));
};
export default InductionProgress;
