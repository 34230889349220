import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:fetchDocuments');
const fetchDocuments = createAsyncThunk('documents/fetchDocuments', (_, { rejectWithValue, fulfillWithValue }) => {
    return axios
        .get(`/api/v1/materials`)
        .then((response) => {
        const documents = response.data;
        return fulfillWithValue(documents);
    })
        .catch((error) => {
        debug(`Failed fetching documents`, loggableError(error));
        return rejectWithValue(error.response.status);
    });
});
export default fetchDocuments;
