import { MODAL_CLOSE, MODAL_OPEN, TOGGLE_SIDEBAR } from '@redux/actionTypes';
import initialState from '@redux/reducers/Application/initialState';
const ACTION_HANDLERS = {
    [MODAL_OPEN]: (state = initialState, action) => {
        const { payload: { key, data }, } = action;
        const isModalWithKeyOpen = state.openedModals?.filter((modal) => modal.key === key).length === 1;
        if (isModalWithKeyOpen) {
            return state;
        }
        return {
            ...state,
            openedModals: [
                ...state.openedModals,
                {
                    key,
                    data,
                },
            ],
        };
    },
    [MODAL_CLOSE]: (state = initialState, action) => {
        const { payload: { key }, } = action;
        return {
            ...state,
            openedModals: state.openedModals.filter((modal) => modal.key !== key),
        };
    },
    [TOGGLE_SIDEBAR]: (state = initialState) => {
        return {
            ...state,
            isSidebarOpen: !state.isSidebarOpen,
        };
    },
};
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
