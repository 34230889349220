import { useAppSelector } from '@redux/hooks';
import { selectCollectionWithStoreAndBusinessById } from '@redux/selectors/collections';
import { FETCHING_STATUSES } from 'config/api';
const { IDLE, LOADING } = FETCHING_STATUSES;
const useCollection = ({ collectionId, dataToInclude }) => {
    const collection = useAppSelector((state) => selectCollectionWithStoreAndBusinessById(state, collectionId, dataToInclude));
    const isLoadingCollections = useAppSelector((state) => state.collections.collectionsFetchingStatus === IDLE ||
        state.collections.collectionsFetchingStatus === LOADING);
    const isLoadingStores = useAppSelector((state) => state.stores.storesFetchingStatus === IDLE || state.stores.storesFetchingStatus === LOADING);
    const isLoadingBusinesses = useAppSelector((state) => state.stores.businessesFetchingStatus === IDLE ||
        state.stores.businessesFetchingStatus === LOADING);
    const isLoading = isLoadingCollections === true || isLoadingStores === true || isLoadingBusinesses === true;
    return {
        data: collection,
        isLoading,
        isLoadingCollections,
        isLoadingStores,
        isLoadingBusinesses,
    };
};
export default useCollection;
