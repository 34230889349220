import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { modalKeys } from 'config/modals';
import { COLLECTION_ACTIONS } from 'helpers/collections';
import { useModal } from 'hooks/application';
import ActionsModal from './ActionsModal';
const SwapModal = ({ collectionId }) => {
    const { isOpen } = useModal(modalKeys.SWAP_COLLECTION);
    if (isOpen === false) {
        return null;
    }
    return (_jsx(ActionsModal, { collectionId: collectionId, mode: COLLECTION_ACTIONS.SWAP }));
};
export default memo(SwapModal);
