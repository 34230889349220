import { t } from 'i18next';
import { DateTime } from 'luxon';
import { COLLECTION_TYPES } from 'config/collections';
const exampleAdhocCollection = () => ({
    businessName: t('join_intro_page.example_adhoc_collection.business'),
    storeAddress: {
        addressLine1: t('join_intro_page.example_adhoc_collection.address_line_1'),
        addressLine2: t('join_intro_page.example_adhoc_collection.address_line_2'),
        postCode: t('join_intro_page.example_adhoc_collection.postcode'),
    },
    storeLocation: {
        latitude: 51.517,
        longitude: -0.0760374,
    },
    homeLocation: {
        latitude: 51.517,
        longitude: -0.08734,
    },
    frequency: t('join_intro_page.example_adhoc_collection.frequency'),
    nextCollection: DateTime.now().plus({ days: 2 }).toString(),
    type: COLLECTION_TYPES.ADHOC,
});
const exampleOngoingCollection = () => ({
    businessName: t('join_intro_page.example_ongoing_collection.business'),
    storeAddress: {
        addressLine1: t('join_intro_page.example_ongoing_collection.address_line_1'),
        addressLine2: t('join_intro_page.example_ongoing_collection.address_line_2'),
        postCode: t('join_intro_page.example_ongoing_collection.postcode'),
    },
    storeLocation: {
        latitude: 51.517,
        longitude: -0.0760374,
    },
    homeLocation: {
        latitude: 51.517,
        longitude: -0.10504,
    },
    frequency: t('join_intro_page.example_ongoing_collection.frequency'),
    nextCollection: DateTime.now().plus({ days: 4, hours: 2 }).toString(),
    type: COLLECTION_TYPES.ONGOING,
});
export { exampleAdhocCollection, exampleOngoingCollection };
