// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card-module--card--MFlk7{background-color:#fff;border-radius:16px}.Card-module--card--MFlk7 .Card-module--content--mvEDx{padding:1.5rem;height:100%;display:flex;flex-direction:column;justify-content:space-between}.Card-module--card--MFlk7 img{max-width:100%}.Card-module--imageWrapper--CIbHb{display:flex;justify-content:center}.Card-module--imageWrapper--CIbHb img{height:70px;align-self:center;margin-top:1rem}.Card-module--textSection--QfaYj{padding-top:1rem;padding-bottom:1.5rem;color:#474747}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Card-module--card--MFlk7",
	"content": "Card-module--content--mvEDx",
	"imageWrapper": "Card-module--imageWrapper--CIbHb",
	"textSection": "Card-module--textSection--QfaYj"
};
export default ___CSS_LOADER_EXPORT___;
