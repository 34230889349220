import { jsx as _jsx } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { useAppSelector } from '@redux/hooks';
import { useIsMobile } from 'hooks/application';
import SidebarContent from 'components/layout/Sidebar/Content';
import styles from './Wrapper.module.scss';
const SidebarWrapper = () => {
    const isSidebarOpen = useAppSelector((state) => state.application.isSidebarOpen);
    const isMobile = useIsMobile();
    return (_jsx(motion.div, { initial: isMobile === true && 'mobileInitial', animate: isSidebarOpen === false && isMobile === true ? 'close' : 'open', variants: {
            mobileInitial: { x: '-100%', visibility: 'hidden' },
            open: { x: 0, visibility: 'visible' },
            close: {
                x: '-100%',
                transitionEnd: {
                    visibility: 'hidden',
                },
            },
        }, transition: { duration: 0.25 }, className: styles.sidebarWrapper, "data-testid": "sidebarAnimated", role: "navigation", children: _jsx(SidebarContent, {}) }));
};
export default SidebarWrapper;
