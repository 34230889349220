// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VolunteerThoughts-module--thoughtsTitle--_yLOt{margin-top:5rem;margin-bottom:1.5rem}.VolunteerThoughts-module--youtube--lfOh3 iframe{height:12.8125rem;border-radius:12px}@media(min-width: 600px){.VolunteerThoughts-module--youtube--lfOh3 iframe{height:32.6875rem;width:58.375rem}}.VolunteerThoughts-module--youtube--lfOh3 iframe{max-width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thoughtsTitle": "VolunteerThoughts-module--thoughtsTitle--_yLOt",
	"youtube": "VolunteerThoughts-module--youtube--lfOh3"
};
export default ___CSS_LOADER_EXPORT___;
