import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdOutlineLock } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { trackEvent } from 'services/Analytics';
import { BECOME_A_FOOD_HERO } from 'config/pictureHeader';
import { REGISTER_URL } from 'config/routes';
import Button from 'components/common/Button';
import PictureHeader from 'components/common/PictureHeader';
import styles from './BecomeAFoodWasteHero.module.scss';
const BecomeAFoodWasteHero = ({ isUserVerified }) => {
    const { t } = useTranslation();
    return (_jsxs("section", { className: styles.container, children: [_jsx(PictureHeader, { variant: BECOME_A_FOOD_HERO }), _jsx("h2", { className: styles.heading, children: t('join_intro_page.heading') }), isUserVerified === true && (_jsx(Link, { to: REGISTER_URL, children: _jsx(Button, { onClick: () => trackEvent('FWH_NAVIGATE_COLLECTIONS_TEASER_PROCEED'), className: styles.button, children: t('join_intro_page.become_food_waste_volunteer') }) })), isUserVerified === false && (_jsxs(_Fragment, { children: [_jsx(Button, { leftContent: _jsx(MdOutlineLock, { "data-testid": "lockIcon" }), className: styles.button, children: t('join_intro_page.become_food_waste_volunteer') }), _jsx("div", { className: styles.buttonHint, children: t('join_intro_page.unverified_info.locked_button_hint') })] }))] }));
};
export default BecomeAFoodWasteHero;
