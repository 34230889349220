import { FETCHING_STATUSES } from 'config/api';
export default {
    currentUser: {
        id: undefined,
        roles: [],
    },
    authenticationError: undefined,
    items: {},
    currentUserFetchingStatus: FETCHING_STATUSES.IDLE,
};
