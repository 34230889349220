import { COLLECTION_SKIP_ERROR, COLLECTION_SKIP_START, COLLECTION_SKIP_SUCCESS, } from '@redux/actionTypes';
import { trackEvent } from 'services/Analytics';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:collections:skip');
export default (collectionId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: COLLECTION_SKIP_START,
            });
            const failedFetch = (error) => {
                const reason = loggableError(error);
                debug(`Failed skipping collection ${collectionId}`, reason);
                dispatch({
                    type: COLLECTION_SKIP_ERROR,
                    payload: {
                        errors: error?.response?.data?.errors?.base || [],
                    },
                });
                reject(reason);
            };
            return axios
                .patch(`/api/v1/collections/${collectionId}/pause`, {
                collection: {
                    end: 'immediate',
                },
            })
                .then(() => {
                const state = getState();
                const collection = state.collections?.items[collectionId];
                const store = state.stores?.items[collection.storeId];
                const business = state.stores?.businesses[store.businessId];
                dispatch({
                    type: COLLECTION_SKIP_SUCCESS,
                    meta: {
                        analytics: {
                            eventName: COLLECTION_SKIP_SUCCESS,
                            eventPayload: {
                                id: collectionId,
                                type: collection?.type,
                                business: business?.name,
                                store: store?.name,
                                nextCollection: collection?.nextCollection,
                            },
                        },
                    },
                });
                trackEvent('COLLECTION_SKIP_NEXT', { id: collectionId });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
