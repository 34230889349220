// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvailableCollectionDetailsScene-module--lateLabel--NLBIy{background-color:#fdf2eb;margin-bottom:16px;padding:8px}.AvailableCollectionDetailsScene-module--lateLabel--NLBIy .AvailableCollectionDetailsScene-module--contentWrapper--XYVsq{display:flex;flex-direction:row}.AvailableCollectionDetailsScene-module--lateLabel--NLBIy .AvailableCollectionDetailsScene-module--contentWrapper--XYVsq p{padding:0}.AvailableCollectionDetailsScene-module--lateLabel--NLBIy .AvailableCollectionDetailsScene-module--title--Oy952{color:#ff4c70;font-weight:600;padding-bottom:4px !important}.AvailableCollectionDetailsScene-module--lateLabel--NLBIy .AvailableCollectionDetailsScene-module--image--H8wDw{align-self:center;width:36px;height:36px;padding-right:8px}.AvailableCollectionDetailsScene-module--lateLabel--NLBIy .AvailableCollectionDetailsScene-module--wordWrapper--uXnwn{padding-left:8px;border-left:#ffe9db 1px solid}.AvailableCollectionDetailsScene-module--link--TBqaC{text-decoration:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lateLabel": "AvailableCollectionDetailsScene-module--lateLabel--NLBIy",
	"contentWrapper": "AvailableCollectionDetailsScene-module--contentWrapper--XYVsq",
	"title": "AvailableCollectionDetailsScene-module--title--Oy952",
	"image": "AvailableCollectionDetailsScene-module--image--H8wDw",
	"wordWrapper": "AvailableCollectionDetailsScene-module--wordWrapper--uXnwn",
	"link": "AvailableCollectionDetailsScene-module--link--TBqaC"
};
export default ___CSS_LOADER_EXPORT___;
