import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@redux/hooks';
import { getCollectionActionDetails } from 'helpers/collections';
import StepContent from './StepContent';
import StepHeader from './StepHeader';
const Step = ({ step, mode }) => {
    const { t } = useTranslation();
    const activeStep = useAppSelector((state) => state.collections[mode].step);
    const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
        return getCollectionActionDetails(t);
    }, []);
    const isStepComplete = () => COLLECTION_ACTIONS_DETAILS[mode].steps[activeStep].position >
        COLLECTION_ACTIONS_DETAILS[mode].steps[step].position;
    return (_jsxs("div", { className: `step${isStepComplete() ? ' complete' : ''}`, children: [_jsx(StepHeader, { step: step, mode: mode }), _jsx(StepContent, { step: step, mode: mode })] }));
};
export default Step;
