import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdLockOutline } from 'react-icons/md';
import { INFO } from 'config/alert';
import { COLLECTION_TYPES } from 'config/collections';
import { modalKeys } from 'config/modals';
import { formatDateToDayAndMonth } from 'helpers/time';
import { useModal } from 'hooks/application';
import { useCollection } from 'hooks/collections';
import ClaimCollectionModal from 'components/AvailableCollections/ClaimCollection/ClaimCollectionModal';
import ClaimCollectionSuccessModal from 'components/AvailableCollections/ClaimCollection/ClaimCollectionSuccessModal';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import styles from './ClaimCollection.module.scss';
import useHasCollectionOnSameDay from './useHasCollectionOnSameDay';
const renderClaimButton = ({ onClick, label, secondary = false, showLock = false, }) => (_jsx(Button, { onClick: onClick, leftContent: showLock ? _jsx(MdLockOutline, { "data-testid": "lockIcon" }) : null, secondary: secondary, children: label }));
const ClaimCollection = ({ collectionId, requiredInductions }) => {
    const { t } = useTranslation();
    const { data: { derestricted, nextCollection, type }, } = useCollection({ collectionId });
    const hasCollectionOnSameDay = useHasCollectionOnSameDay(collectionId);
    const { openModal: openClaimCollectionModal, isOpen: isClaimCollectionModalOpen } = useModal(modalKeys.CLAIM_COLLECTION, {
        trackingProperties: {
            spaceCheckboxDisplayed: hasCollectionOnSameDay,
            derestricted,
        },
    });
    const { isOpen: isClaimCollectionSuccessModalOpen } = useModal(modalKeys.CLAIM_COLLECTION_SUCCESS);
    const [collectionClaimAttempted, setCollectionClaimAttempted] = useState(false);
    const areSomeInductionsIncomplete = useMemo(() => requiredInductions.some((induction) => induction.isComplete === false), [requiredInductions]);
    const handleClaimAttempt = () => setCollectionClaimAttempted(true);
    const claimLabel = type === COLLECTION_TYPES.ONGOING
        ? t('collection-slots.show.claim.button-ongoing')
        : t('collection-slots.show.claim.button');
    const claimAsOneOffLabel = t('collection-slots.show.claim.button-oneoff', {
        date: formatDateToDayAndMonth(nextCollection),
    });
    return (_jsxs(_Fragment, { children: [areSomeInductionsIncomplete ? (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.inlineActions, children: [type === COLLECTION_TYPES.ONGOING &&
                                renderClaimButton({
                                    onClick: handleClaimAttempt,
                                    label: claimAsOneOffLabel,
                                    secondary: true,
                                    showLock: true,
                                }), renderClaimButton({
                                onClick: handleClaimAttempt,
                                label: claimLabel,
                                showLock: true,
                            })] }), collectionClaimAttempted === true && (_jsx("div", { className: "inductions-warning", children: _jsx(Alert, { variant: INFO, children: t('collections.available.inductions-needed-message') }) }))] })) : (_jsxs("div", { className: styles.inlineActions, children: [type === COLLECTION_TYPES.ONGOING &&
                        renderClaimButton({
                            onClick: () => openClaimCollectionModal({ nextIterationOnly: true }),
                            label: claimAsOneOffLabel,
                            secondary: true,
                        }), renderClaimButton({
                        onClick: () => openClaimCollectionModal({ nextIterationOnly: false }),
                        label: claimLabel,
                    })] })), isClaimCollectionModalOpen && (_jsx(ClaimCollectionModal, { collectionId: collectionId, hasCollectionOnSameDay: hasCollectionOnSameDay })), isClaimCollectionSuccessModalOpen && _jsx(ClaimCollectionSuccessModal, {})] }));
};
export default ClaimCollection;
