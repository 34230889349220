// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyVolunteerActivityWidget-module--card--Ee5lM{height:fit-content}.MyVolunteerActivityWidget-module--card--Ee5lM .MyVolunteerActivityWidget-module--grid--CEOeD{gap:1.25rem;margin-top:1.5rem;display:grid;grid-template-columns:repeat(2, 1fr);column-gap:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "MyVolunteerActivityWidget-module--card--Ee5lM",
	"grid": "MyVolunteerActivityWidget-module--grid--CEOeD"
};
export default ___CSS_LOADER_EXPORT___;
