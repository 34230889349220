// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown-module--dropdown--C4lOs{width:100%;height:3rem;padding:5px;margin-top:2px;border-radius:10px;border:1px solid #fff;border-right:10px solid #fff;background-color:#fff;font-size:1rem;font-weight:600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "Dropdown-module--dropdown--C4lOs"
};
export default ___CSS_LOADER_EXPORT___;
