// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RotaItem-module--rotaItem--LhqjH{display:flex;flex-direction:row;justify-content:space-between;gap:1rem}.RotaItem-module--rotaItem--LhqjH .RotaItem-module--bulletAndDate--ByVLK{display:flex;flex-direction:row;gap:1rem;flex:2}@media(max-width: 599px){.RotaItem-module--rotaItem--LhqjH .RotaItem-module--bulletAndDate--ByVLK{font-size:14px}}.RotaItem-module--rotaItem--LhqjH .RotaItem-module--nameAndTime--FCIRE{text-align:right;flex:1;font-weight:600}@media(max-width: 599px){.RotaItem-module--rotaItem--LhqjH .RotaItem-module--nameAndTime--FCIRE{font-size:14px;font-weight:600}}.RotaItem-module--rotaItem--LhqjH .RotaItem-module--icon--qofRA{color:#3b1869;line-height:1}.RotaItem-module--rotaItem--LhqjH:last-of-type{margin-bottom:0}.RotaItem-module--rotaItem--LhqjH .RotaItem-module--dateTime--m8h5o{font-weight:600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotaItem": "RotaItem-module--rotaItem--LhqjH",
	"bulletAndDate": "RotaItem-module--bulletAndDate--ByVLK",
	"nameAndTime": "RotaItem-module--nameAndTime--FCIRE",
	"icon": "RotaItem-module--icon--qofRA",
	"dateTime": "RotaItem-module--dateTime--m8h5o"
};
export default ___CSS_LOADER_EXPORT___;
