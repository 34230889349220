// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HowItWorks-module--howItWorks--oV045{display:flex;flex-direction:column;padding-bottom:3rem}.HowItWorks-module--howItWorks--oV045 p:first-of-type{padding-top:0}.HowItWorks-module--howItWorks--oV045 p{color:#474747}.HowItWorks-module--howItWorks--oV045 strong{color:#474747}.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--grid--vpHKR{gap:1.25rem;margin-top:1rem}@media(min-width: 992px){.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--grid--vpHKR{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:5rem}}.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--stepContainer--yY8FU{display:flex;flex-direction:row;justify-content:flex-start}@media(min-width: 992px){.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--stepContainer--yY8FU .HowItWorks-module--stepImage--Bjflk{margin-right:1.5rem}}.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--stepContainer--yY8FU .HowItWorks-module--rightImage--rh3r3{margin-left:1rem}@media(min-width: 992px){.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--stepContainer--yY8FU .HowItWorks-module--rightImage--rh3r3{margin-left:0}}.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--stepContainer--yY8FU .HowItWorks-module--leftImage--gy5DV{margin-right:1rem}@media(min-width: 992px){.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--stepContainer--yY8FU .HowItWorks-module--leftImage--gy5DV{margin-right:1.5rem}}.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--howItWorksSection--XS2fY{margin-bottom:2rem}.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--title--qKYw9{padding-bottom:1.125rem}@media(min-width: 992px){.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--title--qKYw9{padding-bottom:1rem}}@media(max-width: 991px){.HowItWorks-module--howItWorks--oV045 .HowItWorks-module--reversedRow--OJhlJ{flex-direction:row-reverse;gap:1rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"howItWorks": "HowItWorks-module--howItWorks--oV045",
	"grid": "HowItWorks-module--grid--vpHKR",
	"stepContainer": "HowItWorks-module--stepContainer--yY8FU",
	"stepImage": "HowItWorks-module--stepImage--Bjflk",
	"rightImage": "HowItWorks-module--rightImage--rh3r3",
	"leftImage": "HowItWorks-module--leftImage--gy5DV",
	"howItWorksSection": "HowItWorks-module--howItWorksSection--XS2fY",
	"title": "HowItWorks-module--title--qKYw9",
	"reversedRow": "HowItWorks-module--reversedRow--OJhlJ"
};
export default ___CSS_LOADER_EXPORT___;
