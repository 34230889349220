import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppDispatch } from '@redux/hooks';
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences';
import Debug from 'helpers/debug';
import { useAuthenticatedUser } from 'hooks/users';
import BecomeAFoodWasteHero from 'components/JoinIntro/BecomeAFoodWasteHero/BecomeAFoodWasteHero';
import CollectionsNearMe from 'components/JoinIntro/CollectionsNearMe/CollectionsNearMe';
import NextSteps from 'components/JoinIntro/NextSteps';
import RegisteringIsEasy from 'components/JoinIntro/RegisteringIsEasy';
import UnverifiedInfo from 'components/JoinIntro/UnverifiedInfo';
import VolunteeringInfoSection from 'components/common/VolunteeringInfoSection';
import Footer from 'components/layout/Footer';
import styles from './JoinIntroScene.module.scss';
const debug = Debug('scenes:join-intro-scene');
const JoinIntroScene = () => {
    const dispatch = useAppDispatch();
    const { isUserVerified } = useAuthenticatedUser();
    useEffect(() => {
        dispatch(fetchUserPreferences()).catch(debug);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { id: "join-intro-scene", className: styles.joinIntroScene, children: [isUserVerified === false && _jsx(UnverifiedInfo, {}), _jsx(BecomeAFoodWasteHero, { isUserVerified: isUserVerified }), _jsx(VolunteeringInfoSection, {}), _jsx(CollectionsNearMe, {}), _jsx(NextSteps, {}), _jsx(RegisteringIsEasy, { isUserVerified: isUserVerified })] }), _jsx(Footer, {})] }));
};
export default JoinIntroScene;
