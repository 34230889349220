// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NextSteps-module--nextSteps--UT3V_{display:flex;justify-content:center;position:relative;width:100vw;margin-left:-50vw;left:50%;margin-top:4rem;padding:2.5rem .75rem;background-color:#fff}@media(min-width: 600px){.NextSteps-module--nextSteps--UT3V_ .NextSteps-module--contentWrapper--RGFIF{max-width:58.375rem}}.NextSteps-module--nextSteps--UT3V_ .NextSteps-module--contentWrapper--RGFIF .NextSteps-module--header--nCE0t{margin-bottom:.5rem}.NextSteps-module--nextSteps--UT3V_ .NextSteps-module--contentWrapper--RGFIF h3{font-size:1.25rem}.NextSteps-module--nextSteps--UT3V_ .NextSteps-module--contentWrapper--RGFIF strong{font-size:1rem;color:#474747;display:block;margin-top:2rem}.NextSteps-module--nextSteps--UT3V_ .NextSteps-module--contentWrapper--RGFIF strong:first-of-type{margin-top:1.5rem}.NextSteps-module--nextSteps--UT3V_ .NextSteps-module--contentWrapper--RGFIF p{color:#474747;margin-top:.5rem;padding-bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nextSteps": "NextSteps-module--nextSteps--UT3V_",
	"contentWrapper": "NextSteps-module--contentWrapper--RGFIF",
	"header": "NextSteps-module--header--nCE0t"
};
export default ___CSS_LOADER_EXPORT___;
