import { ARTICLE_FETCH_SUCCESS, BASKET_ARTICLE_FETCH_SUCCESS, BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS, } from '@redux/actionTypes';
import initialState from '@redux/reducers/Articles/initialState';
const ACTION_HANDLERS = {
    [ARTICLE_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { articleId, totalItems, title } = payload;
        return {
            ...state,
            items: {
                ...state.items,
                [articleId]: {
                    ...state.items[articleId],
                    total: totalItems,
                    title,
                },
            },
        };
    },
    [BASKET_ARTICLE_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { articleId, available } = payload;
        return {
            ...state,
            items: {
                ...state.items,
                [articleId]: {
                    ...state.items[articleId],
                    available,
                },
            },
        };
    },
    [BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { articleId, available } = payload;
        return {
            ...state,
            items: {
                ...state.items,
                [articleId]: {
                    ...state.items[articleId],
                    available,
                },
            },
        };
    },
};
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
