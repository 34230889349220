import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styles from './CollectionConfirmationInfo.module.scss';
const CollectionConfirmationInfo = ({ businessName, storeName, storeAddress, collectionTime, collectionType, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.root, children: [_jsx("h3", { className: styles.businessAndStoreName, children: t('collection-slots.collection-confirmation-info.business-and-store', {
                    businessName,
                    storeName,
                }) }), _jsx("p", { className: styles.address, children: storeAddress &&
                    Object.values(storeAddress).map((addressLine, index) => {
                        return _jsx("span", { children: addressLine }, addressLine + index);
                    }) }), _jsx("p", { className: styles.on, children: t('collection-slots.collection-confirmation-info.on') }), _jsx("p", { className: styles.collectionTime, children: collectionTime }), _jsx("p", { className: styles.collectionType, children: t('collection-slots.collection-confirmation-info.collection-type', {
                    collectionType: t(`collection-slots.claim.types.${collectionType}`),
                }) })] }));
};
export default CollectionConfirmationInfo;
