import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { convertDistance, getDistance } from 'geolib';
import { getMapUrl } from 'helpers/map';
import styles from './Distance.module.scss';
const CollectionDistance = ({ units, baseLocation, destinationLocation, }) => {
    const { t } = useTranslation();
    if (!baseLocation || !destinationLocation) {
        return null;
    }
    const mapUrl = getMapUrl(destinationLocation);
    const distanceInMeters = getDistance(baseLocation, destinationLocation);
    const distanceInUserUnits = convertDistance(distanceInMeters, units).toFixed(1);
    return (_jsx("div", { className: styles.root, children: _jsx("div", { className: styles.collectionDistance, children: _jsx("span", { dangerouslySetInnerHTML: {
                    __html: t('collection_slots.partials.slot.distance_from_your_location', {
                        distance: t('collection_slots.partials.slot.distance', {
                            quantity: distanceInUserUnits,
                            units: t(`common.units-short.${units}`),
                        }),
                        mapUrl,
                    }),
                } }) }) }));
};
export default CollectionDistance;
