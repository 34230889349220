// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DismissableCard-module--buttonContainer--z7DtL{display:flex;flex-direction:column}.DismissableCard-module--buttonContainer--z7DtL .DismissableCard-module--dismissButton--N4nJ6{width:fit-content;padding:0;margin-top:1rem;height:unset;background:none;color:#bb4291;align-self:center}@media(min-width: 600px){.DismissableCard-module--buttonContainer--z7DtL .DismissableCard-module--dismissButton--N4nJ6{margin-top:1.5rem}}.DismissableCard-module--buttonContainer--z7DtL .DismissableCard-module--dismissButton--N4nJ6 span{font-size:.875rem;font-weight:400}@media(min-width: 600px){.DismissableCard-module--buttonContainer--z7DtL .DismissableCard-module--dismissButton--N4nJ6 span{font-size:1rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "DismissableCard-module--buttonContainer--z7DtL",
	"dismissButton": "DismissableCard-module--dismissButton--N4nJ6"
};
export default ___CSS_LOADER_EXPORT___;
