import { LOGIN_EMAIL_REQUESTED_URL } from 'config/routes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:users:request-magic-token');
export default (email) => {
    return () => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                const reason = loggableError(error);
                debug(`Failed requesting login email`, reason);
                reject(error);
            };
            return axios
                .post(`/api/v1/sessions/email`, {
                email,
            })
                .then((response) => {
                debug(response);
                window.location.assign(LOGIN_EMAIL_REQUESTED_URL);
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
