/**
 * This function only takes "true" or "false" and converts them to primitive boolean. It defaults to false otherwise
 */
export const convertStringifiedBoolean = function (value) {
    if (value === 'true')
        return true;
    if (value === 'false')
        return false;
    return false;
};
