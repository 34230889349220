// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MakeADifference-module--makeADifference--YhcAt{padding:2rem 0 3.75rem}@media(min-width: 600px){.MakeADifference-module--makeADifference--YhcAt{padding:2.5rem 0 5rem}}.MakeADifference-module--makeADifference--YhcAt p{color:#474747}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"makeADifference": "MakeADifference-module--makeADifference--YhcAt"
};
export default ___CSS_LOADER_EXPORT___;
