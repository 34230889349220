import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdNotificationsNone } from 'react-icons/md';
import { useParams, useSearchParams } from 'react-router-dom';
import { MESSAGE } from 'config/alert';
import MyCollectionWrapper from 'components/Collections/MyCollectionWrapper';
import Alert from 'components/common/Alert';
import SceneHeader from 'components/common/SceneHeader';
import { Stack } from 'components/layout/primitives';
const NEW_COLLECTION_QUERY_PARAM = 'new';
const MyCollectionDetailsScene = () => {
    const { t } = useTranslation();
    const { collectionId } = useParams();
    const [searchParams] = useSearchParams();
    const isAlertVisible = searchParams.get(NEW_COLLECTION_QUERY_PARAM) === 'true';
    return (_jsxs("div", { id: "my-collection-details-scene", children: [_jsx(SceneHeader, { title: t('your_collections.show.title') }), _jsx("div", { className: "scene-description", children: t('your_collections.show.description') }), _jsxs(Stack, { gap: "1.5rem", children: [isAlertVisible && (_jsxs(Alert, { variant: MESSAGE, children: [_jsxs("div", { className: "message-header", children: [_jsx(MdNotificationsNone, { className: "alert-icon" }), _jsx("h3", { children: t('your_collections.show.steps.title') })] }), _jsxs("ol", { children: [_jsx("li", { children: _jsx("p", { children: _jsx("a", { className: "faq-link", href: t('your_collections.show.steps.read_faq_url'), children: t('your_collections.show.steps.read_faq') }) }) }), _jsx("li", { children: _jsx("p", { children: t('your_collections.show.steps.update_profile') }) }), _jsx("li", { children: _jsx("p", { children: t('your_collections.show.steps.introduce_yourself') }) })] })] })), _jsx(MyCollectionWrapper, { collectionId: Number(collectionId) })] })] }));
};
export default memo(MyCollectionDetailsScene);
