// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InnerCard-module--card--RZr9V{height:fit-content;background-color:#f8ecf4;padding:1rem;border-radius:16px}.InnerCard-module--card--RZr9V .InnerCard-module--headingSection--_TsIZ{display:flex}.InnerCard-module--card--RZr9V .InnerCard-module--headingSection--_TsIZ .InnerCard-module--groupIcon--xGshW{width:60px;height:40px;color:#bb4291;line-height:1;margin-top:-0.5rem;margin-left:-0.5rem}.InnerCard-module--card--RZr9V .InnerCard-module--headingSection--_TsIZ .InnerCard-module--groupIcon--xGshW svg{width:60px;height:40px}.InnerCard-module--card--RZr9V p{color:#252525}.InnerCard-module--card--RZr9V .InnerCard-module--textSection--gTVLp{padding-bottom:1rem}.InnerCard-module--card--RZr9V .InnerCard-module--textSection--gTVLp .InnerCard-module--totalHeading--cQ3ZI{font-size:.875rem;font-weight:600;padding:0;margin-top:1rem}@media(min-width: 600px){.InnerCard-module--card--RZr9V .InnerCard-module--textSection--gTVLp .InnerCard-module--totalHeading--cQ3ZI{font-size:1rem}}.InnerCard-module--card--RZr9V .InnerCard-module--textSection--gTVLp .InnerCard-module--totalText--OB_EJ{padding-bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "InnerCard-module--card--RZr9V",
	"headingSection": "InnerCard-module--headingSection--_TsIZ",
	"groupIcon": "InnerCard-module--groupIcon--xGshW",
	"textSection": "InnerCard-module--textSection--gTVLp",
	"totalHeading": "InnerCard-module--totalHeading--cQ3ZI",
	"totalText": "InnerCard-module--totalText--OB_EJ"
};
export default ___CSS_LOADER_EXPORT___;
