import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@redux/hooks';
import setSelectedOptions from '@redux/thunks/collections/setSelectedOptions';
import { modalKeys, modalTrackingKeys } from 'config/modals';
import { MODAL_MODES } from 'helpers/collections';
import { useModal } from 'hooks/application';
import Button from 'components/common/Button';
import { ModalFooter } from 'components/common/Modal/ConfirmationModal';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import styles from './Warning.module.scss';
const Warning = ({ mode }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { closeModal } = useModal(modalKeys.SWAP_COLLECTION);
    const handleRejectClick = () => {
        closeModal();
    };
    const handleConfirmClick = () => {
        dispatch(setSelectedOptions({
            action: mode,
            modalMode: MODAL_MODES.OPTIONS,
        }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx("h2", { children: t('your-collections.page.show.swap-modal.confirmation-modal.title') }), _jsx("strong", { className: styles.warning, children: t('your-collections.page.show.swap-modal.confirmation-modal.warning') }), _jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: t('your-collections.page.show.swap-modal.confirmation-modal.content.text-1') }) }), _jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: t('your-collections.page.show.swap-modal.confirmation-modal.content.p_2.text', {
                                boldText1: `<b>${t('your-collections.page.show.swap-modal.confirmation-modal.content.p_2.bold_text_1')}</b>`,
                                boldText2: `<b>${t('your-collections.page.show.swap-modal.confirmation-modal.content.p_2.bold_text_2')}</b>`,
                            }) }) }), _jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: t('your-collections.page.show.swap-modal.confirmation-modal.content.p_3.text', {
                                underlinedText: `<u>${t('your-collections.page.show.swap-modal.confirmation-modal.content.p_3.underlined_text')}</u>`,
                            }) }) })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { "data-tracking": modalTrackingKeys.reject, onClick: handleRejectClick, secondary: true, children: t('your-collections.page.show.swap-modal.confirmation-modal.buttons.cancel') }), _jsx(Button, { "data-tracking": modalTrackingKeys.confirm, onClick: handleConfirmClick, children: t('your-collections.page.show.swap-modal.confirmation-modal.buttons.confirm') })] })] }));
};
export default Warning;
