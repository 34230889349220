import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
export const VARIANTS = {
    PINK: 'pink',
};
const Pill = ({ variant, children }) => {
    return _jsx("div", { className: clsx(variant, 'pill'), children: children });
};
Pill.Icon = function PillIcon({ children }) {
    return _jsx("div", { className: "pill-icon", children: children });
};
Pill.Text = function PillText({ children }) {
    return _jsx("div", { className: "pill-text", children: children });
};
export default Pill;
