import { MdOutlineInfo, MdPlace, MdWarningAmber } from 'react-icons/md';
export const ERROR = 'error';
export const INFO = 'info';
export const STATUS = 'status';
export const MESSAGE = 'message';
export const RECOMMENDATION = 'recommendation';
export const alertIcons = {
    [ERROR]: MdWarningAmber,
    [INFO]: MdOutlineInfo,
    [STATUS]: MdOutlineInfo,
    [MESSAGE]: undefined,
    [RECOMMENDATION]: MdPlace,
};
