import { USER_ADDRESS_FETCH_SUCCESS } from '@redux/actionTypes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { CANCELED_ERROR, loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:users:fetchUserAddress');
export default (id) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                if (error.name === CANCELED_ERROR) {
                    debug(`Cancelled duplicate request`);
                    return;
                }
                const reason = loggableError(error);
                debug(`Failed fetching address for user ${id}`, reason);
                reject(error);
            };
            return axios
                .get(`/api/v1/users/${id}/address`)
                .then((response) => {
                const { address_line_1: addressLine1, address_line_2: addressLine2, address_line_3: addressLine3, address_line_4: addressLine4, region: county, locality: city, country, postcode, telephone_number: telephoneNumber, } = response.data;
                dispatch({
                    type: USER_ADDRESS_FETCH_SUCCESS,
                    payload: {
                        userId: id,
                        addressLine1,
                        addressLine2,
                        addressLine3,
                        addressLine4,
                        city,
                        county,
                        country,
                        postcode,
                        telephoneNumber,
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
