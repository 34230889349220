// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "footer{background-color:#261750;color:#fff;display:flex;flex-direction:column;align-items:center;padding-top:1.5rem;padding-bottom:1rem}@media(min-width: 600px){footer{padding:.75rem 0}}footer .Footer-module--socialFooter--skv9L{max-width:300px}footer .Footer-module--socialFooter--skv9L ul{display:flex;flex-direction:row;margin:0}footer .Footer-module--socialFooter--skv9L ul li img{width:2rem}@media(min-width: 600px){footer .Footer-module--socialFooter--skv9L ul li img{width:2.5rem}}footer .Footer-module--socialFooter--skv9L ul .Footer-module--twitter--sIeqd{padding:0 .75rem}@media(min-width: 600px){footer .Footer-module--socialFooter--skv9L ul .Footer-module--twitter--sIeqd{padding:0 1rem}}footer .Footer-module--copyrightFooter--tDsOa .Footer-module--copyright--z51sZ{font-size:.75rem;font-weight:600}@media(min-width: 600px){footer .Footer-module--copyrightFooter--tDsOa .Footer-module--copyright--z51sZ{font-size:1rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialFooter": "Footer-module--socialFooter--skv9L",
	"twitter": "Footer-module--twitter--sIeqd",
	"copyrightFooter": "Footer-module--copyrightFooter--tDsOa",
	"copyright": "Footer-module--copyright--z51sZ"
};
export default ___CSS_LOADER_EXPORT___;
