// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyCollectionWrapper-module--card--Yc1yB .MyCollectionWrapper-module--content--x2PC2{display:flex;flex-direction:column}.MyCollectionWrapper-module--card--Yc1yB .MyCollectionWrapper-module--content--x2PC2 .MyCollectionWrapper-module--unassignAlert--ZaVMK{margin-bottom:.5rem}@media(min-width: 600px){.MyCollectionWrapper-module--card--Yc1yB .MyCollectionWrapper-module--content--x2PC2 .MyCollectionWrapper-module--unassignAlert--ZaVMK{margin-top:2rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "MyCollectionWrapper-module--card--Yc1yB",
	"content": "MyCollectionWrapper-module--content--x2PC2",
	"unassignAlert": "MyCollectionWrapper-module--unassignAlert--ZaVMK"
};
export default ___CSS_LOADER_EXPORT___;
