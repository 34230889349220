import { createAsyncThunk } from '@reduxjs/toolkit';
import { cleanInductions } from '@redux/utilities/handleInductionData';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:inductions:fetchMyInductions');
const fetchMyInductions = createAsyncThunk('inductions/fetchMyInductions', async () => {
    try {
        const { data } = await axios.get(`/api/v1/inductions`);
        return cleanInductions(data);
    }
    catch (error) {
        debug(`Failed fetching user inductions`, loggableError(error));
    }
});
export default fetchMyInductions;
