// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnlimitedSlotLabel-module--root--vvDd1 .UnlimitedSlotLabel-module--label--nLdAi{display:flex;justify-content:center;background-color:#fff3ec;border-radius:.25rem;padding:.25rem .5rem;text-transform:uppercase;font-weight:600}.UnlimitedSlotLabel-module--root--vvDd1 .UnlimitedSlotLabel-module--label--nLdAi .UnlimitedSlotLabel-module--icon--NAAPX{width:1.5rem;height:1.5rem;margin-right:.25rem;color:#ff8743}.UnlimitedSlotLabel-module--root--vvDd1 .UnlimitedSlotLabel-module--explanation--I1jpi{padding-top:.5rem;padding-bottom:0;text-align:center;font-size:.75rem;font-style:italic;font-weight:500;color:#6f6f6f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "UnlimitedSlotLabel-module--root--vvDd1",
	"label": "UnlimitedSlotLabel-module--label--nLdAi",
	"icon": "UnlimitedSlotLabel-module--icon--NAAPX",
	"explanation": "UnlimitedSlotLabel-module--explanation--I1jpi"
};
export default ___CSS_LOADER_EXPORT___;
