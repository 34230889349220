import { createSlice } from '@reduxjs/toolkit';
import fetchDocuments from '@redux/thunks/documents/fetchDocuments';
import { FETCHING_STATUSES } from 'config/api';
const { LOADING, SUCCESS, IDLE, FAILED } = FETCHING_STATUSES;
const documentsSlice = createSlice({
    name: 'documents',
    initialState: {
        items: {},
        documentsFetchingStatus: IDLE,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDocuments.rejected, (state) => {
            state.documentsFetchingStatus = FAILED;
        });
        builder.addCase(fetchDocuments.fulfilled, (state, action) => {
            const documents = action.payload;
            const newDocuments = {};
            documents?.forEach((document) => {
                newDocuments[document.id] = document;
            });
            state.items = {
                ...state.items,
                ...newDocuments,
            };
            state.documentsFetchingStatus = SUCCESS;
        });
        builder.addCase(fetchDocuments.pending, (state) => {
            state.documentsFetchingStatus = LOADING;
        });
    },
});
const { reducer } = documentsSlice;
export default reducer;
