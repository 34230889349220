// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollapsibleAlert-module--alert--ArMUN{max-width:35.0625rem;padding:1rem;font-size:1rem}.CollapsibleAlert-module--alert--ArMUN .CollapsibleAlert-module--children--MPHiH{padding-top:0;padding-bottom:0}.CollapsibleAlert-module--alert--ArMUN .CollapsibleAlert-module--children--MPHiH:last-of-type{padding-bottom:1rem}.CollapsibleAlert-module--alert--ArMUN .CollapsibleAlert-module--icon--LVCKw{margin:0 .5rem .125rem .125rem;color:#ff8743;line-height:1}.CollapsibleAlert-module--alert--ArMUN .CollapsibleAlert-module--header--pTLOE{display:flex;margin-bottom:1px}.CollapsibleAlert-module--alert--ArMUN .CollapsibleAlert-module--toggle--G1WUW{cursor:pointer;color:#3b1869;border-bottom:.125rem solid #3b1869;font-weight:600}.CollapsibleAlert-module--alert--ArMUN u{text-decoration:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "CollapsibleAlert-module--alert--ArMUN",
	"children": "CollapsibleAlert-module--children--MPHiH",
	"icon": "CollapsibleAlert-module--icon--LVCKw",
	"header": "CollapsibleAlert-module--header--pTLOE",
	"toggle": "CollapsibleAlert-module--toggle--G1WUW"
};
export default ___CSS_LOADER_EXPORT___;
