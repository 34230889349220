import { createSlice } from '@reduxjs/toolkit';
import { COLLECTION_INDUCTIONS_FETCH, COLLECTION_INDUCTIONS_FETCH_SUCCESS, } from '@redux/actionTypes';
import completeInduction from '@redux/thunks/inductions/completeInduction';
import fetchInduction from '@redux/thunks/inductions/fetchInduction';
import fetchMyInductions from '@redux/thunks/inductions/fetchMyInductions';
import { FETCHING_STATUSES } from 'config/api';
const { LOADING, SUCCESS, IDLE, FAILED } = FETCHING_STATUSES;
const inductionsSlice = createSlice({
    name: 'inductions',
    initialState: {
        items: {},
        inductionsFetchingStatus: IDLE,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchInduction.fulfilled, (state, action) => {
            const inductionId = action.payload.id;
            state.items[inductionId] = action.payload;
            state.inductionsFetchingStatus = SUCCESS;
        });
        builder.addCase(fetchInduction.rejected, (state) => {
            state.inductionsFetchingStatus = FAILED;
        });
        builder.addCase(fetchMyInductions.fulfilled, (state, action) => {
            const inductions = action.payload;
            const newInductions = {};
            inductions?.forEach((induction) => {
                newInductions[induction.id] = induction;
            });
            state.items = {
                ...state.items,
                ...newInductions,
            };
            state.inductionsFetchingStatus = SUCCESS;
        });
        builder.addCase(fetchMyInductions.pending, (state) => {
            state.inductionsFetchingStatus = LOADING;
        });
        builder.addCase(completeInduction.fulfilled, (state, action) => {
            const inductionId = action.payload.id;
            state.items[inductionId] = { ...state.items[inductionId], isComplete: true };
        });
        builder.addCase(COLLECTION_INDUCTIONS_FETCH, (state) => {
            state.inductionsFetchingStatus = LOADING;
        });
        builder.addCase(COLLECTION_INDUCTIONS_FETCH_SUCCESS, (state, action) => {
            const { inductions } = action.payload;
            inductions.map((induction) => (state.items[induction.id] = {
                ...state.items[induction.id],
                ...induction,
            }));
            state.inductionsFetchingStatus = SUCCESS;
        });
    },
});
const { reducer } = inductionsSlice;
export default reducer;
