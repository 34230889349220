import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CLEAR_AUTHENTICATION_ERROR_MESSAGE } from '@redux/actionTypes';
import { useAppDispatch } from '@redux/hooks';
import loginPassword from '@redux/thunks/users/loginPassword';
import requestMagicToken from '@redux/thunks/users/requestMagicToken';
import { LOGIN_TYPES } from 'config/loginTypes';
import * as routes from 'config/routes';
import { VALIDATION } from 'config/validation';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import TextField from 'components/common/TextField';
const { MAGIC, PASSWORD } = LOGIN_TYPES;
const LoginForm = ({ loginType }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const methods = useForm({
        mode: 'onTouched',
        defaultValues: {
            email: '',
            password: '',
            rememberMe: false,
        },
    });
    const { getValues, formState: { isValid }, handleSubmit, register, unregister, control, } = methods;
    const forgotPasswordLink = `${routes.WEBAPP_URL}${routes.FORGOT_PASSWORD_URL}`;
    useEffect(() => {
        if (loginType === MAGIC) {
            unregister('password');
        }
        else if (loginType === PASSWORD) {
            register('password');
        }
    }, [loginType]);
    const onSubmit = () => {
        const { email, password, rememberMe } = getValues();
        if (isValid === false) {
            return null;
        }
        const originPathname = location.state?.from?.pathname;
        if (loginType === PASSWORD) {
            dispatch(loginPassword(email, password, rememberMe, originPathname));
        }
        else if (loginType === MAGIC) {
            dispatch(requestMagicToken(email));
        }
    };
    const clearAuthenticationError = () => {
        dispatch({ type: CLEAR_AUTHENTICATION_ERROR_MESSAGE });
    };
    return (_jsx(FormProvider, { ...methods, children: _jsxs("form", { id: "login-form", onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: "form-fields", children: [_jsx(TextField, { name: "email", type: "email", rules: {
                                required: true,
                                pattern: VALIDATION.LOGIN_EMAIL,
                            }, labelText: t('login.landing.password.labels.email'), customErrorMessage: _jsx(SetInnerHtmlSafe, { children: t('login-scene.errors.no-email-entered', {
                                    'error-invalid-prefix': `<b>${t('common.alerts.error-invalid-prefix')}</b>`,
                                }) }), onChange: clearAuthenticationError }), loginType === PASSWORD && (_jsxs(_Fragment, { children: [_jsx(TextField, { name: "password", type: "password", rules: {
                                        required: true,
                                        minLength: VALIDATION.LOGIN_PASSWORD_MIN_LENGTH,
                                    }, labelText: t('login.landing.password.labels.password'), customErrorMessage: _jsx(SetInnerHtmlSafe, { children: t('login-scene.errors.invalid-password', {
                                            'error-invalid-prefix': `<b>${t('common.alerts.error-invalid-prefix')}</b>`,
                                        }) }), onChange: clearAuthenticationError }), _jsx(Controller, { name: "rememberMe", control: control, rules: { required: false }, render: ({ field: { onChange, onBlur, value } }) => {
                                        return (_jsx(Checkbox, { checked: value, label: t('login.landing.password.labels.remember-me'), onChange: onChange, onBlur: onBlur }));
                                    } }), _jsx("div", { className: "forgot-password", children: _jsx("a", { className: "pink-text", href: forgotPasswordLink, children: t('login.landing.password.labels.forgot') }) })] }))] }), _jsx(Button, { type: "submit", buttonSize: "fullWidthButton", children: loginType === PASSWORD
                        ? t('login.landing.password.button')
                        : t('login.landing.magic.button') })] }) }));
};
export default LoginForm;
