import mixpanel from 'mixpanel-browser'
import Debug from 'helpers/debug'
import Env, { isProductionLike } from 'helpers/environment'

const debug = Debug('services:analytics:mixpanel')

class Mixpanel {
  constructor() {
    if (this.mixpanel !== undefined || Env.PUBLIC_MIXPANEL_API_KEY === undefined) {
      return
    }

    debug('Initialising mixpanel instance')
    this.mixpanel = mixpanel
    this.mixpanel.init(Env.PUBLIC_MIXPANEL_API_KEY, {
      ignore_dnt: true,
      batch_requests: true,
      debug: isProductionLike === false,
    })
  }

  identify = async (id) => {
    debug(`identifing user ${id}`)
    await this.mixpanel?.identify(id)
  }

  track = (eventName, eventPayload, options) => {
    return new Promise((resolve) => {
      mixpanel.track(eventName, eventPayload, options, () => {
        debug(`Track: ${eventName}, ${JSON.stringify(eventPayload)}`)
        resolve()
      })
    })
  }
}

export default new Mixpanel()
