import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import clsx from 'clsx';
import { useAppSelector } from '@redux/hooks';
import { sortRotaItemsByDate } from 'helpers/collections';
import RotaItem from 'components/common/StoreRota/RotaItem';
import styles from './Rota.module.scss';
const Rota = ({ storeId, className, timezone }) => {
    const { t } = useTranslation();
    const storeCollectionIds = useAppSelector((state) => state.stores.items[storeId]?.collections);
    const storeCollections = useAppSelector((state) => state?.collections?.items);
    const sortedStoreCollections = useMemo(() => {
        if (storeCollectionIds !== undefined && storeCollections !== undefined) {
            return sortRotaItemsByDate(storeCollectionIds, storeCollections);
        }
    }, [storeCollectionIds, storeCollections]);
    return (_jsxs("div", { className: clsx(className, styles.rota), children: [_jsx("h3", { children: t('your-collections.page.show.rota-key.page-title') }), _jsxs("div", { className: styles.wrapper, children: [_jsx("span", { className: styles.title, children: t('your-collections.page.show.rota-key.title') }), _jsxs("div", { className: styles.keyWrapper, children: [_jsxs("span", { className: clsx(styles.claimed, styles.key), children: [_jsx(FaCheckCircle, { "data-testid": "claimedIcon", className: styles.icon }), t('your-collections.page.show.rota-key.claimed')] }), _jsxs("span", { className: styles.key, children: [_jsx(FaRegCircle, { "data-testid": "unclaimedIcon", className: styles.icon }), t('your-collections.page.show.rota-key.unclaimed')] })] })] }), sortedStoreCollections !== undefined &&
                sortedStoreCollections.map((item, index) => {
                    const { nextCollection, userId, collectionState } = item;
                    return (_jsx(RotaItem, { nextCollection: nextCollection, userId: userId, collectionState: collectionState, timezone: timezone }, index));
                })] }));
};
export default Rota;
