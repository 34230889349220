import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { convertSecondsToMinutes } from 'helpers/time';
import VideoDurationIcon from 'images/VideoDurationIcon.svg';
const VideoDuration = ({ durationInSeconds }) => {
    const { t } = useTranslation();
    const durationInMinutes = useMemo(() => {
        return convertSecondsToMinutes(durationInSeconds);
    }, [durationInSeconds]);
    if (typeof durationInSeconds !== 'number') {
        return null;
    }
    return (_jsxs("div", { className: "video-duration", children: [_jsx("img", { src: VideoDurationIcon, alt: "" }), _jsx("span", { className: "video-duration-text", children: t('inductions.page.show.video_duration', { count: durationInMinutes }) })] }));
};
export default VideoDuration;
