import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import Env, { isDevelopment } from 'helpers/environment';
const currentLocale = window.locale;
const supportedLocales = ['en', 'es'];
const defaultLocale = 'en';
const LOCAL_URL = 'http://localhost:9292/';
const locale = supportedLocales.includes(currentLocale) === true ? currentLocale : defaultLocale;
const translationSource = locale === 'en' && isDevelopment ? LOCAL_URL : Env.PUBLIC_CDN_URL;
const initI18n = () => {
    i18next
        .use(HttpBackend)
        .use(initReactI18next)
        .init({
        lng: locale,
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
            prefix: '%{',
            suffix: '}',
        },
        backend: {
            loadPath: `${translationSource}hub-translations/${locale}.json?fallback=en`,
        },
    });
};
export { currentLocale, supportedLocales, defaultLocale, initI18n };
