// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollectionCard-module--collectionContainer--hxc59{margin-bottom:1rem;border-radius:1.25rem;padding:1rem;background-color:#fdf2eb}.CollectionCard-module--collectionContainer--hxc59 .CollectionCard-module--cardLink--g4WV3{color:unset}.CollectionCard-module--collectionContainer--hxc59 .CollectionCard-module--cardLink--g4WV3 .CollectionCard-module--collectionInfo--qdhCC{display:flex;align-items:center;font-size:.875rem;font-weight:400;margin-top:.75rem}@media(min-width: 992px){.CollectionCard-module--collectionContainer--hxc59 .CollectionCard-module--cardLink--g4WV3 .CollectionCard-module--collectionInfo--qdhCC{font-size:1rem;margin-top:1rem}}.CollectionCard-module--collectionContainer--hxc59 .CollectionCard-module--cardLink--g4WV3 .CollectionCard-module--collectionInfo--qdhCC .CollectionCard-module--icon--SOLqS{background:linear-gradient(150.62deg, #ffdc00 9.21%, #ff576a 59.31%, #f4700b 105.21%);background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0);margin-right:.5rem;line-height:1;width:1.5rem;height:1.5rem}.CollectionCard-module--collectionContainer--hxc59 .CollectionCard-module--cardLink--g4WV3 .CollectionCard-module--collectionInfo--qdhCC strong{font-weight:700}.CollectionCard-module--noCollectionContainer--TEoh0{margin:unset}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectionContainer": "CollectionCard-module--collectionContainer--hxc59",
	"cardLink": "CollectionCard-module--cardLink--g4WV3",
	"collectionInfo": "CollectionCard-module--collectionInfo--qdhCC",
	"icon": "CollectionCard-module--icon--SOLqS",
	"noCollectionContainer": "CollectionCard-module--noCollectionContainer--TEoh0"
};
export default ___CSS_LOADER_EXPORT___;
