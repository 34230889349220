import { BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS } from '@redux/actionTypes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:baskets:adjustBasketArticleQuantity');
export default (basketArticleId, quantity) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                const reason = loggableError(error);
                debug(`Failed setting basket article ${basketArticleId} quantity`, reason);
                reject(reason);
            };
            return axios
                .patch(`/api/v1/basket_articles/${basketArticleId}`, { items: quantity })
                .then((response) => {
                const { data } = response;
                dispatch({
                    type: BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS,
                    payload: {
                        basketArticleId,
                        articleId: data.article_id,
                        available: data.remaining_items,
                        quantity: data.items,
                    },
                    meta: {
                        analytics: {
                            eventName: BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS,
                            eventPayload: {
                                basketId: data.basket_id,
                                articleId: data.article_id,
                                quantity: data.items,
                            },
                        },
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
