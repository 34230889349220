// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DashboardScene-module--dashboardScene--MeKIP .DashboardScene-module--welcomeCard--N4IRB{margin-top:1rem;max-width:unset}@media(min-width: 600px){.DashboardScene-module--dashboardScene--MeKIP .DashboardScene-module--welcomeCard--N4IRB{margin-top:2.5rem}}.DashboardScene-module--dashboardScene--MeKIP .DashboardScene-module--welcomeCard--N4IRB a{max-width:14.9375rem}@media(min-width: 600px){.DashboardScene-module--dashboardScene--MeKIP .DashboardScene-module--welcomeCard--N4IRB a{max-width:19.125rem}}.DashboardScene-module--dashboardScene--MeKIP .DashboardScene-module--widgetContainer--yhQL4{display:flex;margin-top:1rem;gap:1rem}@media(min-width: 600px){.DashboardScene-module--dashboardScene--MeKIP .DashboardScene-module--widgetContainer--yhQL4{margin-top:2.5rem}}.DashboardScene-module--dashboardScene--MeKIP .DashboardScene-module--widgetContainer--yhQL4 .DashboardScene-module--column--mor78{display:flex;flex-direction:column;gap:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardScene": "DashboardScene-module--dashboardScene--MeKIP",
	"welcomeCard": "DashboardScene-module--welcomeCard--N4IRB",
	"widgetContainer": "DashboardScene-module--widgetContainer--yhQL4",
	"column": "DashboardScene-module--column--mor78"
};
export default ___CSS_LOADER_EXPORT___;
