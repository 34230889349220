import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '@redux/hooks';
import fetchCurrentUser from '@redux/thunks/users/fetchCurrentUser';
import Debug from 'helpers/debug';
const debug = Debug('components:global-data-fetcher');
const GlobalDataFetcher = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchCurrentUser()).catch(debug);
    }, []);
    return _jsx(Outlet, {});
};
export default GlobalDataFetcher;
