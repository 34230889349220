// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UsersNearMeWidget-module--card--Oq2Qg{height:fit-content}.UsersNearMeWidget-module--card--Oq2Qg .UsersNearMeWidget-module--headingSection--K6k1f{display:flex;justify-content:space-between;align-items:center;gap:1rem}.UsersNearMeWidget-module--card--Oq2Qg .UsersNearMeWidget-module--innerCard--CCQaE{background-color:#f8ecf4;box-shadow:unset}.UsersNearMeWidget-module--card--Oq2Qg .UsersNearMeWidget-module--innerCard--CCQaE p{color:#252525}.UsersNearMeWidget-module--card--Oq2Qg .UsersNearMeWidget-module--innerCard--CCQaE .UsersNearMeWidget-module--innerCardHeading--RrbM4{font-size:.875rem;font-weight:600;padding:0}@media(min-width: 600px){.UsersNearMeWidget-module--card--Oq2Qg .UsersNearMeWidget-module--innerCard--CCQaE .UsersNearMeWidget-module--innerCardHeading--RrbM4{font-size:1rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "UsersNearMeWidget-module--card--Oq2Qg",
	"headingSection": "UsersNearMeWidget-module--headingSection--K6k1f",
	"innerCard": "UsersNearMeWidget-module--innerCard--CCQaE",
	"innerCardHeading": "UsersNearMeWidget-module--innerCardHeading--RrbM4"
};
export default ___CSS_LOADER_EXPORT___;
