// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginEmailRequestedScene-module--root--sRf22{display:flex;justify-content:center;text-align:center}.LoginEmailRequestedScene-module--root--sRf22 .LoginEmailRequestedScene-module--card--H95uV{padding:40px;max-width:initial}@media(min-width: 992px){.LoginEmailRequestedScene-module--root--sRf22{margin-top:40px}.LoginEmailRequestedScene-module--root--sRf22 .LoginEmailRequestedScene-module--card--H95uV{max-width:50%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LoginEmailRequestedScene-module--root--sRf22",
	"card": "LoginEmailRequestedScene-module--card--H95uV"
};
export default ___CSS_LOADER_EXPORT___;
