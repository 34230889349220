import { useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchAvailableCollections from '@redux/thunks/collections/fetchAvailableCollections';
import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses';
import fetchStores from '@redux/thunks/stores/fetchStores';
import { FETCHING_STATUSES } from 'config/api';
import Debug from 'helpers/debug';
const { IDLE, LOADING } = FETCHING_STATUSES;
const debug = Debug('javascript:hooks:collections:useFetchAvailableCollectionsWithStoresAndBusinesses');
const useFetchAvailableCollectionsWithStoresAndBusinesses = () => {
    const dispatch = useAppDispatch();
    const availableCollections = useAppSelector(({ collections, stores }) => {
        return Object.entries(collections.items)
            .filter(([_collectionId, collection]) => collection.available === true)
            .map(([collectionId, collection]) => {
            const collectionStoreId = collection.storeId;
            const collectionStoreInfo = stores.items[collectionStoreId];
            const collectionBusinessInfo = stores.businesses[collectionStoreInfo.businessId];
            return {
                collectionId,
                ...collection,
                store: collectionStoreInfo,
                business: collectionBusinessInfo,
            };
        });
    }, shallowEqual);
    const collectionsFetchStatus = useAppSelector((state) => state.collections.collectionsFetchingStatus);
    const storesFetchStatus = useAppSelector((state) => state.stores.storesFetchingStatus);
    const businessesFetchingStatus = useAppSelector((state) => state.stores.businessesFetchingStatus);
    const hasCollectionsFetched = collectionsFetchStatus !== IDLE && collectionsFetchStatus !== LOADING;
    const hasStoresFetched = storesFetchStatus !== IDLE && storesFetchStatus !== LOADING;
    const hasBusinessesFetched = businessesFetchingStatus !== IDLE && businessesFetchingStatus !== LOADING;
    const stores = useAppSelector((state) => state.stores?.items, shallowEqual);
    const storeIds = useMemo(() => Object.keys(stores), [stores]);
    const businessIds = useMemo(() => {
        const ids = [];
        Object.values(stores).forEach((store) => {
            if (store.businessId !== undefined) {
                ids.push(store.businessId);
            }
        });
        return ids;
    }, [stores]);
    const uniqueBusinessIds = [...new Set(businessIds)];
    useEffect(() => {
        dispatch(fetchAvailableCollections()).catch(debug);
    }, []);
    useEffect(() => {
        if (storeIds !== undefined && storeIds.length > 0) {
            dispatch(fetchStores(storeIds)).catch(debug);
        }
    }, [storeIds.length]);
    useEffect(() => {
        if (uniqueBusinessIds !== undefined && uniqueBusinessIds.length > 0) {
            dispatch(fetchBusinesses(uniqueBusinessIds)).catch(debug);
        }
    }, [uniqueBusinessIds.length]);
    return {
        availableCollections,
        hasCollectionsFetched,
        hasBusinessesFetched,
        hasStoresFetched,
    };
};
export default useFetchAvailableCollectionsWithStoresAndBusinesses;
