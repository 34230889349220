import { AUTHENTICATE_USER_SUCCESS } from '@redux/actionTypes'
import Mixpanel from 'services/Analytics/Mixpanel'
import { trackedEvents, trackedRTKEvents } from 'services/Analytics/config'
import { trackAdEvent, trackEvent } from 'services/Analytics/trackEvent'

const identifyUser = (userId) => {
  if (!userId) return
  Mixpanel.identify(userId)
}

const middlewareTracking = (action) => {
  if (action.type === AUTHENTICATE_USER_SUCCESS) {
    identifyUser(action.payload?.id)
  }

  if (trackedEvents.includes(action.type)) {
    const name = action?.meta?.analytics?.eventName || action.type
    const payload = action?.meta?.analytics?.eventPayload || {}
    trackEvent(name, payload)
  }

  if (trackedRTKEvents.includes(action.type)) {
    const name = action.payload?.meta?.analytics?.eventName || action.type
    const payload = action.payload?.meta?.analytics?.eventPayload || {}
    trackEvent(name, payload)
  }
}

export { identifyUser, middlewareTracking, trackEvent, trackAdEvent }
