import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useLocation } from 'react-router-dom';
import { DASHBOARD_URL, LOGIN_URL } from 'config/routes';
import { useAuthenticatedUser, useIsUserAuthenticated } from 'hooks/users';
const RequireNonFoodHeroAccess = ({ children }) => {
    const location = useLocation();
    const isUserAuthenticated = useIsUserAuthenticated();
    const { isFoodHero, isUserLoaded } = useAuthenticatedUser({ shouldFetchUser: true });
    if (isUserAuthenticated === false) {
        return (_jsx(Navigate, { to: `${LOGIN_URL}${location.search}`, state: { from: location }, replace: true }));
    }
    if (isUserLoaded === false) {
        return null;
    }
    if (isFoodHero === true) {
        return (_jsx(Navigate, { to: DASHBOARD_URL, replace: true }));
    }
    return children;
};
export default RequireNonFoodHeroAccess;
