import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { INDUCTIONS_URL } from 'config/routes';
import Button from 'components/common/Button';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import { Stack } from 'components/layout/primitives';
const QuizSuccess = ({ questionCount, inductionTitle }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isOriginDefined = location.state?.origin !== undefined;
    const navigateToInductionsList = () => {
        if (isOriginDefined === true) {
            return navigate(location.state.origin);
        }
        return navigate(INDUCTIONS_URL);
    };
    const questionCountStyled = `<b>${questionCount}</b>`;
    const questionCountText = t('inductions.page.show.quiz.results_description_2', {
        correct: questionCountStyled,
        total: questionCountStyled,
    });
    const inductionTitleText = t('inductions.page.show.quiz.well_done', {
        inductionTitle: `<b>${inductionTitle}</b>`,
    });
    const greatJobText = t('inductions.page.show.quiz.visit_documents.text', {
        highlightedText: `<b>${t('inductions.page.show.quiz.visit_documents.highlighted_text')}</b>`,
    });
    return (_jsx("div", { className: "card induction-quiz-results", children: _jsx("div", { className: "card-content", children: _jsxs(Stack, { gap: "1rem", children: [_jsx("h2", { children: t('inductions.page.show.quiz.your_results') }), _jsx("p", { children: t('inductions.page.show.quiz.results_description_1') }), _jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: questionCountText }) }), _jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: inductionTitleText }) }), _jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: greatJobText }) }), _jsx(Button, { onClick: navigateToInductionsList, children: t('inductions.page.show.quiz.view_remaining_inductions') })] }) }) }));
};
export default QuizSuccess;
