// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InductionProgress-module--inductionProgress--m5RXc{display:flex;align-items:center;padding-top:1.5rem}.InductionProgress-module--inductionProgress--m5RXc .InductionProgress-module--inductionProgressValues--zdA0D{margin-left:.25rem;font-weight:600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inductionProgress": "InductionProgress-module--inductionProgress--m5RXc",
	"inductionProgressValues": "InductionProgress-module--inductionProgressValues--zdA0D"
};
export default ___CSS_LOADER_EXPORT___;
