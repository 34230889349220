import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useLocation } from 'react-router-dom';
import { LOGIN_URL, LOW_RATING_ERROR_URL } from 'config/routes';
import { useAuthenticatedUser, useIsUserAuthenticated } from 'hooks/users';
const RequireAuthentication = ({ children }) => {
    const location = useLocation();
    const { hasLowRating } = useAuthenticatedUser({ shouldFetchUser: true });
    const isUserAuthenticated = useIsUserAuthenticated();
    if (isUserAuthenticated === false) {
        return (_jsx(Navigate, { to: LOGIN_URL, state: { from: location }, replace: true }));
    }
    if (hasLowRating === true) {
        return (_jsx(Navigate, { to: LOW_RATING_ERROR_URL, replace: true }));
    }
    return children;
};
export default RequireAuthentication;
