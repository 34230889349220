import { useAppSelector } from '@redux/hooks';
import { selectPreferenceWithFetchingStatus } from '@redux/selectors/preferences';
const usePreference = (preferenceName) => {
    const { userPreference, fetchingStatus } = useAppSelector((state) => selectPreferenceWithFetchingStatus(state, preferenceName));
    return {
        preference: userPreference,
        preferencesFetchingStatus: fetchingStatus,
    };
};
export default usePreference;
