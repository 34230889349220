import { FETCHING_STATUSES } from 'config/api';
import { MODAL_MODES, STEP_DATE, STEP_TARGET_COLLECTION } from 'helpers/collections';
const { IDLE } = FETCHING_STATUSES;
export default {
    items: {},
    collectionsFetchingStatus: IDLE,
    error: null,
    unassign: {
        modalMode: MODAL_MODES.OPTIONS,
        step: STEP_DATE,
        selectedStoreId: undefined,
        selectedCollectionId: undefined,
        selectedDate: undefined,
        errors: undefined,
    },
    skip: {
        modalMode: MODAL_MODES.OPTIONS,
        step: STEP_DATE,
        selectedStoreId: undefined,
        selectedCollectionId: undefined,
        selectedDate: undefined,
        errors: undefined,
    },
    pause: {
        modalMode: MODAL_MODES.OPTIONS,
        step: STEP_DATE,
        selectedStoreId: undefined,
        selectedCollectionId: undefined,
        selectedDate: undefined,
        errors: undefined,
        selectedIncrement: undefined,
    },
    swap: {
        modalMode: MODAL_MODES.FORBIDDEN,
        step: STEP_TARGET_COLLECTION,
        selectedStoreId: undefined,
        selectedCollectionId: undefined,
        swapForCollectionId: undefined,
        newCollectionId: undefined,
        errors: undefined,
    },
};
