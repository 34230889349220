// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label-module--label--n14dR{display:flex;align-items:center;font-size:.875rem;border-radius:.25rem;background-color:#f9f9f9;width:fit-content;margin-top:1.5rem;padding:.25rem .5rem}.Label-module--label--n14dR .Label-module--labelIcon--RStVH{color:#474747;width:24px;height:24px;margin-right:4px}.Label-module--label--n14dR .Label-module--text--X3Ps_{padding-right:.5rem;color:#474747;font-weight:600}.Label-module--label--n14dR.Label-module--green--HCVKd{background-color:#e6f1ec;margin-bottom:1rem;margin-top:1rem;border-radius:1rem}.Label-module--label--n14dR.Label-module--green--HCVKd .Label-module--text--X3Ps_,.Label-module--label--n14dR.Label-module--green--HCVKd .Label-module--labelIcon--RStVH{color:#26762c}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "Label-module--label--n14dR",
	"labelIcon": "Label-module--labelIcon--RStVH",
	"text": "Label-module--text--X3Ps_",
	"green": "Label-module--green--HCVKd"
};
export default ___CSS_LOADER_EXPORT___;
