import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import styles from './Address.module.scss';
const Address = ({ address }) => {
    return (_jsx("address", { className: styles.address, children: address &&
            Object.values(address).map((addressLine, index) => {
                return _jsx("span", { children: addressLine }, addressLine + index);
            }) }));
};
export default memo(Address);
