import { createSlice } from '@reduxjs/toolkit';
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences';
import updateUserPreferences from '@redux/thunks/preferences/updateUserPreferences';
import { FETCHING_STATUSES } from 'config/api';
const { LOADING, SUCCESS, IDLE, FAILED } = FETCHING_STATUSES;
const preferencesSlice = createSlice({
    name: 'preferences',
    initialState: {
        userPreferences: [],
        preferencesFetchingStatus: IDLE,
        preferencesUpdatingStatus: IDLE,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserPreferences.fulfilled, (state, action) => {
            const preferences = action.payload;
            state.userPreferences = preferences;
            state.preferencesFetchingStatus = SUCCESS;
        });
        builder.addCase(fetchUserPreferences.pending, (state) => {
            state.preferencesFetchingStatus = LOADING;
        });
        builder.addCase(fetchUserPreferences.rejected, (state) => {
            state.preferencesFetchingStatus = FAILED;
        });
        builder.addCase(updateUserPreferences.fulfilled, (state) => {
            state.preferencesUpdatingStatus = SUCCESS;
        });
        builder.addCase(updateUserPreferences.rejected, (state) => {
            state.preferencesUpdatingStatus = FAILED;
        });
    },
});
const { reducer } = preferencesSlice;
export default reducer;
