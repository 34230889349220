import { LOGIN_URL } from 'config/routes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:users:logout');
export default () => {
    return () => {
        return new Promise((resolve, reject) => {
            const failedCall = (error) => {
                const reason = loggableError(error);
                debug(`Failed logging out`, reason);
                reject(reason);
            };
            return axios
                .delete(`/api/v1/sessions`)
                .then(() => {
                window.userId = undefined;
                window.location.assign(`${LOGIN_URL}?logout-success=true`);
                resolve();
            })
                .catch(failedCall);
        });
    };
};
