// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HelpfulInformation-module--root--udB3Z{margin-bottom:1.5rem}@media(min-width: 600px){.HelpfulInformation-module--root--udB3Z{margin:2rem 0 1.5rem}}.HelpfulInformation-module--root--udB3Z .HelpfulInformation-module--title--Gr434{padding:1.5rem 0 21px 1.5rem}.HelpfulInformation-module--root--udB3Z .HelpfulInformation-module--icon--gxREB{color:#3b1869;background-color:#dedbec;border-radius:50%;padding:3px;width:30px;height:30px}.HelpfulInformation-module--root--udB3Z .HelpfulInformation-module--label--MoLIy{margin-left:5px;padding-top:5px;border-bottom:.125rem solid #3b1869}.HelpfulInformation-module--root--udB3Z a{color:#3b1869}.HelpfulInformation-module--root--udB3Z .HelpfulInformation-module--documentsList--RfSPy{padding-left:1.5rem;padding-bottom:15px}.HelpfulInformation-module--root--udB3Z .HelpfulInformation-module--documentsList--RfSPy .HelpfulInformation-module--listItem--WvNx5{padding-bottom:26px}.HelpfulInformation-module--root--udB3Z .HelpfulInformation-module--documentsList--RfSPy li{display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "HelpfulInformation-module--root--udB3Z",
	"title": "HelpfulInformation-module--title--Gr434",
	"icon": "HelpfulInformation-module--icon--gxREB",
	"label": "HelpfulInformation-module--label--MoLIy",
	"documentsList": "HelpfulInformation-module--documentsList--RfSPy",
	"listItem": "HelpfulInformation-module--listItem--WvNx5"
};
export default ___CSS_LOADER_EXPORT___;
