import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchUser from '@redux/thunks/users/fetchUser';
import { COLLECTION_STATES } from 'helpers/collections';
import Debug from 'helpers/debug';
import { formatDateToDayOfWeekAndDate, formatDateToTime24 } from 'helpers/time';
import { useCurrentUserId } from 'hooks/users';
import styles from './RotaItem.module.scss';
const debug = Debug('components:store-rota');
const { UNASSIGNED } = COLLECTION_STATES;
const RotaItem = ({ nextCollection, userId, collectionState, timezone }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentUserId = useCurrentUserId();
    const userName = useAppSelector((state) => {
        if (currentUserId === userId) {
            return state.users.currentUser?.name?.firstName;
        }
        return state.users.items[userId]?.name;
    });
    useEffect(() => {
        if (collectionState !== UNASSIGNED &&
            userId !== undefined &&
            Number(currentUserId) !== userId) {
            dispatch(fetchUser(userId)).catch(debug);
        }
    }, [userId]);
    return (_jsxs("div", { className: styles.rotaItem, children: [_jsxs("div", { className: styles.bulletAndDate, children: [_jsx("span", { className: styles.icon, children: collectionState === UNASSIGNED ? (_jsx(FaRegCircle, { "data-testid": "unclaimedIcon" })) : (_jsx(FaCheckCircle, { "data-testid": "claimedIcon" })) }), _jsx("div", { "data-testid": "rotaItem", className: styles.dateTime, children: formatDateToDayOfWeekAndDate(nextCollection, timezone) })] }), _jsx("div", { className: styles.nameAndTime, children: _jsxs("span", { children: [formatDateToTime24(nextCollection, timezone), " |", ' ', collectionState === UNASSIGNED
                            ? t('your-collections.page.show.rota.unclaimed')
                            : userName] }) })] }));
};
export default RotaItem;
