import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import EmailImage from 'images/email-icon.png';
import styles from './LoginEmailRequestedScene.module.scss';
const LoginEmailRequestedScene = () => {
    const { t } = useTranslation();
    return (_jsx("div", { className: styles.root, children: _jsxs("div", { className: clsx(styles.card, 'card'), children: [_jsx("img", { alt: "", src: EmailImage }), _jsx("h3", { children: t('login.magic_login_sent.title') }), _jsx("p", { children: t('login.magic_login_sent.description') }), _jsx("p", { children: t('login.magic_login_sent.try-again') })] }) }));
};
export default LoginEmailRequestedScene;
