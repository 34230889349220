import { MODAL_OPEN } from '@redux/actionTypes';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import closeModal from '@redux/thunks/application/closeModal';
import openModal from '@redux/thunks/application/openModal';
import { trackEvent } from 'services/Analytics';
const useModal = (key, { actionOnClose, trackingProperties } = {}) => {
    const dispatch = useAppDispatch();
    const modal = useAppSelector((state) => state.application.openedModals.find((modal) => modal.key === key));
    const handleOpenModal = (data) => {
        trackEvent(MODAL_OPEN, { modalKey: key, ...trackingProperties });
        dispatch(openModal({ key, data }));
    };
    const handleCloseModal = () => {
        if (actionOnClose !== undefined) {
            actionOnClose();
        }
        dispatch(closeModal({ key }));
    };
    return {
        isOpen: Boolean(modal),
        data: modal?.data,
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
    };
};
export default useModal;
