import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdInsertLink, MdPictureAsPdf, MdSlideshow, MdSubject } from 'react-icons/md';
import { useAppDispatch } from '@redux/hooks';
import fetchDocuments from '@redux/thunks/documents/fetchDocuments';
import { useIsMobile } from 'hooks/application';
import { useAllDocuments } from 'hooks/documents';
import { useAuthenticatedUser } from 'hooks/users';
import NoDocumentIcon from 'images/Tomato.svg';
import DocumentCard from 'components/Documents/DocumentCard';
import Dropdown from 'components/common/Dropdown';
import InfoItem, { infoItemTypes } from 'components/common/InfoItem';
import LoadingSpinner from 'components/common/LoadingSpinner';
import { Grid } from 'components/layout/primitives';
import styles from './DocumentsList.module.scss';
const { EMPTY } = infoItemTypes;
const countryFilterKeys = {
    all: 'all-countries',
};
const DocumentIcon = {
    picture_as_pdf: _jsx(MdPictureAsPdf, {}),
    slideshow: _jsx(MdSlideshow, {}),
    link: _jsx(MdInsertLink, {}),
    subject: _jsx(MdSubject, {}),
};
const DocumentsList = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const [selectedCountryForDocuments, setSelectedCountryForDocuments] = useState(countryFilterKeys.all);
    const { data: documents, isLoading } = useAllDocuments();
    const { user } = useAuthenticatedUser({ shouldFetchUser: false });
    const userCountry = user?.address?.country;
    const hasNoDocuments = isLoading === false && documents.length === 0;
    const countryFilterOptions = useMemo(() => {
        const countries = documents.map((document) => document.country);
        const uniqueCountries = [...new Set(countries)];
        return uniqueCountries.map((country) => ({
            value: country,
            label: country,
        }));
    }, [documents]);
    const onCountryChange = (event) => setSelectedCountryForDocuments(event.target.value);
    const filterByCountry = (document) => {
        if (selectedCountryForDocuments === countryFilterKeys.all) {
            return true;
        }
        return document.country === selectedCountryForDocuments;
    };
    const defaultDocuments = countryFilterOptions.some((document) => document.value === userCountry);
    const filteredDocuments = useMemo(() => {
        return documents.filter(filterByCountry);
    }, [documents, selectedCountryForDocuments]);
    useEffect(() => {
        dispatch(fetchDocuments());
    }, []);
    useEffect(() => {
        if (userCountry !== undefined && defaultDocuments === true) {
            setSelectedCountryForDocuments(userCountry);
        }
    }, [userCountry, defaultDocuments]);
    if (isLoading === true || userCountry === undefined) {
        return _jsx(LoadingSpinner, { containerHeight: "400px" });
    }
    return (_jsxs(_Fragment, { children: [hasNoDocuments === true && (_jsx("div", { id: "no-documents", children: _jsx(InfoItem, { type: EMPTY, title: t('documents.unavailable'), description1: t('documents.unavailable-description'), icon: NoDocumentIcon }) })), hasNoDocuments === false && (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.dropdown, children: [_jsx("label", { htmlFor: "country-dropdown", children: _jsx("span", { children: t('documents.filter-label') }) }), _jsx(Dropdown, { name: "country-dropdown", className: styles.countrySelector, onChange: onCountryChange, value: selectedCountryForDocuments, options: [
                                    {
                                        value: countryFilterKeys.all,
                                        label: t('documents.dropdown-placeholder'),
                                    },
                                    ...countryFilterOptions,
                                ] })] }), _jsx(Grid, { columns: isMobile === true ? 1 : 3, gap: "1rem", children: filteredDocuments.map((document) => {
                            return (_jsx(DocumentCard, { title: document.name, description: document.description, link: document.url, icon: DocumentIcon[document.icon] }, document.id));
                        }) })] }))] }));
};
export default DocumentsList;
