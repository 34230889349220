import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@redux/hooks';
import closeSwapModal from '@redux/thunks/collections/closeSwapModal';
import { modalKeys, modalTrackingKeys } from 'config/modals';
import { useModal } from 'hooks/application';
import Button from 'components/common/Button';
import { ModalFooter } from 'components/common/Modal/ConfirmationModal';
import styles from './Forbidden.module.scss';
const Forbidden = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { closeModal } = useModal(modalKeys.SWAP_COLLECTION);
    const handleCloseClick = () => {
        closeModal();
        dispatch(closeSwapModal());
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.modalContent, children: [_jsx("h2", { children: t('your-collections.page.show.modals.swap-forbidden.title') }), _jsx("p", { className: styles.forbiddenList, dangerouslySetInnerHTML: {
                            __html: t('your-collections.page.show.modals.swap-forbidden.list'),
                        } }), _jsx("p", { dangerouslySetInnerHTML: {
                            __html: t('your-collections.page.show.modals.swap-forbidden.warning'),
                        } })] }), _jsx(ModalFooter, { children: _jsx(Button, { "data-tracking": modalTrackingKeys.reject, onClick: handleCloseClick, children: t('your-collections.page.show.modals.swap-forbidden.button') }) })] }));
};
export default Forbidden;
