import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import FacebookIcon from 'images/facebook_circle.svg';
import InstagramIcon from 'images/instagram_circle.svg';
import TwitterIcon from 'images/twitter_circle.svg';
import styles from './Footer.module.scss';
const Footer = () => {
    const { t } = useTranslation();
    const year = new Date().getFullYear();
    return (_jsxs("footer", { "data-testid": "footer", children: [_jsx("div", { className: styles.socialFooter, children: _jsxs("ul", { children: [_jsx("li", { children: _jsx("a", { href: t('external-urls.instagram'), target: "_blank", "data-tracking-event": "NAVIGATE_INSTAGRAM", "data-tracking-properties": '{"origin": "footer"}', rel: "noreferrer", "data-testid": "instagram-footer", children: _jsx("img", { alt: "Instagram", src: InstagramIcon }) }) }), _jsx("li", { className: styles.twitter, children: _jsx("a", { href: t('external-urls.twitter'), target: "_blank", "data-tracking-event": "NAVIGATE_TWITTER", "data-tracking-properties": '{"origin": "footer"}', rel: "noreferrer", "data-testid": "twitter-footer", children: _jsx("img", { alt: "Twitter", src: TwitterIcon }) }) }), _jsx("li", { children: _jsx("a", { href: t('external-urls.facebook'), target: "_blank", "data-tracking-event": "NAVIGATE_FACEBOOK", "data-tracking-properties": '{"origin": "footer"}', rel: "noreferrer", "data-testid": "facebook-footer", children: _jsx("img", { alt: "Facebook", src: FacebookIcon }) }) })] }) }), _jsx("div", { className: styles.copyrightFooter, children: _jsxs("div", { className: styles.copyright, children: [t('company.name'), " \u00A9 ", year] }) })] }));
};
export default Footer;
