import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as routes from 'config/routes';
import { getAppDeepLink } from 'helpers/nativeAppDeepLink';
import BasketsList from 'components/Baskets/List';
import Button from 'components/common/Button';
import SceneHeader from 'components/common/SceneHeader';
const BasketsScene = () => {
    const { t } = useTranslation();
    const [requesterId, setRequesterId] = useState(undefined);
    const [searchParams] = useSearchParams();
    const getSearchParams = (value) => searchParams.get(value);
    const originRequesterId = getSearchParams('requester_id');
    const webappMetaTag = document.querySelector("meta[name='web-app']")?.getAttribute('content');
    const isNotFromWebapp = webappMetaTag === 'false';
    useEffect(() => {
        if (originRequesterId !== undefined) {
            setRequesterId(Number(originRequesterId));
        }
    }, [originRequesterId]);
    return (_jsx("main", { children: _jsxs("div", { id: "baskets-scene", children: [_jsx(SceneHeader, { title: t('baskets.header_title') }), _jsx(BasketsList, { requesterId: requesterId }), _jsx("div", { className: "footer", children: (isIOS === false || isNotFromWebapp === false) && (_jsx(Button, { href: isMobile === true ? getAppDeepLink() : routes.DASHBOARD, children: t('baskets.close_button') })) })] }) }));
};
export default BasketsScene;
