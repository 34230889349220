import { useEffect, useState } from 'react';
import { DESKTOP_BREAKPOINT } from 'helpers/display';
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < DESKTOP_BREAKPOINT);
    useEffect(() => {
        const resizeListener = () => {
            setIsMobile(window.innerWidth < DESKTOP_BREAKPOINT);
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);
    return isMobile;
};
export default useIsMobile;
