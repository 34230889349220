import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { MdOutlineChecklist } from 'react-icons/md';
import { trackEvent } from 'services/Analytics';
import { useIsMobile } from 'hooks/application';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import styles from './WhatYouNeed.module.scss';
const WhatYouNeed = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const olioWebSite = t('common.links.olio-website');
    const appStoreLink = t('common.links.app-store');
    const playStoreLink = t('common.links.play-store');
    const whatYouNeedLink = useMemo(() => {
        if (isIOS === true) {
            return appStoreLink;
        }
        else if (isAndroid === true) {
            return playStoreLink;
        }
        else {
            return olioWebSite;
        }
    }, [isIOS, isAndroid]);
    const handleClick = () => {
        const eventName = 'FWH_LANDING_VERIFY';
        trackEvent(eventName);
    };
    const link = `<a href="${whatYouNeedLink}" class="no-button-styling ${styles.button}">
      ${t('landing-page.what-you-need.description1.bold-text')}</a>`;
    const whatYouNeedText = t('landing-page.what-you-need.description1.text', {
        bold: link,
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.heading, children: [_jsx("span", { className: styles.icon, children: _jsx(MdOutlineChecklist, {}) }), _jsx("h3", { children: t('landing-page.what-you-need.title') })] }), _jsxs("div", { className: styles.content, children: [_jsx("strong", { children: t('landing-page.what-you-need.subheading') }), _jsx("p", { onClick: handleClick, children: _jsx(SetInnerHtmlSafe, { children: whatYouNeedText }) }), isMobile === true && _jsx("p", { children: t('landing-page.what-you-need.description2.text') })] })] }));
};
export default WhatYouNeed;
