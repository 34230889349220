// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Alert-module--alert--PduKg{border-radius:.75rem;padding:.5rem;font-size:.875rem;display:flex}@media(min-width: 600px){.Alert-module--alert--PduKg{font-size:1rem}}@media(max-width: 599px){.Alert-module--alert--PduKg .Alert-module--children--M9oKx{align-self:center}}.Alert-module--alert--PduKg.Alert-module--error--T8Wqm{background-color:#fbebe8}.Alert-module--alert--PduKg.Alert-module--error--T8Wqm .Alert-module--alertIcon--z0jrW{color:#d4351c}.Alert-module--alert--PduKg.Alert-module--info--ppTnT{background-color:#e5f3fc}.Alert-module--alert--PduKg.Alert-module--info--ppTnT .Alert-module--alertIcon--z0jrW{color:#0084e4}.Alert-module--alert--PduKg.Alert-module--status--F4nMX{background-color:#fffce6}.Alert-module--alert--PduKg.Alert-module--status--F4nMX .Alert-module--alertIcon--z0jrW{color:#fd0}.Alert-module--alert--PduKg.Alert-module--message--CDJUt{padding-top:1.125rem;background-color:#fff}.Alert-module--alert--PduKg.Alert-module--message--CDJUt p{padding-top:0}.Alert-module--alert--PduKg.Alert-module--message--CDJUt .Alert-module--alertIcon--z0jrW{color:#bb4291}.Alert-module--alert--PduKg.Alert-module--recommendation--AggH9{background-color:rgba(0,0,0,0);border:1px solid #474747;border-radius:.5rem}.Alert-module--alert--PduKg.Alert-module--recommendation--AggH9 .Alert-module--alertIcon--z0jrW{color:#474747}.Alert-module--alert--PduKg .Alert-module--alertIcon--z0jrW{vertical-align:bottom;margin-right:.375rem;width:1.5rem;height:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "Alert-module--alert--PduKg",
	"children": "Alert-module--children--M9oKx",
	"error": "Alert-module--error--T8Wqm",
	"alertIcon": "Alert-module--alertIcon--z0jrW",
	"info": "Alert-module--info--ppTnT",
	"status": "Alert-module--status--F4nMX",
	"message": "Alert-module--message--CDJUt",
	"recommendation": "Alert-module--recommendation--AggH9"
};
export default ___CSS_LOADER_EXPORT___;
