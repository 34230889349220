import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense, lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@redux/hooks';
import fetchUserAddress from '@redux/thunks/users/fetchUserAddress';
import Debug from 'helpers/debug';
import { getCurrentUserId } from 'helpers/user';
import LoadingSpinner from 'components/common/LoadingSpinner';
import SceneHeader from 'components/common/SceneHeader';
import Footer from 'components/layout/Footer';
const RegistrationForm = lazy(() => import('components/Registration/RegistrationForm'));
const debug = Debug('scenes:registration');
const RegistrationScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        const currentUserId = getCurrentUserId();
        dispatch(fetchUserAddress(currentUserId)).catch(debug);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("main", { id: "registration-scene", children: _jsxs("div", { className: "scene-container", children: [_jsx(SceneHeader, { title: t('food_waste_heroes.join.register_as_a_food_waste_hero') }), _jsxs("div", { className: "form-description", children: [_jsx("p", { children: t('food_waste_heroes.join.food_waste_hero_description') }), _jsx("p", { className: "notice", children: t('food_waste_heroes.join.food_waste_hero_description_notice1') }), _jsx("p", { className: "notice", children: t('food_waste_heroes.join.food_waste_hero_description_notice2') })] }), _jsx(Suspense, { fallback: _jsx(LoadingSpinner, { containerHeight: "400px" }), children: _jsx(RegistrationForm, {}) })] }) }), _jsx(Footer, {})] }));
};
export default RegistrationScene;
