import i18next from 'i18next';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
const debug = Debug('helpers:documents');
export const getDocuments = (key, locale = i18next.language) => {
    debug(`Getting document ${key} for ${locale} locale`);
    return new Promise((resolve, reject) => {
        const failedFetch = (error) => {
            const reason = loggableError(error);
            debug(`Failed getting document ${key} for ${locale} locale`, reason);
            reject(reason);
        };
        const documentUrl = () => {
            if (key !== undefined) {
                return `/api/v1/materials?key=${key}&locale=${locale}`;
            }
            else {
                return `/api/v1/materials?locale=${locale}`;
            }
        };
        return axios
            .get(documentUrl())
            .then((response) => {
            resolve(response.data);
        })
            .catch(failedFetch);
    });
};
export const createDocumentPlaceholder = (key, locale = i18next.language) => {
    debug(`Creating placeholder document ${key} for ${locale} locale`);
    return new Promise((resolve, reject) => {
        const failedFetch = (error) => {
            const reason = loggableError(error);
            debug(`Failed creating placeholder document ${key} for ${locale} locale`, reason);
            reject(reason);
        };
        return axios
            .post(`/api/v1/materials`, {
            key,
            locale,
        })
            .then((response) => {
            resolve(response.data);
        })
            .catch(failedFetch);
    });
};
