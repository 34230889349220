import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { DASHBOARD_URL, JOIN_INTRO_URL, LOGIN_URL, LOW_RATING_ERROR_URL } from 'config/routes';
import { useAuthenticatedUser, useIsUserAuthenticated } from 'hooks/users';
const RequireFoodHeroRegistrationAccess = ({ children }) => {
    const { isFoodHero, hasLowRating, isUserLoaded } = useAuthenticatedUser({ shouldFetchUser: true });
    const isUserAuthenticated = useIsUserAuthenticated();
    const { isUserVerified } = useAuthenticatedUser();
    if (isUserAuthenticated === false) {
        window.location.replace(LOGIN_URL);
        return null;
    }
    if (isUserLoaded === false) {
        return null;
    }
    if (isFoodHero === true) {
        return (_jsx(Navigate, { to: DASHBOARD_URL, replace: true }));
    }
    if (hasLowRating === true) {
        return (_jsx(Navigate, { to: LOW_RATING_ERROR_URL, replace: true }));
    }
    if (isUserVerified === false) {
        return (_jsx(Navigate, { to: JOIN_INTRO_URL, replace: true }));
    }
    return children;
};
export default RequireFoodHeroRegistrationAccess;
