import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import closePauseModal from '@redux/thunks/collections/closePauseModal';
import closeSkipModal from '@redux/thunks/collections/closeSkipModal';
import closeSwapModal from '@redux/thunks/collections/closeSwapModal';
import closeUnassignModal from '@redux/thunks/collections/closeUnassignModal';
import setSelectedOptions from '@redux/thunks/collections/setSelectedOptions';
import skip from '@redux/thunks/collections/skip';
import swap from '@redux/thunks/collections/swap';
import unassign from '@redux/thunks/collections/unassign';
import { modalTrackingKeys } from 'config/modals';
import { COLLECTION_ACTIONS, MODAL_MODES, getActionModalKey, getCollectionActionDetails, } from 'helpers/collections';
import Debug from 'helpers/debug';
import { useModal, useTimeout } from 'hooks/application';
import { DEBOUNCE_TIMEOUT } from 'components/Baskets/List/Content/Article/ArticleQuantity';
import Button from 'components/common/Button';
import { ModalFooter } from 'components/common/Modal/ConfirmationModal';
const { UNASSIGN, SKIP, PAUSE, SWAP } = COLLECTION_ACTIONS;
const Footer = ({ mode }) => {
    const debug = Debug(`javascript:components:collection-${mode}:options:footer`);
    const { t } = useTranslation();
    const { closeModal } = useModal(getActionModalKey(mode));
    const [isActionButtonPressed, setIsActionButtonPressed] = useState(false);
    const dispatch = useAppDispatch();
    const collection = useAppSelector((state) => state.collections[mode]);
    const { selectedDate, selectedCollectionId, selectedIncrement, swapForCollectionId, selectedStoreId, } = collection;
    const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
        return getCollectionActionDetails(t);
    }, []);
    const isActionButtonDisabled = useMemo(() => {
        switch (mode) {
            case UNASSIGN:
                return selectedDate === undefined;
            case SKIP:
                return false;
            case PAUSE:
                return selectedDate === undefined || selectedIncrement === undefined;
            case SWAP:
                return swapForCollectionId === undefined;
            default:
                debug('Invalid collection action provided');
                break;
        }
    }, [mode, selectedDate, selectedIncrement, swapForCollectionId]);
    const handleCloseButtonClick = () => {
        switch (mode) {
            case UNASSIGN:
                closeModal();
                dispatch(closeUnassignModal());
                break;
            case SKIP:
                closeModal();
                dispatch(closeSkipModal());
                break;
            case PAUSE:
                closeModal();
                dispatch(closePauseModal());
                break;
            case SWAP:
                closeModal();
                dispatch(closeSwapModal());
                break;
            default:
                break;
        }
    };
    const handleActionClick = async () => {
        setIsActionButtonPressed(true);
        try {
            switch (mode) {
                case PAUSE:
                    dispatch(setSelectedOptions({
                        action: mode,
                        modalMode: MODAL_MODES.CONFIRMATION,
                    }));
                    break;
                case SKIP:
                    dispatch(skip(selectedCollectionId)).catch(debug);
                    break;
                case UNASSIGN:
                    dispatch(unassign(selectedCollectionId, selectedDate)).catch(debug);
                    break;
                case SWAP:
                    dispatch(swap({
                        selectedCollectionId,
                        swapForCollectionId,
                        selectedStoreId,
                    })).catch(debug);
                    break;
                default:
                    debug('Invalid collection action provided');
                    break;
            }
        }
        catch (error) {
            debug(error);
        }
    };
    useTimeout(() => {
        setIsActionButtonPressed(false);
    }, DEBOUNCE_TIMEOUT, isActionButtonPressed === true);
    return (_jsxs(ModalFooter, { children: [_jsx(Button, { "data-tracking": modalTrackingKeys.reject, onClick: handleCloseButtonClick, secondary: true, children: COLLECTION_ACTIONS_DETAILS[mode].footer.closeButton }), _jsx(Button, { "data-tracking": modalTrackingKeys.confirm, disabled: isActionButtonDisabled || isActionButtonPressed, onClick: handleActionClick, testId: `modal-${mode?.toLowerCase()}-button`, children: COLLECTION_ACTIONS_DETAILS[mode].footer.confirmButton })] }));
};
export default Footer;
