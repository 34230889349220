import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { DASHBOARD_URL, JOIN_INTRO_URL } from 'config/routes';
import { useAuthenticatedUser, useCurrentUserId } from 'hooks/users';
const LandingPageRedirects = ({ children }) => {
    const userId = useCurrentUserId();
    const { isFoodHero } = useAuthenticatedUser();
    if (userId) {
        if (isFoodHero === true) {
            return _jsx(Navigate, { to: DASHBOARD_URL });
        }
        else if (isFoodHero === false) {
            return _jsx(Navigate, { to: JOIN_INTRO_URL });
        }
        return null;
    }
    return children;
};
export default LandingPageRedirects;
