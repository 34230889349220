// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rota-module--rota--gwfrb{display:flex;flex-direction:column;gap:1.375rem}.Rota-module--wrapper--_xeZ6{background-color:#ece7ee;border-radius:.5rem;padding:.25rem .5rem}.Rota-module--wrapper--_xeZ6 .Rota-module--title--wR_AY{color:#3b1869;font-weight:600;font-size:.75rem}@media(min-width: 600px){.Rota-module--wrapper--_xeZ6 .Rota-module--title--wR_AY{font-size:.875rem}}.Rota-module--wrapper--_xeZ6 .Rota-module--keyWrapper--MDbv6{display:flex;flex-direction:row;padding-top:.5rem}.Rota-module--wrapper--_xeZ6 .Rota-module--keyWrapper--MDbv6 .Rota-module--claimed--pjI2T{padding-right:.75rem}.Rota-module--wrapper--_xeZ6 .Rota-module--keyWrapper--MDbv6 .Rota-module--key--baYjz{display:flex;color:#3b1869;font-size:.75rem;align-items:center}@media(min-width: 600px){.Rota-module--wrapper--_xeZ6 .Rota-module--keyWrapper--MDbv6 .Rota-module--key--baYjz{font-size:.875rem}}.Rota-module--wrapper--_xeZ6 .Rota-module--keyWrapper--MDbv6 .Rota-module--icon--_y2VR{color:#3b1869;margin-right:.3125rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rota": "Rota-module--rota--gwfrb",
	"wrapper": "Rota-module--wrapper--_xeZ6",
	"title": "Rota-module--title--wR_AY",
	"keyWrapper": "Rota-module--keyWrapper--MDbv6",
	"claimed": "Rota-module--claimed--pjI2T",
	"key": "Rota-module--key--baYjz",
	"icon": "Rota-module--icon--_y2VR"
};
export default ___CSS_LOADER_EXPORT___;
