import { ARTICLE_FETCH_SUCCESS } from '@redux/actionTypes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { CANCELED_ERROR, loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:articles:fetchArticle');
export default (articleId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                if (error.name === CANCELED_ERROR) {
                    debug(`Cancelled duplicate request`);
                    return;
                }
                const reason = loggableError(error);
                debug(`Failed fetching article ${articleId}`, reason);
                reject(reason);
            };
            return axios
                .get(`/api/v1/articles/${articleId}`)
                .then((response) => {
                const { data } = response;
                dispatch({
                    type: ARTICLE_FETCH_SUCCESS,
                    payload: {
                        articleId,
                        totalItems: data.items,
                        title: data.title,
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
