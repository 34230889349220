import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdInsertLink, MdPictureAsPdf, MdSlideshow, MdSubject } from 'react-icons/md';
import Debug from 'helpers/debug';
import { getDocuments } from 'helpers/documents';
import { appendUniqueIdAndReferrerToTypeformUrl } from 'helpers/typeform';
import styles from './HelpfulInformation.module.scss';
const debug = Debug('components:collections:helpful-information');
const DocumentIcon = {
    picture_as_pdf: _jsx(MdPictureAsPdf, {}),
    slideshow: _jsx(MdSlideshow, {}),
    link: _jsx(MdInsertLink, {}),
    subject: _jsx(MdSubject, {}),
};
const HelpfulInformation = () => {
    const { t } = useTranslation();
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const docs = await getDocuments();
                const enhancedDocuments = docs.map((document) => {
                    if (document.url.includes('olio1.typeform')) {
                        return {
                            ...document,
                            url: appendUniqueIdAndReferrerToTypeformUrl(document.url),
                        };
                    }
                    return document;
                });
                setDocuments(enhancedDocuments);
            }
            catch (error) {
                debug(error);
            }
        };
        fetchDocuments();
    }, []);
    const importantDocuments = documents.filter((document) => document.important === true);
    return (_jsx("div", { className: styles.root, children: _jsxs("div", { className: "card", children: [_jsx("h3", { className: styles.title, children: t('your-collections.page.index.subtitles.helpful-information') }), _jsx("ul", { className: styles.documentsList, children: importantDocuments.map((importantDoc) => {
                        return (_jsxs("li", { className: styles.listItem, children: [_jsx("span", { className: styles.icon, "data-testid": "icon", children: DocumentIcon[importantDoc.icon] }), _jsx("a", { href: importantDoc.url, className: styles.label, children: importantDoc.label })] }, importantDoc.id));
                    }) })] }) }));
};
export default HelpfulInformation;
