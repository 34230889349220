import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdEvent } from 'react-icons/md';
import { COLLECTION_TYPES } from 'config/collections';
import { calculateRelativeTimeFromNow, formatDateToDayAndTime24, formatDateToDayMonthAndTime, } from 'helpers/time';
import { useCollection } from 'hooks/collections';
const CollectionDates = ({ collectionId }) => {
    const { t } = useTranslation();
    const { data: collection } = useCollection({ collectionId });
    const { frequency: collectionFrequency, type, nextCollection: nextCollectionDate, endDate: endCollectionDate, store: { timezone: storeTimezone }, } = collection;
    const shouldDisplayNextCollection = nextCollectionDate &&
        (!endCollectionDate || Date.parse(nextCollectionDate) <= Date.parse(endCollectionDate));
    const displayCollectionFrequency = () => {
        if (type === COLLECTION_TYPES.ADHOC || type === COLLECTION_TYPES.TEMPORARY) {
            return t(`collection-slots.claim.types.${type}`);
        }
        return t(`collection-slots.frequency.${collectionFrequency.toLowerCase()}`);
    };
    return (_jsxs("div", { className: "collection-dates", children: [_jsxs("div", { className: "collection-date next-collection", children: [_jsx("label", { children: t('your-collections.page.general.collection.next_time') }), shouldDisplayNextCollection && (_jsx("strong", { className: "collection-date-relative", children: calculateRelativeTimeFromNow(nextCollectionDate) })), _jsxs("strong", { className: "next-collection", children: ["(", formatDateToDayMonthAndTime(nextCollectionDate, storeTimezone), ")"] }), _jsx(MdEvent, { className: "calendar-icon" })] }), _jsxs("div", { className: "collection-date", children: [_jsx("label", { children: t('your-collections.page.general.collection.current_time') }), _jsx("strong", { children: shouldDisplayNextCollection
                            ? formatDateToDayAndTime24(nextCollectionDate, storeTimezone)
                            : t('your-collections.page.general.collection.no_next_collection') }), _jsxs("strong", { children: [_jsx("span", { children: "(" }), displayCollectionFrequency(), _jsx("span", { children: ")" })] })] }), _jsxs("div", { className: "collection-date", children: [_jsx("label", { children: t('your-collections.page.general.collection.last_time') }), endCollectionDate && (_jsx("strong", { className: "collection-date-relative", children: calculateRelativeTimeFromNow(endCollectionDate) })), _jsx("strong", { children: endCollectionDate
                            ? `(${formatDateToDayMonthAndTime(endCollectionDate)})`
                            : t('your-collections.page.general.collection.no_end_date') })] })] }));
};
export default CollectionDates;
