import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { MdErrorOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { trackEvent } from 'services/Analytics';
import { getVerifyWithOlioLink } from 'helpers/nativeAppDeepLink';
import Button from 'components/common/Button';
import Card from 'components/common/Cards/Card';
import styles from './UnverifiedInfo.module.scss';
const UnverifiedInfo = () => {
    const { t } = useTranslation();
    const link = useMemo(() => {
        const olioWebSite = t('common.links.olio-website');
        if (isIOS === true || isAndroid === true) {
            return getVerifyWithOlioLink();
        }
        else {
            return olioWebSite;
        }
    }, [isIOS, isAndroid]);
    const handleVerifyButtonClick = () => {
        trackEvent('FWH_JOIN_INTRO_VERIFICATION');
    };
    return (_jsxs(Card, { className: styles.unverifiedCard, children: [_jsxs(Card.Heading, { className: styles.unverifiedCardHeading, children: [_jsx(MdErrorOutline, { "data-testid": "errorIcon" }), t('join_intro_page.unverified_info.title')] }), _jsxs(Card.TextSection, { children: [_jsx("p", { children: t('join_intro_page.unverified_info.description1') }), _jsx("p", { children: t('join_intro_page.unverified_info.description2') })] }), _jsx(Link, { to: link, reloadDocument: true, children: _jsx(Button, { secondary: true, onClick: handleVerifyButtonClick, children: t('join_intro_page.unverified_info.verify_with_olio') }) })] }));
};
export default UnverifiedInfo;
