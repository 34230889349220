// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Forbidden-module--modalContent--NFWbX{height:100%;display:flex;flex-direction:column}.Forbidden-module--forbiddenList--AxPte{padding:0 !important}.Forbidden-module--forbiddenList--AxPte li{list-style-type:disc !important;margin-left:1.5rem;padding-bottom:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "Forbidden-module--modalContent--NFWbX",
	"forbiddenList": "Forbidden-module--forbiddenList--AxPte"
};
export default ___CSS_LOADER_EXPORT___;
