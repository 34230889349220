import { createSelector } from '@reduxjs/toolkit';
import { selectStoreById } from '@redux/selectors/stores';
const selectBusinessById = (stores, id) => {
    return stores.businesses[id];
};
const selectAllCollections = (state) => {
    return state.collections.items;
};
const selectAvailableCollections = createSelector([selectAllCollections], (allCollections) => {
    return Object.entries(allCollections)
        .filter(([_collectionId, collection]) => collection.available === true)
        .map(([collectionId, collection]) => ({
        collectionId,
        ...collection,
    }));
});
const selectBusinessesForAvailableCollections = createSelector([(state) => state.stores, selectAvailableCollections], (stores, availableCollections) => {
    const storeIds = availableCollections.map((collection) => collection.storeId);
    const uniqueStoreIds = [...new Set(storeIds)];
    const uniqueStores = uniqueStoreIds.map((storeId) => selectStoreById(stores, storeId));
    const businessIds = uniqueStores.map((store) => store.businessId);
    const uniqueBusinessIds = [...new Set(businessIds)];
    const businesses = uniqueBusinessIds
        .map((businessId) => selectBusinessById(stores, businessId))
        .filter((business) => business !== undefined);
    return businesses;
});
export { selectAvailableCollections, selectBusinessesForAvailableCollections, selectBusinessById };
