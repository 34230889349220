import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineContactSupport } from 'react-icons/md';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
const AboutInduction = ({ handleStartQuizClick, questionCount }) => {
    const { t } = useTranslation();
    const [showStartQuizButton, setShowStartQuizButton] = useState(false);
    return (_jsxs("div", { className: "card about-induction-card", children: [_jsx("h2", { children: t('inductions.page.show.about_this_induction.title') }), _jsxs("div", { className: "about-induction-question-number", children: [_jsx(MdOutlineContactSupport, { className: "icon", "data-testid": "contact-support-icon" }), _jsx("span", { children: t('inductions.page.show.about_this_induction.question_count', {
                            count: questionCount,
                        }) })] }), _jsx("p", { children: t('inductions.page.show.about_this_induction.description') }), _jsx(Checkbox, { checked: showStartQuizButton, id: "confirm-watched-video-checkbox", label: t('inductions.page.show.about_this_induction.checkbox_label'), onChange: () => setShowStartQuizButton((value) => !value) }), showStartQuizButton === true && (_jsx(Button, { className: "about-induction-start-quiz", onClick: handleStartQuizClick, children: t('inductions.page.show.button-start') }))] }));
};
export default AboutInduction;
