import { AUTHENTICATE_USER_ERROR, AUTHENTICATE_USER_SUCCESS, USER_FETCH_SUCCESS, } from '@redux/actionTypes';
import { LOGIN_TYPES } from 'config/loginTypes';
import { DASHBOARD_URL } from 'config/routes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:users:login-magic');
const { MAGIC } = LOGIN_TYPES;
export default (loginToken) => {
    return async (dispatch) => {
        const onFail = (error) => {
            const reason = loggableError(error);
            debug(`Failed authenticating user`, reason);
            dispatch({
                type: AUTHENTICATE_USER_ERROR,
                payload: {
                    errorType: MAGIC,
                },
                meta: {
                    analytics: {
                        eventName: AUTHENTICATE_USER_ERROR,
                        eventPayload: {
                            type: MAGIC,
                        },
                    },
                },
            });
        };
        try {
            const response = await axios.post(`/api/v1/sessions/magic`, {
                token: loginToken,
            });
            const { data: { user }, } = response;
            const { id } = user;
            window.userId = Number(id);
            dispatch({
                type: AUTHENTICATE_USER_SUCCESS,
                payload: {
                    id,
                },
                meta: {
                    analytics: {
                        eventName: AUTHENTICATE_USER_SUCCESS,
                        eventPayload: {
                            type: MAGIC,
                            userId: id,
                        },
                    },
                },
            });
            dispatch({
                type: USER_FETCH_SUCCESS,
                payload: {
                    userId: id,
                    name: user.first_name,
                    email: user.email,
                    rating: user.rating,
                    location: user.location,
                    avatar: user.current_avatar,
                },
            });
            window.location.assign(DASHBOARD_URL);
        }
        catch (error) {
            onFail(error);
        }
    };
};
