// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplayedCollection-module--displayedCollection--qvJA_ .DisplayedCollection-module--cardHeader--D3MbU{margin-bottom:1.5rem}.DisplayedCollection-module--displayedCollection--qvJA_ .DisplayedCollection-module--mapImage--JTofy{margin-top:1.625rem;border-radius:.625rem}.DisplayedCollection-module--displayedCollection--qvJA_ .DisplayedCollection-module--availebleCollectionInformation--mJIsB{padding-bottom:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayedCollection": "DisplayedCollection-module--displayedCollection--qvJA_",
	"cardHeader": "DisplayedCollection-module--cardHeader--D3MbU",
	"mapImage": "DisplayedCollection-module--mapImage--JTofy",
	"availebleCollectionInformation": "DisplayedCollection-module--availebleCollectionInformation--mJIsB"
};
export default ___CSS_LOADER_EXPORT___;
