import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import MyCollectionCardList from 'components/Collections/MyCollectionCardList';
import SceneHeader from 'components/common/SceneHeader';
const MyCollectionsScene = () => {
    const { t } = useTranslation();
    return (_jsxs("div", { id: "my-collections-scene", children: [_jsx(SceneHeader, { title: t('your-collections.page.index.title') }), _jsx("div", { children: t('your-collections.page.index.description') }), _jsx(MyCollectionCardList, {})] }));
};
export default memo(MyCollectionsScene);
