// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InformationIcon-module--icon--P7rzl{width:1.5rem;height:1.5rem;color:#3b1869;background-color:#f3f1f4;border-radius:50%;padding:.25rem;margin-right:.5rem}.InformationIcon-module--icon--P7rzl.InformationIcon-module--adhoc--GeS5f{color:#ff4c70;background-color:#fdf2eb}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "InformationIcon-module--icon--P7rzl",
	"adhoc": "InformationIcon-module--adhoc--GeS5f"
};
export default ___CSS_LOADER_EXPORT___;
