import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdOutlineGroups } from 'react-icons/md';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe/SetInnerHtmlSafe';
import styles from './InnerCard.module.scss';
const InnerCard = ({ nearbyUsersCount }) => {
    const { t } = useTranslation();
    return (_jsxs("article", { className: styles.card, children: [_jsxs("div", { className: styles.headingSection, children: [_jsx("span", { className: styles.groupIcon, children: _jsx(MdOutlineGroups, {}) }), _jsx("h3", { children: t('dashboard-scene.users-near-me.inner-card.heading') })] }), _jsxs("section", { className: styles.textSection, children: [_jsx("p", { className: styles.totalHeading, children: t('dashboard-scene.users-near-me.inner-card.total-heading') }), _jsx("p", { className: styles.totalText, children: _jsx(SetInnerHtmlSafe, { children: nearbyUsersCount === 1
                                ? t('dashboard-scene.users-near-me.inner-card.total-text.text-singular', {
                                    nearbyUsers: `<strong>${nearbyUsersCount}</strong>`,
                                })
                                : t('dashboard-scene.users-near-me.inner-card.total-text.text-plural', {
                                    nearbyUsers: `<strong>${nearbyUsersCount}</strong>`,
                                }) }) })] })] }));
};
export default InnerCard;
