import Debug from 'debug'
import { isProductionLike, isTest } from 'helpers/environment'

const noOperation = () => ({
  log: () => {
    return null
  },
  debug: () => {
    return null
  },
  error: () => {
    return null
  },
  warn: () => {
    return null
  },
})

export default (name) => {
  const isJest = typeof jest !== 'undefined'
  if (isProductionLike === true || isTest === true || isJest === true) {
    return noOperation
  }

  Debug.enable('*')
  return Debug(name)
}
