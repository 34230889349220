// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollectionConfirmationInfo-module--root--MyLZh p{padding:0}.CollectionConfirmationInfo-module--root--MyLZh .CollectionConfirmationInfo-module--businessAndStoreName--FES52{margin-bottom:.5rem}.CollectionConfirmationInfo-module--root--MyLZh .CollectionConfirmationInfo-module--address--yBAw5{font-weight:600}.CollectionConfirmationInfo-module--root--MyLZh .CollectionConfirmationInfo-module--address--yBAw5>span:not(:last-of-type)::after{content:\", \"}.CollectionConfirmationInfo-module--root--MyLZh .CollectionConfirmationInfo-module--on--HbtYo{font-weight:600;margin:1rem 0}.CollectionConfirmationInfo-module--root--MyLZh .CollectionConfirmationInfo-module--collectionTime--P7Qux{font-weight:600}.CollectionConfirmationInfo-module--root--MyLZh .CollectionConfirmationInfo-module--collectionType--Ktua7{text-transform:capitalize}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CollectionConfirmationInfo-module--root--MyLZh",
	"businessAndStoreName": "CollectionConfirmationInfo-module--businessAndStoreName--FES52",
	"address": "CollectionConfirmationInfo-module--address--yBAw5",
	"on": "CollectionConfirmationInfo-module--on--HbtYo",
	"collectionTime": "CollectionConfirmationInfo-module--collectionTime--P7Qux",
	"collectionType": "CollectionConfirmationInfo-module--collectionType--Ktua7"
};
export default ___CSS_LOADER_EXPORT___;
