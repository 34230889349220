import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import closePauseModal from '@redux/thunks/collections/closePauseModal';
import closeSkipModal from '@redux/thunks/collections/closeSkipModal';
import closeSwapModal from '@redux/thunks/collections/closeSwapModal';
import closeUnassignModal from '@redux/thunks/collections/closeUnassignModal';
import fetchStoreCollections from '@redux/thunks/collections/fetchStoreCollections';
import { COLLECTION_ACTIONS, getActionModalKey, getCollectionActionDetails, getCollectionTypeTranslation, } from 'helpers/collections';
import Debug from 'helpers/debug';
import { formatDateToDayAndTime24 } from 'helpers/time';
import { useModal } from 'hooks/application';
import SuccessModal from 'components/common/Modal/SuccessModal';
const { PAUSE, SKIP, UNASSIGN, SWAP } = COLLECTION_ACTIONS;
const REDIRECT_URL = '/my-collections';
const debug = Debug('components:collections:success');
const Success = ({ mode }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const modalKey = getActionModalKey(mode);
    const { closeModal } = useModal(modalKey);
    const COLLECTION_ACTIONS_DETAILS = useMemo(() => {
        return getCollectionActionDetails(t);
    }, []);
    const storeId = useAppSelector((state) => state?.collections[mode].selectedStoreId);
    const store = useAppSelector((state) => state?.stores.items[storeId]);
    const timezone = store.timezone;
    const storeName = store?.name;
    const businessId = store?.businessId;
    const businessName = useAppSelector((state) => state?.stores.businesses[businessId]?.name);
    const selectedCollectionId = useAppSelector((state) => state?.collections[mode].selectedCollectionId);
    const collection = useAppSelector((state) => state?.collections.items[selectedCollectionId]);
    const selectedCollectionDate = collection?.nextCollection;
    const type = collection?.type;
    const collectionType = getCollectionTypeTranslation({ t, type });
    const collectionDate = formatDateToDayAndTime24(selectedCollectionDate, timezone);
    const swapForCollectionId = useAppSelector((state) => state.collections.swap?.swapForCollectionId);
    const swapForCollection = useAppSelector((state) => state.collections.items[swapForCollectionId]);
    const swapForCollectionDate = formatDateToDayAndTime24(swapForCollection?.nextCollection, timezone);
    const newCollectionId = useAppSelector((state) => state.collections.swap?.newCollectionId);
    useEffect(() => {
        if (newCollectionId !== undefined) {
            dispatch(fetchStoreCollections({ storeId })).catch(debug);
        }
    }, [newCollectionId]);
    const handleClose = () => {
        switch (mode) {
            case UNASSIGN:
                closeModal();
                dispatch(closeUnassignModal());
                window.location.assign(REDIRECT_URL);
                break;
            case SKIP:
                closeModal();
                dispatch(closeSkipModal());
                window.location.assign(REDIRECT_URL);
                break;
            case PAUSE:
                closeModal();
                dispatch(closePauseModal());
                window.location.assign(REDIRECT_URL);
                break;
            case SWAP:
                closeModal();
                dispatch(closeSwapModal());
                window.location.assign(`${REDIRECT_URL}/${newCollectionId}`);
                break;
            default:
                break;
        }
    };
    const getSlotText = () => {
        switch (mode) {
            case UNASSIGN:
                return t('your-collections.page.show.end-modal.choices-modal.slot', {
                    type: collectionType,
                    date: collectionDate,
                    split: '\n',
                });
            case SKIP:
                return t('your-collections.page.show.skip-modal.choices-modal.slot', {
                    type: collectionType,
                    date: collectionDate,
                    split: '\n',
                });
            case PAUSE:
                return t('your-collections.page.show.pause-modal.choices-modal.slot', {
                    type: collectionType,
                    date: collectionDate,
                    split: '\n',
                });
            default:
                return undefined;
        }
    };
    return (_jsxs(SuccessModal, { onClose: handleClose, modalKey: modalKey, children: [_jsx("h2", { children: COLLECTION_ACTIONS_DETAILS[mode].successHeader }), mode !== PAUSE ? (_jsxs(_Fragment, { children: [mode !== SWAP && _jsx("p", { children: getSlotText() }), mode === SWAP && (_jsxs(_Fragment, { children: [_jsx("p", { children: t('your-collections.page.show.swap-modal.success-modal.swapped-collection', {
                                    date: collectionDate,
                                }) }), _jsx("p", { children: t('your-collections.page.show.swap-modal.success-modal.swap-for-collection', {
                                    date: swapForCollectionDate,
                                }) })] })), _jsx("p", { children: COLLECTION_ACTIONS_DETAILS[mode].atText }), _jsxs("p", { children: [businessName, _jsx("br", {}), storeName] })] })) : (COLLECTION_ACTIONS_DETAILS[mode].description), mode === SKIP && (_jsxs(_Fragment, { children: [_jsx("p", { children: t('your-collections.page.show.skip-modal.success-modal.description_1') }), _jsx("p", { children: t('your-collections.page.show.skip-modal.success-modal.description_2') })] }))] }));
};
export { REDIRECT_URL };
export default Success;
