import { combineReducers } from 'redux';
import ApplicationReducer from '@redux/reducers/Application/';
import ArticlesReducer from '@redux/reducers/Articles/';
import BasketsReducer from '@redux/reducers/Baskets/';
import CollectionsReducer from '@redux/reducers/Collections/';
import DocumentsReducer from '@redux/reducers/Documents/';
import InductionsReducer from '@redux/reducers/Inductions/';
import PreferencesReducer from '@redux/reducers/Preferences';
import StoresReducer from '@redux/reducers/Stores/';
import UsersReducer from '@redux/reducers/Users/';
const rootReducer = combineReducers({
    application: ApplicationReducer,
    articles: ArticlesReducer,
    baskets: BasketsReducer,
    collections: CollectionsReducer,
    inductions: InductionsReducer,
    stores: StoresReducer,
    users: UsersReducer,
    documents: DocumentsReducer,
    preferences: PreferencesReducer,
});
export default rootReducer;
