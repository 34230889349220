import { ACCOUNT_BASKET_IDS_FETCH_SUCCESS, BASKET_ARTICLE_FETCH_SUCCESS, BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS, BASKET_CLOSED_SUCCESS, BASKET_FETCH_SUCCESS, } from '@redux/actionTypes';
import initialState from '@redux/reducers/Baskets/initialState';
const ACTION_HANDLERS = {
    [BASKET_CLOSED_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { basketId } = payload;
        const newUserBaskets = state.userBaskets.filter((id) => id !== basketId);
        return {
            ...state,
            userBaskets: newUserBaskets,
        };
    },
    [BASKET_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { basketId, basketArticleIds, requesterId } = payload;
        return {
            ...state,
            items: {
                ...state.items,
                [basketId]: {
                    requesterId,
                    basketArticleIds,
                },
            },
        };
    },
    [BASKET_ARTICLE_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { basketArticleId, articleId, quantity } = payload;
        return {
            ...state,
            articles: {
                ...(state.articles || {}),
                [basketArticleId]: {
                    quantity,
                    articleId,
                },
            },
        };
    },
    [BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { basketArticleId, quantity } = payload;
        return {
            ...state,
            articles: {
                ...state.articles,
                [basketArticleId]: {
                    ...state.articles[basketArticleId],
                    quantity,
                },
            },
        };
    },
    [ACCOUNT_BASKET_IDS_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { baskets } = payload;
        const basketIds = baskets.map((basket) => basket.id);
        const newItems = {};
        baskets.forEach((basket) => {
            newItems[basket.id] = {
                ...state.items[basket.id],
                requesterId: basket.requester_id,
            };
        });
        return {
            ...state,
            userBaskets: basketIds,
            items: {
                ...state.items,
                ...newItems,
            },
        };
    },
};
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
