import { useCallback, useReducer } from 'react';
const actionTypes = {
    NEXT_STEP: 'NEXT_STEP',
    PREVIOUS_STEP: 'PREVIOUS_STEP',
    GO_TO_STEP: 'GO_TO_STEP',
};
const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.NEXT_STEP:
            return {
                step: state.step + 1,
            };
        case actionTypes.PREVIOUS_STEP:
            if (state.step === 0) {
                return {
                    step: 0,
                };
            }
            return {
                step: state.step - 1,
            };
        case actionTypes.GO_TO_STEP:
            return {
                step: action.step,
            };
    }
};
const useWizard = () => {
    const [state, dispatch] = useReducer(reducer, { step: 0 });
    const nextStep = useCallback(() => {
        dispatch({ type: actionTypes.NEXT_STEP });
    }, []);
    const previousStep = useCallback(() => {
        dispatch({ type: actionTypes.PREVIOUS_STEP });
    }, []);
    const goToStep = useCallback((step) => {
        dispatch({
            type: actionTypes.GO_TO_STEP,
            step,
        });
    }, []);
    return {
        step: state.step,
        wizard: {
            nextStep,
            previousStep,
            goToStep,
        },
    };
};
export default useWizard;
