import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdLogin, MdLogout, MdMenu } from 'react-icons/md';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useAppDispatch } from '@redux/hooks';
import toggleSidebar from '@redux/thunks/application/toggleSidebar';
import logout from '@redux/thunks/users/logout';
import { trackEvent } from 'services/Analytics';
import { nonFoodHeroRoutes } from 'helpers/navigation';
import { useIsMobile } from 'hooks/application';
import { useIsUserAuthenticated } from 'hooks/users';
import LogoImage from 'images/olio-logo-header.svg';
import styles from './Navigation.module.scss';
const Navigation = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const isMobile = useIsMobile();
    const isUserAuthenticated = useIsUserAuthenticated();
    const nonFoodHeroMatchedRoutes = matchRoutes(nonFoodHeroRoutes, location);
    const dispayBurgerMenu = nonFoodHeroMatchedRoutes === null || nonFoodHeroMatchedRoutes.length === 0;
    const handleToggle = () => {
        dispatch(toggleSidebar());
    };
    const handleLogout = () => {
        const eventName = `NAVIGATE_LOGOUT`;
        trackEvent(eventName, {
            origin: 'top-nav',
        });
        dispatch(logout());
    };
    return (_jsx("nav", { children: _jsxs("div", { className: styles.navWrapper, children: [isMobile === true && dispayBurgerMenu === true && (_jsx("button", { type: "button", className: clsx('sidebar-toggle cursor-pointer no-button-styling', styles.navButton), onClick: handleToggle, onKeyPress: handleToggle, "aria-label": t('layouts.authenticated.sidebar_toggle'), tabIndex: 0, children: _jsx("span", { children: _jsx(MdMenu, { "aria-hidden": "true" }) }) })), _jsx("div", { className: styles.logoContainer, children: _jsxs("a", { href: t('layouts.join-default.header-link'), className: styles.brandLogo, children: [_jsx("img", { alt: "", src: LogoImage }), isMobile === false && _jsx("h2", { children: t('layouts.join-default.header') })] }) }), isMobile === false && (_jsxs("ul", { children: [_jsx("li", { children: _jsx("a", { className: styles.website, href: t('external-urls.olio-website'), children: t('phrases.olio-website') }) }), isUserAuthenticated === false && (_jsx("li", { children: _jsx(Link, { to: "/login", className: styles.authenticationWrapper, children: _jsxs("div", { className: styles.authentication, children: [_jsx(MdLogin, {}), _jsx("span", { className: styles.authenticationText, children: t('phrases.login') })] }) }) })), isUserAuthenticated === true && (_jsx(_Fragment, { children: _jsx("li", { children: _jsx("button", { className: clsx('no-button-styling', styles.authenticationWrapper), type: "button", onClick: handleLogout, children: _jsxs("div", { className: styles.authentication, children: [_jsx(MdLogout, {}), _jsx("span", { className: styles.authenticationText, children: t('phrases.logout') })] }) }) }) }))] }))] }) }));
};
export default Navigation;
