import { USER_FETCH_SUCCESS } from '@redux/actionTypes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { CANCELED_ERROR, loggableError } from 'helpers/errors';
const debug = Debug('redux:thunks:users:fetchUser');
export default (id) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                if (error.name === CANCELED_ERROR) {
                    debug(`Cancelled duplicate request`);
                    return;
                }
                const reason = loggableError(error);
                debug(`Failed fetching user ${id}`, reason);
                reject(error);
            };
            return axios
                .get(`/api/v1/users/${id}`)
                .then((response) => {
                const { data } = response;
                dispatch({
                    type: USER_FETCH_SUCCESS,
                    payload: {
                        userId: id,
                        name: data.first_name,
                        avatar: data.current_avatar,
                        rating: data.rating,
                        location: data.location,
                        volunteerActivation: data.volunteer_activation,
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
