import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import styles from './Card.module.scss';
const Card = ({ Element = 'article', className, children }) => {
    return (_jsx(Element, { className: clsx(className, styles.card), children: _jsx("div", { className: clsx(styles.content), children: children }) }));
};
const CardHeading = ({ className, children }) => (_jsx("h3", { className: className, children: children }));
const CardImage = ({ className, children }) => (_jsx("div", { className: clsx(className, styles.imageWrapper), children: _jsx("img", { alt: "", src: children }) }));
const CardTextSection = ({ className, children }) => (_jsx("div", { className: clsx(className, styles.textSection), children: children }));
Card.Heading = CardHeading;
Card.Image = CardImage;
Card.TextSection = CardTextSection;
export default Card;
