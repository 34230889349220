import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useAppSelector } from '@redux/hooks';
import { trackEvent } from 'services/Analytics';
import { formatDateToTime24, formatShortDateAndTime24 } from 'helpers/time';
import { getReportAnIssueUrl } from 'helpers/typeform';
import { getCurrentUserId } from 'helpers/user';
import CollectionDates from 'components/Collections/CollectionDates';
import UnlimitedSlotLabel from 'components/Collections/UnlimitedSlotLabel';
import Button from 'components/common/Button';
import StoreInfo from 'components/common/StoreInfo';
import styles from './MyCollectionCard.module.scss';
const COLLECTIONS_PATH = '/my-collections';
const MyCollectionCard = ({ collectionId }) => {
    const { t } = useTranslation();
    const { type: collectionType, frequency: collectionFrequency, storeId, nextCollection: nextCollectionDate, derestricted: isDerestrictedCollection, } = useAppSelector((state) => state.collections.items[collectionId] ?? {});
    const { name: storeName, businessId, location: storeLocation, timezone, } = useAppSelector((state) => state.stores.items[storeId] ?? {});
    const { name: businessName, logo: businessLogo } = useAppSelector((state) => state.stores.businesses[businessId] ?? {});
    const { url: businessLogoUrl } = businessLogo ?? {};
    const trackReportAnIssueClickEvent = () => {
        trackEvent('COLLECTION_SOMETHING_WRONG', {
            business: businessName,
            type: collectionType,
            location: storeName,
            frequency: collectionFrequency,
            nextAvailableSlotTime: formatDateToTime24(nextCollectionDate, timezone),
            nextAvailableSlotDate: formatShortDateAndTime24(nextCollectionDate, timezone),
        });
    };
    const reportAnIssueUrl = useMemo(() => {
        return getReportAnIssueUrl({
            t,
            storeName,
            businessName,
            storeId,
            businessId,
            userId: getCurrentUserId(),
        });
    }, [storeName, businessName, storeId, businessId]);
    return (_jsx("div", { className: clsx('card', styles.root), children: _jsxs("div", { className: "card-content", children: [isDerestrictedCollection && _jsx(UnlimitedSlotLabel, {}), _jsxs("div", { className: styles.content, children: [_jsx("div", { className: styles.collectionInfo, children: storeId && (_jsxs(_Fragment, { children: [_jsx(StoreInfo, { logoUrl: businessLogoUrl, location: storeLocation, storeId: storeId }), _jsx(CollectionDates, { collectionId: collectionId })] })) }), _jsxs("div", { className: styles.buttons, children: [_jsx(Button, { href: `${COLLECTIONS_PATH}/${collectionId}`, children: t('your-collections.page.index.buttons.view-details') }), _jsx(Button, { href: reportAnIssueUrl, onClick: trackReportAnIssueClickEvent, secondary: true, children: t('your_collections.collection.something_wrong_text') })] })] })] }) }));
};
export default memo(MyCollectionCard);
