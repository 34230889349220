import { jsx as _jsx } from "react/jsx-runtime";
import InductionQuizFailure from 'components/Inductions/InductionQuiz/QuizFailure';
import InductionQuizSuccess from 'components/Inductions/InductionQuiz/QuizSuccess';
import LoadingSpinner from 'components/common/LoadingSpinner';
const QuizResults = ({ questions, selectedAnswers, hasVideo, inductionTitle, playYoutubeVideo, quizCompletedSuccessfully, areAllAnswersCorrect, retryQuiz, }) => {
    if (areAllAnswersCorrect === true && quizCompletedSuccessfully === false) {
        return _jsx(LoadingSpinner, { containerHeight: "400px" });
    }
    else if (quizCompletedSuccessfully === true) {
        return (_jsx(InductionQuizSuccess, { questionCount: questions.length, inductionTitle: inductionTitle }));
    }
    else {
        return (_jsx(InductionQuizFailure, { questions: questions, selectedAnswers: selectedAnswers, hasVideo: hasVideo, playYoutubeVideo: playYoutubeVideo, retryQuiz: retryQuiz }));
    }
};
export default QuizResults;
