// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoCollectionsNearMe-module--noCollectionsNearMe--LvmKG .NoCollectionsNearMe-module--subheading--pPYpL{font-weight:600;color:#252525}.NoCollectionsNearMe-module--noCollectionsNearMe--LvmKG .NoCollectionsNearMe-module--textSection--t4Z1o{padding-bottom:0}.NoCollectionsNearMe-module--noCollectionsNearMe--LvmKG .NoCollectionsNearMe-module--becomeAnAmbassadorButton--k9K4o{margin-top:1.5rem;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noCollectionsNearMe": "NoCollectionsNearMe-module--noCollectionsNearMe--LvmKG",
	"subheading": "NoCollectionsNearMe-module--subheading--pPYpL",
	"textSection": "NoCollectionsNearMe-module--textSection--t4Z1o",
	"becomeAnAmbassadorButton": "NoCollectionsNearMe-module--becomeAnAmbassadorButton--k9K4o"
};
export default ___CSS_LOADER_EXPORT___;
