// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProgressBullets-module--progressBullets--i11fA{display:flex;justify-content:center;margin-top:.5rem}.ProgressBullets-module--progressBullets--i11fA .ProgressBullets-module--bullet--Uig_6{margin:.25rem;width:.5rem;height:.5rem;border-radius:50%;background-color:#d9d9d9}.ProgressBullets-module--progressBullets--i11fA .ProgressBullets-module--bullet--Uig_6.ProgressBullets-module--current--Nq_E8{background-color:#474747;transition:500ms}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBullets": "ProgressBullets-module--progressBullets--i11fA",
	"bullet": "ProgressBullets-module--bullet--Uig_6",
	"current": "ProgressBullets-module--current--Nq_E8"
};
export default ___CSS_LOADER_EXPORT___;
