import { USER_ACTIVITY_FETCH_SUCCESS } from '@redux/actionTypes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { CANCELED_ERROR, loggableError } from 'helpers/errors';
import { getCurrentUserId } from 'helpers/user';
const debug = Debug('redux:thunks:users:fetch-my-activity');
export default () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                if (error.name === CANCELED_ERROR) {
                    debug(`Cancelled duplicate request`);
                    return;
                }
                const reason = loggableError(error);
                debug('Failed fetching user activity', reason);
                reject(reason);
            };
            return axios
                .get(`/api/v1/users/${getCurrentUserId()}/my_activity`)
                .then((response) => {
                const { data } = response;
                const { total_articles, total_collections, articles_last_30_days } = data;
                dispatch({
                    type: USER_ACTIVITY_FETCH_SUCCESS,
                    payload: {
                        totalArticles: total_articles,
                        totalCollections: total_collections,
                        articlesLast30Days: articles_last_30_days,
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
