// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Badge-module--badge--xOR0t{display:flex;flex-direction:row}.Badge-module--badge--xOR0t.Badge-module--success--L2F1J{color:#00a69c}.Badge-module--badge--xOR0t.Badge-module--error--ePSAV{color:#bb4291}.Badge-module--badge--xOR0t svg{margin-right:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "Badge-module--badge--xOR0t",
	"success": "Badge-module--success--L2F1J",
	"error": "Badge-module--error--ePSAV"
};
export default ___CSS_LOADER_EXPORT___;
