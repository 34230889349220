import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAccordion } from 'hooks/application';
const Accordion = ({ sections, autoExpandedSections, Header, Content, Footer, ItemWrapper = 'div', AccordionWrapper = 'div', expandMultiple = false, }) => {
    const { handleMouseDown, handleExpandClick, handleExpandKeyDown, isExpanded } = useAccordion({
        autoExpandedSections,
        expandMultiple,
        isClickableHeader: true,
    });
    return (_jsx(AccordionWrapper, { children: sections.map((section) => (_jsxs(ItemWrapper, { id: section.id, children: [_jsx("button", { type: "button", className: "cursor-pointer no-button-styling full-width-button button-focus", "data-testid": "headerWrapper", onMouseDown: handleMouseDown, onClick: (event) => handleExpandClick(event, section.id), onKeyDown: (event) => handleExpandKeyDown(event, section.id), tabIndex: 0, children: _jsx(Header, { section: section, active: isExpanded(section.id) }) }), isExpanded(section.id) === true && _jsx(Content, { section: section }), _jsx(Footer, { basketId: section.id })] }, section.id))) }));
};
export default Accordion;
