import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { MdOutlineLock } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { trackEvent } from 'services/Analytics';
import { REGISTER_URL } from 'config/routes';
import Button from 'components/common/Button';
import styles from './RegisteringIsEasy.module.scss';
const RegisteringIsEasy = ({ isUserVerified }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.registeringIsEasy, children: [_jsx("h3", { children: t('join-intro-page.registering-is-easy.header') }), isUserVerified === true && (_jsxs(_Fragment, { children: [_jsx("p", { children: t('join-intro-page.registering-is-easy.verified-description') }), _jsx(Link, { to: REGISTER_URL, children: _jsx(Button, { onClick: () => trackEvent('FWH_NAVIGATE_COLLECTIONS_TEASER_PROCEED'), children: t('join_intro_page.become_food_waste_volunteer') }) })] })), isUserVerified === false && (_jsxs(_Fragment, { children: [_jsx("p", { children: t('join-intro-page.registering-is-easy.unverified-description') }), _jsx(Button, { leftContent: _jsx(MdOutlineLock, { "data-testid": "lockIcon" }), children: t('join_intro_page.become_food_waste_volunteer') })] }))] }));
};
export default RegisteringIsEasy;
