import { createSelector } from '@reduxjs/toolkit';
import { appendUniqueIdAndReferrerToTypeformUrl } from 'helpers/typeform';
const selectDocuments = (state) => {
    return state.documents.items;
};
const selectAllDocuments = createSelector([selectDocuments], (allDocuments) => {
    return Object.entries(allDocuments).map(([documentId, document]) => {
        let url = document.url;
        if (url.includes('olio1.typeform')) {
            url = appendUniqueIdAndReferrerToTypeformUrl(document.url);
        }
        return {
            ...document,
            id: Number(documentId),
            url,
        };
    });
});
export { selectAllDocuments };
