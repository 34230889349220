import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useInView } from 'framer-motion';
import { useAppSelector } from '@redux/hooks';
import { AVAILABLE_COLLECTIONS_URL } from 'config/routes';
import { getMapUrl, getSignedStaticMapUrl } from 'helpers/map';
import { formatDateToTime24 } from 'helpers/time';
import { useCollection } from 'hooks/collections';
import Late_Badge from 'images/Late_Badge.svg';
import AvailableCollectionInformation from 'components/AvailableCollections/AvailableCollectionInformation';
import UnlimitedSlotLabel from 'components/Collections/UnlimitedSlotLabel';
import Alert from 'components/common/Alert';
import Button from 'components/common/Button';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import { Spacer } from 'components/layout/primitives';
import styles from './AvailableCollectionsListCard.module.scss';
const AvailableCollectionsListCard = ({ collectionId, type, storeName, businessName, storeAddress, storeLocation, nextCollection, collectionNotes, storeLocation: { latitude, longitude }, storeTimezone, donationConfirmedAt, }) => {
    const { t } = useTranslation();
    const [mapUrl, setMapUrl] = useState();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const animation = useAnimation();
    const { data: collection } = useCollection({
        collectionId,
        dataToInclude: { inductions: true },
    });
    const homeLocation = useAppSelector((state) => state.users.currentUser?.location, shallowEqual);
    const mapLinkUrl = getMapUrl(storeLocation);
    const collectionTime = formatDateToTime24(nextCollection, storeTimezone);
    useLayoutEffect(() => {
        if (isInView === true) {
            animation.start({
                opacity: 1,
                y: 0,
                transition: {
                    type: 'spring',
                    duration: 0.4,
                    bounce: 0.15,
                },
            });
        }
    }, [animation, isInView]);
    useEffect(() => {
        const getMapUrl = async () => {
            const mapUrl = await getSignedStaticMapUrl({ latitude, longitude, width: 520, height: 157 });
            setMapUrl(mapUrl);
        };
        if (isInView === true) {
            getMapUrl();
        }
    }, [isInView]);
    return (_jsx(motion.div, { className: `available-collections-list-card ${type} card`, initial: { opacity: 0, y: 50 }, exit: { opacity: 0 }, animate: animation, ref: ref, children: _jsxs("div", { className: "card-content", children: [_jsxs("div", { children: [collection?.derestricted === true && (_jsxs(_Fragment, { children: [_jsx(UnlimitedSlotLabel, {}), _jsx(Spacer, { x: "1.25rem" })] })), _jsx("h3", { className: "store-name", children: `${businessName} |` }), _jsx("h3", { className: "business-name", children: storeName }), _jsx(AvailableCollectionInformation, { homeLocation: homeLocation, storeLocation: storeLocation, storeAddress: storeAddress, nextCollection: nextCollection, type: type, storeTimezone: storeTimezone, businessName: businessName, donationConfirmedAt: donationConfirmedAt }), collectionTime >= '22:00' && (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.lateBadge, children: [_jsx("img", { src: Late_Badge, alt: "" }), _jsx("span", { className: styles.label, children: t('collections.available.late-collection') })] }), _jsx(Spacer, { x: "1.25rem" })] })), collectionNotes !== undefined && (_jsx("div", { className: "alert-info-message", children: _jsx(Alert, { variant: "info", children: _jsx(SetInnerHtmlSafe, { children: collectionNotes }) }) }))] }), _jsxs("div", { children: [_jsx("div", { className: "bottom-map hide-on-small-only", children: _jsx("a", { href: mapLinkUrl, target: "_blank", rel: "noreferrer", children: _jsx("img", { alt: "", src: mapUrl, "data-testid": "map-image", loading: "lazy" }) }) }), _jsx(Link, { to: `${AVAILABLE_COLLECTIONS_URL}/${collectionId}`, children: _jsx(Button, { testId: `view-details-button-${type}`, buttonSize: "fullWidthButton", children: t('collection_slots.partials.slot.details-button') }) })] })] }) }));
};
export default AvailableCollectionsListCard;
