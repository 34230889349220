import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdOutlineArrowForward } from 'react-icons/md';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences';
import updateUserPreferences from '@redux/thunks/preferences/updateUserPreferences';
import { ERROR, STATUS } from 'config/alert';
import { FETCHING_STATUSES } from 'config/api';
import Debug from 'helpers/debug';
import { filterNotificationPreferences, getCardIcon, getCardIconClass, getCardTitles, getOptions, } from 'helpers/notificationPreferences';
import { useIsMobile } from 'hooks/application';
import Alert from 'components/common/Alert/Alert';
import Button from 'components/common/Button';
import LoadingSpinner from 'components/common/LoadingSpinner';
import RadioButtonGroup from 'components/common/RadioButtonGroup';
import SceneHeader from 'components/common/SceneHeader';
import { Grid, Stack } from 'components/layout/primitives';
import styles from './NotificationPreferencesScene.module.scss';
const { LOADING, IDLE, SUCCESS, FAILED } = FETCHING_STATUSES;
const debug = Debug('scenes:notification-preferences-scene');
const NotificationPreferencesScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const methods = useForm({ mode: 'onChange' });
    const { getValues } = methods;
    const { userPreferences, preferencesFetchingStatus, preferencesUpdatingStatus } = useAppSelector((state) => state.preferences);
    const alertShown = preferencesUpdatingStatus === SUCCESS || preferencesUpdatingStatus === FAILED;
    const cardTitles = useMemo(() => {
        return getCardTitles(t);
    }, []);
    const options = useMemo(() => {
        return getOptions(t);
    }, []);
    const onSubmit = async () => {
        const preferences = getValues();
        if (preferences !== undefined) {
            dispatch(updateUserPreferences(preferences)).catch(debug);
        }
    };
    const notificationPreferences = useMemo(() => {
        const userNotificationPreferences = filterNotificationPreferences(userPreferences);
        return Object.keys(cardTitles).map((notificationType) => {
            const userPreference = userNotificationPreferences.find((preference) => preference.name === notificationType);
            if (userPreference !== undefined) {
                return userPreference;
            }
            return { name: notificationType };
        });
    }, [userPreferences]);
    useEffect(() => {
        dispatch(fetchUserPreferences()).catch(debug);
    }, []);
    if (preferencesFetchingStatus === LOADING || preferencesFetchingStatus === IDLE) {
        return _jsx(LoadingSpinner, { containerHeight: "400px" });
    }
    return (_jsxs("div", { className: styles.notificationPreferencesScene, children: [_jsx("div", { className: styles.header, children: _jsx(SceneHeader, { title: t('notification-settings.title'), description: t('notification-settings.description') }) }), _jsxs(FormProvider, { ...methods, children: [_jsx(Stack, { children: _jsx(Grid, { columns: isMobile === true ? 1 : 3, gap: "1rem", children: notificationPreferences.map(({ name, value }) => {
                                return (_jsx("div", { className: clsx(styles.card, 'card'), children: _jsxs("div", { className: clsx(styles.cardContent, 'card-content'), children: [_jsxs("div", { className: styles.cardHeader, children: [_jsx("span", { className: clsx(styles.icon, styles[getCardIconClass(name)]), children: getCardIcon(name)({}) }), _jsx("h3", { className: styles.title, children: cardTitles[name] })] }), _jsx("div", { className: styles.radioButtonWrapper, children: _jsx(RadioButtonGroup, { name: name, selectedValue: value, options: options }) })] }) }, name));
                            }) }) }), _jsxs("div", { className: styles.settingsButtonWrapper, children: [_jsxs("div", { className: clsx(styles.alertWrapper, {
                                    [styles.alertVisible]: alertShown === true,
                                }), children: [preferencesUpdatingStatus === SUCCESS && (_jsxs(Alert, { variant: STATUS, children: [_jsxs("strong", { children: [t('common.alerts.status'), " "] }), t('notification-settings.update.success')] })), preferencesUpdatingStatus === FAILED && (_jsxs(Alert, { variant: ERROR, children: [_jsxs("strong", { children: [t('common.alerts.error'), " "] }), t('notification-settings.update.error')] }))] }), _jsx(Button, { className: styles.saveButton, onClick: onSubmit, children: t('notification-settings.update.button') }), _jsxs("a", { href: t('notification-settings.info-link.url'), className: styles.slotTypesLink, target: "_blank", rel: "noreferrer", children: [t('notification-settings.info-link.text'), _jsx(MdOutlineArrowForward, {})] })] })] })] }));
};
export default NotificationPreferencesScene;
