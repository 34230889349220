import Airship from 'services/Analytics/Airship'
import Facebook from 'services/Analytics/Facebook'
import Mixpanel from 'services/Analytics/Mixpanel'
import { getPayloadEnhancement } from 'services/Analytics/config'

const enhancePayload = (payload) => ({
  ...payload,
  ...getPayloadEnhancement(),
})

const trackEvent = (eventName, eventPayload, options) => {
  const enhancedPayload = enhancePayload(eventPayload)

  Airship.track(eventName, enhancedPayload)

  const normalisePromises = (p) =>
    p
      .then((value) => ({ status: 'fulfilled', value }))
      .catch((reason) => ({ status: 'rejected', reason }))

  const trackedEvents = [Mixpanel.track(eventName, enhancedPayload, options)].map(normalisePromises)
  return Promise.all(trackedEvents)
}

const trackAdEvent = (eventName, eventPayload) => {
  const enhancedPayload = enhancePayload(eventPayload)

  Facebook.track(eventName, enhancedPayload)
}

export { trackEvent, trackAdEvent }
