// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivityItem-module--container--qQjl9{display:flex}.ActivityItem-module--container--qQjl9 span{display:block}.ActivityItem-module--container--qQjl9 .ActivityItem-module--icon--XkhKM{margin-right:.5rem;width:1.5rem;height:1.5rem;display:flex;align-items:center;justify-content:center;border-radius:50%;color:#bb4291;background-color:#f8ecf4}.ActivityItem-module--container--qQjl9 .ActivityItem-module--icon--XkhKM svg{width:1.25rem;height:1.25rem}@media(min-width: 600px){.ActivityItem-module--container--qQjl9 .ActivityItem-module--icon--XkhKM{width:2rem;height:2rem}.ActivityItem-module--container--qQjl9 .ActivityItem-module--icon--XkhKM svg{width:1.5rem;height:1.5rem}}.ActivityItem-module--container--qQjl9 .ActivityItem-module--name--K7CDV{color:#bb4291;padding-top:.125rem;font-weight:700;font-size:.875rem}@media(min-width: 600px){.ActivityItem-module--container--qQjl9 .ActivityItem-module--name--K7CDV{font-size:1rem;padding-top:.25rem}}.ActivityItem-module--container--qQjl9 .ActivityItem-module--value--eaZlK{font-size:1.875rem;font-weight:700;padding-top:.25rem}@media(min-width: 600px){.ActivityItem-module--container--qQjl9 .ActivityItem-module--value--eaZlK{font-size:2rem}}.ActivityItem-module--container--qQjl9 .ActivityItem-module--description--S5Qqy{color:#767676;padding-bottom:0;font-size:.625rem}@media(min-width: 600px){.ActivityItem-module--container--qQjl9 .ActivityItem-module--description--S5Qqy{font-size:.875rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ActivityItem-module--container--qQjl9",
	"icon": "ActivityItem-module--icon--XkhKM",
	"name": "ActivityItem-module--name--K7CDV",
	"value": "ActivityItem-module--value--eaZlK",
	"description": "ActivityItem-module--description--S5Qqy"
};
export default ___CSS_LOADER_EXPORT___;
