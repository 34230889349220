// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnverifiedInfo-module--unverifiedCard--Iqzda{font-size:.875rem;margin-bottom:2rem}@media only screen and (min-width: 600px){.UnverifiedInfo-module--unverifiedCard--Iqzda{font-size:1rem}}.UnverifiedInfo-module--unverifiedCard--Iqzda .UnverifiedInfo-module--unverifiedCardHeading--_J9yO{display:flex}.UnverifiedInfo-module--unverifiedCard--Iqzda svg{color:#fd8c08;margin-right:.375rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unverifiedCard": "UnverifiedInfo-module--unverifiedCard--Iqzda",
	"unverifiedCardHeading": "UnverifiedInfo-module--unverifiedCardHeading--_J9yO"
};
export default ___CSS_LOADER_EXPORT___;
