// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClaimCollection-module--inlineActions--C9jYx{display:grid;gap:21px}@media(min-height: 1200px){.ClaimCollection-module--inlineActions--C9jYx{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:1rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineActions": "ClaimCollection-module--inlineActions--C9jYx"
};
export default ___CSS_LOADER_EXPORT___;
