import { STORE_FETCH_START, STORE_FETCH_SUCCESS } from '@redux/actionTypes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { CANCELED_ERROR, loggableError } from 'helpers/errors';
import { sanitiseAddress } from 'helpers/stores';
const debug = Debug('redux:thunks:stores:fetchStore');
export default (storeId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const failedFetch = (error) => {
                if (error.name === CANCELED_ERROR) {
                    debug(`Cancelled duplicate request`);
                    return;
                }
                const reason = loggableError(error);
                debug(`Failed fetching store ${storeId}`, reason);
                reject(reason);
            };
            dispatch({
                type: STORE_FETCH_START,
            });
            return axios
                .get(`/api/v1/stores/${storeId}`)
                .then((response) => {
                const { data } = response;
                dispatch({
                    type: STORE_FETCH_SUCCESS,
                    payload: {
                        businessId: data.business.id,
                        businessName: data.business.name,
                        name: data.name,
                        address: sanitiseAddress(data.address),
                        storeId,
                        storeNotes: data.details,
                        businessNotes: data.business.details,
                        timezone: data.timezone,
                        location: data.location,
                        squadCaptain: {
                            id: data.squad_captain.id,
                            firstName: data.squad_captain.first_name,
                            photo: data.squad_captain.photo,
                        },
                    },
                });
                resolve();
            })
                .catch(failedFetch);
        });
    };
};
