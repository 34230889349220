import { COLLECTIONS_FETCHING_STATUS_SET_IDLE, COLLECTIONS_FETCH_START, COLLECTIONS_FETCH_SUCCESS, COLLECTION_CLAIM_FAIL, COLLECTION_CLAIM_SUCCESS, COLLECTION_FETCH_ERROR, COLLECTION_FETCH_START, COLLECTION_FETCH_SUCCESS, COLLECTION_INDUCTIONS_FETCH_SUCCESS, COLLECTION_PAUSE_ERROR, COLLECTION_PAUSE_MODAL_CLOSE, COLLECTION_PAUSE_SET_ACTIVE_STEP, COLLECTION_PAUSE_SET_OPTION, COLLECTION_PAUSE_START, COLLECTION_PAUSE_SUCCESS, COLLECTION_SKIP_ERROR, COLLECTION_SKIP_MODAL_CLOSE, COLLECTION_SKIP_SET_ACTIVE_STEP, COLLECTION_SKIP_SET_OPTION, COLLECTION_SKIP_START, COLLECTION_SKIP_SUCCESS, COLLECTION_SWAP_ERROR, COLLECTION_SWAP_MODAL_CLOSE, COLLECTION_SWAP_SET_ACTIVE_STEP, COLLECTION_SWAP_SET_OPTION, COLLECTION_SWAP_START, COLLECTION_SWAP_SUCCESS, COLLECTION_UNASSIGN_ERROR, COLLECTION_UNASSIGN_MODAL_CLOSE, COLLECTION_UNASSIGN_SET_ACTIVE_STEP, COLLECTION_UNASSIGN_SET_OPTION, COLLECTION_UNASSIGN_START, COLLECTION_UNASSIGN_SUCCESS, COLLECTION_VALID_END_DATES_FETCH_SUCCESS, } from '@redux/actionTypes';
import initialState from '@redux/reducers/Collections/initialState';
import { saveCollectionDataToState } from '@redux/utilities/handleCollectionData';
import { FETCHING_STATUSES } from 'config/api';
import { MODAL_MODES } from 'helpers/collections';
import { getCurrentUserId } from 'helpers/user';
const { LOADING, SUCCESS, FAILED, IDLE } = FETCHING_STATUSES;
const ACTION_HANDLERS = {
    [COLLECTION_FETCH_START]: (state = initialState) => {
        return {
            ...state,
            collectionsFetchingStatus: LOADING,
        };
    },
    [COLLECTION_FETCH_ERROR]: (state = initialState) => {
        return {
            ...state,
            collectionsFetchingStatus: FAILED,
        };
    },
    [COLLECTION_FETCH_SUCCESS]: (state = initialState, action) => {
        // API always returns required_inductions_ids array as empty for individual collection
        // therefore we have to ignore it for now https://olio.atlassian.net/browse/FWH-716
        const { payload: { collectionId, validEndDates, type, frequency, nextCollection, endDate, storeId, collectionState, userId, notes, derestricted, donationConfirmedAt, }, } = action;
        const collectionData = {
            collectionId,
            validEndDates,
            type,
            frequency,
            nextCollection,
            endDate,
            storeId,
            collectionState,
            userId,
            notes,
            derestricted,
            donationConfirmedAt,
        };
        return saveCollectionDataToState(collectionData, state);
    },
    [COLLECTION_VALID_END_DATES_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload: { collectionId, validEndDates }, } = action;
        return {
            ...state,
            items: {
                ...state.items,
                [collectionId]: {
                    ...state.items[collectionId],
                    validEndDates,
                },
            },
        };
    },
    [COLLECTIONS_FETCH_START]: (state = initialState) => {
        return {
            ...state,
            collectionsFetchingStatus: LOADING,
        };
    },
    [COLLECTIONS_FETCH_SUCCESS]: (state = initialState, action) => {
        const { payload } = action;
        const { collections } = payload;
        let newState = { ...state };
        collections.forEach((collection) => {
            const { collectionId, type, frequency, nextCollection, endDate, validEndDates, storeId, userId, collectionState, notes, available, requiredInductionsIds, derestricted, donationConfirmedAt, } = collection;
            newState = saveCollectionDataToState({
                collectionId,
                type,
                frequency,
                nextCollection,
                endDate,
                validEndDates,
                storeId,
                userId,
                collectionState,
                notes,
                available,
                requiredInductionsIds,
                derestricted,
                donationConfirmedAt,
            }, newState);
        });
        return {
            ...newState,
            collectionsFetchingStatus: SUCCESS,
        };
    },
    [COLLECTION_UNASSIGN_SET_ACTIVE_STEP]: (state = initialState, action) => {
        const { payload } = action;
        const { step } = payload;
        return {
            ...state,
            unassign: {
                ...state.unassign,
                errors: undefined,
                step,
            },
        };
    },
    [COLLECTION_UNASSIGN_SET_OPTION]: (state = initialState, action) => {
        const { payload } = action;
        const { storeId, collectionId, selectedDate } = payload;
        return {
            ...state,
            unassign: {
                ...state.unassign,
                errors: undefined,
                selectedStoreId: storeId || state.unassign.selectedStoreId,
                selectedCollectionId: collectionId || state.unassign.selectedCollectionId,
                selectedDate: selectedDate || state.unassign.selectedDate,
            },
        };
    },
    [COLLECTION_UNASSIGN_SUCCESS]: (state = initialState) => {
        return {
            ...state,
            unassign: {
                ...state.unassign,
                modalMode: MODAL_MODES.SUCCESS,
                errors: undefined,
            },
        };
    },
    [COLLECTION_UNASSIGN_START]: (state = initialState) => {
        return {
            ...state,
            unassign: {
                ...state.unassign,
                errors: undefined,
            },
        };
    },
    [COLLECTION_UNASSIGN_ERROR]: (state = initialState, action) => {
        const { payload } = action;
        const { errors } = payload;
        return {
            ...state,
            unassign: {
                ...state.unassign,
                errors,
            },
        };
    },
    [COLLECTION_UNASSIGN_MODAL_CLOSE]: (state = initialState) => {
        return {
            ...state,
            unassign: initialState.unassign,
        };
    },
    [COLLECTION_SKIP_SET_ACTIVE_STEP]: (state = initialState, action) => {
        const { payload } = action;
        const { step } = payload;
        return {
            ...state,
            skip: {
                ...state.skip,
                errors: undefined,
                step,
            },
        };
    },
    [COLLECTION_SKIP_SET_OPTION]: (state = initialState, action) => {
        const { payload } = action;
        const { storeId, collectionId, selectedDate } = payload;
        return {
            ...state,
            skip: {
                ...state.skip,
                errors: undefined,
                selectedStoreId: storeId || state.skip.selectedStoreId,
                selectedCollectionId: collectionId || state.skip.selectedCollectionId,
                selectedDate: selectedDate || state.skip.selectedDate,
            },
        };
    },
    [COLLECTION_SKIP_SUCCESS]: (state = initialState) => {
        return {
            ...state,
            skip: {
                ...state.skip,
                modalMode: MODAL_MODES.SUCCESS,
                errors: undefined,
            },
        };
    },
    [COLLECTION_SKIP_START]: (state = initialState) => {
        return {
            ...state,
            skip: {
                ...state.skip,
                errors: undefined,
            },
        };
    },
    [COLLECTION_SKIP_ERROR]: (state = initialState, action) => {
        const { payload } = action;
        const { errors } = payload;
        return {
            ...state,
            skip: {
                ...state.skip,
                errors,
            },
        };
    },
    [COLLECTION_SKIP_MODAL_CLOSE]: (state = initialState) => {
        return {
            ...state,
            skip: initialState.skip,
        };
    },
    [COLLECTION_PAUSE_SET_ACTIVE_STEP]: (state = initialState, action) => {
        const { payload: { step }, } = action;
        return {
            ...state,
            pause: {
                ...state.pause,
                errors: undefined,
                step,
            },
        };
    },
    [COLLECTION_PAUSE_SET_OPTION]: (state = initialState, action) => {
        const { payload } = action;
        const { storeId, collectionId, selectedDate, selectedIncrement, modalMode } = payload;
        return {
            ...state,
            pause: {
                ...state.pause,
                errors: undefined,
                selectedStoreId: storeId || state.pause.selectedStoreId,
                selectedCollectionId: collectionId || state.pause.selectedCollectionId,
                selectedDate: selectedDate || state.pause.selectedDate,
                selectedIncrement: selectedIncrement || state.pause.selectedIncrement,
                modalMode: modalMode || state.pause.modalMode,
            },
        };
    },
    [COLLECTION_PAUSE_SUCCESS]: (state = initialState) => {
        return {
            ...state,
            pause: {
                ...state.pause,
                modalMode: MODAL_MODES.SUCCESS,
                errors: undefined,
            },
        };
    },
    [COLLECTION_PAUSE_START]: (state = initialState) => {
        return {
            ...state,
            pause: {
                ...state.pause,
                errors: undefined,
            },
        };
    },
    [COLLECTION_PAUSE_ERROR]: (state = initialState, action) => {
        const { payload: { errors }, } = action;
        return {
            ...state,
            pause: {
                ...state.pause,
                errors,
            },
        };
    },
    [COLLECTION_PAUSE_MODAL_CLOSE]: (state = initialState) => {
        return {
            ...state,
            pause: initialState.pause,
        };
    },
    [COLLECTION_SWAP_SUCCESS]: (state = initialState, action) => {
        const { newCollectionId } = action.payload;
        return {
            ...state,
            swap: {
                ...state.swap,
                modalMode: MODAL_MODES.SUCCESS,
                errors: undefined,
                newCollectionId,
            },
        };
    },
    [COLLECTION_SWAP_START]: (state = initialState) => {
        return {
            ...state,
            swap: {
                ...state.swap,
                errors: undefined,
            },
        };
    },
    [COLLECTION_SWAP_ERROR]: (state = initialState, action) => {
        const { payload: { errors }, } = action;
        return {
            ...state,
            swap: {
                ...state.swap,
                errors,
            },
        };
    },
    [COLLECTION_SWAP_MODAL_CLOSE]: (state = initialState) => {
        return {
            ...state,
            swap: initialState.swap,
        };
    },
    [COLLECTION_SWAP_SET_OPTION]: (state = initialState, action) => {
        const { payload } = action;
        const { storeId, collectionId, modalMode, swapForCollectionId } = payload;
        return {
            ...state,
            swap: {
                ...state.swap,
                errors: undefined,
                selectedStoreId: storeId || state.swap.selectedStoreId,
                selectedCollectionId: collectionId || state.swap.selectedCollectionId,
                modalMode: modalMode || state.swap.modalMode,
                swapForCollectionId: swapForCollectionId || state.swap.swapForCollectionId,
            },
        };
    },
    [COLLECTION_SWAP_SET_ACTIVE_STEP]: (state = initialState, action) => {
        const { payload } = action;
        const { step } = payload;
        return {
            ...state,
            swap: {
                ...state.swap,
                errors: undefined,
                step,
            },
        };
    },
    [COLLECTION_INDUCTIONS_FETCH_SUCCESS]: (state = initialState, action) => {
        const { collectionId, inductions } = action.payload;
        const requiredInductionsIds = inductions.map((induction) => induction.id);
        return {
            ...state,
            items: {
                ...state.items,
                [collectionId]: {
                    ...state.items[collectionId],
                    requiredInductionsIds,
                },
            },
        };
    },
    [COLLECTION_CLAIM_SUCCESS]: (state = initialState, action) => {
        const collectionId = action.payload.id;
        return {
            ...state,
            items: {
                ...state.items,
                [collectionId]: {
                    ...state.items[collectionId],
                    userId: Number(getCurrentUserId()),
                    available: false,
                },
            },
        };
    },
    [COLLECTION_CLAIM_FAIL]: (state = initialState, action) => {
        const errors = action.payload.errors;
        return {
            ...state,
            errors,
        };
    },
    [COLLECTIONS_FETCHING_STATUS_SET_IDLE]: (state = initialState) => {
        return {
            ...state,
            collectionsFetchingStatus: IDLE,
        };
    },
};
export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
