// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DocumentsList-module--dropdown--DhM4F{padding-top:1.5rem;padding-bottom:2rem}@media(min-width: 600px){.DocumentsList-module--countrySelector--iMqgB{width:390px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "DocumentsList-module--dropdown--DhM4F",
	"countrySelector": "DocumentsList-module--countrySelector--iMqgB"
};
export default ___CSS_LOADER_EXPORT___;
