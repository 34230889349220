import { jsx as _jsx } from "react/jsx-runtime";
import DOMPurify from 'dompurify';
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
    }
});
const SetInnerHtmlSafe = ({ children }) => {
    return (_jsx("span", { dangerouslySetInnerHTML: {
            __html: DOMPurify.sanitize(children),
        } }));
};
export default SetInnerHtmlSafe;
