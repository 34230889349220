import { useLayoutEffect } from 'react';
const useModalLockBodyScroll = () => {
    useLayoutEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'initial';
        };
    }, []);
};
export default useModalLockBodyScroll;
