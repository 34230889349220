// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SceneHeader-module--sceneHeader--z9UAl p{color:#474747}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sceneHeader": "SceneHeader-module--sceneHeader--z9UAl"
};
export default ___CSS_LOADER_EXPORT___;
