// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvailableCollectionsScene-module--info--GOe1C{margin:1.5rem 0}.AvailableCollectionsScene-module--info--GOe1C p{padding:inherit}.AvailableCollectionsScene-module--info--GOe1C p:first-of-type{padding-bottom:1rem}.AvailableCollectionsScene-module--icon--h_QZn{border-radius:24px;padding:3px;width:1.75rem;height:1.75rem}.AvailableCollectionsScene-module--iconOngoing--aROU1{color:#3b1869;background-color:#f3f1f4}.AvailableCollectionsScene-module--iconAdhoc--Q38Xq{color:#ff4c70;background-color:#fdf2eb}.AvailableCollectionsScene-module--type--JRBKs{display:flex;align-items:flex-start;gap:.5rem}.AvailableCollectionsScene-module--more--toVvN{padding-left:2.375rem}.AvailableCollectionsScene-module--more--toVvN a{text-decoration:underline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "AvailableCollectionsScene-module--info--GOe1C",
	"icon": "AvailableCollectionsScene-module--icon--h_QZn",
	"iconOngoing": "AvailableCollectionsScene-module--iconOngoing--aROU1 AvailableCollectionsScene-module--icon--h_QZn",
	"iconAdhoc": "AvailableCollectionsScene-module--iconAdhoc--Q38Xq AvailableCollectionsScene-module--icon--h_QZn",
	"type": "AvailableCollectionsScene-module--type--JRBKs",
	"more": "AvailableCollectionsScene-module--more--toVvN"
};
export default ___CSS_LOADER_EXPORT___;
