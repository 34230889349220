// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextField-module--textField--qdzpG input{background-color:rgba(0,0,0,0);border:none;border-bottom:1px solid #b7b7b7;border-radius:0;outline:none;height:3rem;width:100%;font-size:1rem;margin:0 0 8px;padding:0;box-shadow:none;box-sizing:content-box;transition:box-shadow .3s,border .3s}.TextField-module--textField--qdzpG .TextField-module--isRequired--tVYLI::before{content:\"*\";color:#981b1e}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "TextField-module--textField--qdzpG",
	"isRequired": "TextField-module--isRequired--tVYLI"
};
export default ___CSS_LOADER_EXPORT___;
