// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollectionActions-module--collectionActions--c6AEp{display:grid;gap:21px;padding:0 1.5rem 1.5rem}@media(min-width: 992px){.CollectionActions-module--collectionActions--c6AEp{display:grid;grid-template-columns:repeat(4, 1fr);column-gap:1rem}}@media(min-width: 992px)and (max-width: 1200px){.CollectionActions-module--collectionActions--c6AEp{display:grid;grid-template-columns:repeat(1, 1fr);column-gap:1rem}}.CollectionActions-module--collectionActions--c6AEp .CollectionActions-module--adhocButton--mgZ81{width:fit-content}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectionActions": "CollectionActions-module--collectionActions--c6AEp",
	"adhocButton": "CollectionActions-module--adhocButton--mgZ81"
};
export default ___CSS_LOADER_EXPORT___;
