import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchUser from '@redux/thunks/users/fetchUser';
import { MINIMUM_FOOD_HERO_RATING } from 'config/user';
import Debug from 'helpers/debug';
import { getCurrentUserId } from 'helpers/user';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
const debug = Debug('scenes:access-error-scene');
const AccessErrorScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const currentUserId = getCurrentUserId();
    const rating = useAppSelector((state) => state.users.items[currentUserId]?.rating?.rating);
    const ratingOutOf5 = rating / 2;
    const currentRatingText = t('register.food-waste-hero.rating-needs-updating.my_current_rating', {
        current_rating: `<b>${ratingOutOf5}</b>`,
    });
    const improveRatingText = t('register.food-waste-hero.rating-needs-updating.improve_rating', {
        minimum_rating: Number(MINIMUM_FOOD_HERO_RATING) / 2,
    });
    useEffect(() => {
        dispatch(fetchUser(currentUserId)).catch(debug);
    }, [currentUserId]);
    return (_jsx("main", { id: "access-error-scene", children: _jsxs("div", { className: "container", children: [_jsx("h1", { children: t('register.food-waste-hero.rating-needs-updating.title') }), _jsxs("p", { children: [t('register.food-waste-hero.rating-needs-updating.description'), _jsx("span", { className: "left-padded", children: _jsx("a", { href: "https://help.olioex.com/article/134-ratings-explained", children: t('register.food-waste-hero.rating-needs-updating.link-text') }) })] }), isNaN(rating) === false && (_jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: currentRatingText }) })), _jsx("p", { children: _jsx("strong", { children: _jsx(SetInnerHtmlSafe, { children: improveRatingText }) }) })] }) }));
};
export default AccessErrorScene;
