// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserInfo-module--userAvatar--VBwwD{position:relative;padding-top:2.5rem;padding-bottom:1rem}.UserInfo-module--userAvatar--VBwwD img{width:5rem;height:100%}@media(min-width: 992px){.UserInfo-module--userAvatar--VBwwD img{width:10rem}}.UserInfo-module--details--AV_7f{word-wrap:break-word;padding-bottom:19px}.UserInfo-module--details--AV_7f p{color:#6f6f6f;max-width:150px}@media(min-width: 992px){.UserInfo-module--details--AV_7f p{max-width:initial}}.UserInfo-module--details--AV_7f strong{color:#252525}.UserInfo-module--details--AV_7f .UserInfo-module--userLocation--R8Ap4{display:flex;justify-content:center;margin:0;padding:0}.UserInfo-module--details--AV_7f .UserInfo-module--userLocation--R8Ap4 .UserInfo-module--icon--xJ9w8{display:flex;align-items:center;margin-right:.25rem}.UserInfo-module--details--AV_7f .UserInfo-module--iconAndTitle--I0M9b:hover{background-color:#fff;color:#6f6f6f}.UserInfo-module--details--AV_7f .UserInfo-module--iconAndTitle--I0M9b:hover .UserInfo-module--icon--xJ9w8{color:#6f6f6f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userAvatar": "UserInfo-module--userAvatar--VBwwD",
	"details": "UserInfo-module--details--AV_7f",
	"userLocation": "UserInfo-module--userLocation--R8Ap4",
	"icon": "UserInfo-module--icon--xJ9w8",
	"iconAndTitle": "UserInfo-module--iconAndTitle--I0M9b"
};
export default ___CSS_LOADER_EXPORT___;
