// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WhatYouNeed-module--heading--tz4af{display:flex;flex-direction:row;padding-bottom:1rem;align-items:center;margin-top:2.5rem}@media(min-width: 600px){.WhatYouNeed-module--heading--tz4af{margin-top:5rem;padding-bottom:1.5rem}}.WhatYouNeed-module--heading--tz4af .WhatYouNeed-module--icon--l61Jv{width:34px;height:24px;padding-right:.625rem;color:#ff4c70}.WhatYouNeed-module--content--GpVza{color:#474747}.WhatYouNeed-module--content--GpVza p{padding-top:.5rem}@media(min-width: 600px){.WhatYouNeed-module--content--GpVza p{padding-top:1rem}}.WhatYouNeed-module--button--DA0zI{text-decoration:underline;color:#bb4291 !important;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "WhatYouNeed-module--heading--tz4af",
	"icon": "WhatYouNeed-module--icon--l61Jv",
	"content": "WhatYouNeed-module--content--GpVza",
	"button": "WhatYouNeed-module--button--DA0zI"
};
export default ___CSS_LOADER_EXPORT___;
