import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAccordion } from 'hooks/application';
import StoreCard from 'components/Stores/StoreCard';
const StoreList = ({ storeIds }) => {
    const { handleExpandClick, handleExpandKeyDown, isExpanded } = useAccordion({
        autoExpandedSections: [],
        expandMultiple: false,
    });
    return (_jsx(_Fragment, { children: storeIds.map((storeId) => {
            return (_jsx(StoreCard, { storeId: Number(storeId), handleExpandClick: handleExpandClick, handleExpandKeyDown: handleExpandKeyDown, isExpanded: isExpanded }, storeId));
        }) }));
};
export default StoreList;
