import * as routes from 'config/routes';
const nonFoodHeroRoutes = [
    { path: routes.LOGIN_URL },
    { path: routes.LOGIN_MAGIC_URL },
    { path: routes.LOGIN_EMAIL_REQUESTED_URL },
    { path: routes.REGISTER_URL },
    { path: routes.JOIN_INTRO_URL },
    { path: routes.ACCESS_ERROR_URL },
    { path: routes.ERROR_URL },
    { path: routes.LANDING_PAGE_URL },
];
export { nonFoodHeroRoutes };
