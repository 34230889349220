import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchBasket from '@redux/thunks/baskets/fetchBasket';
import Debug from 'helpers/debug';
import Article from './Article';
const debug = Debug('components:basket:item');
const Item = ({ basketId }) => {
    const dispatch = useAppDispatch();
    const basket = useAppSelector((state) => state?.baskets?.items[basketId]);
    const basketArticleIds = basket?.basketArticleIds;
    const requesterId = basket?.requesterId;
    useEffect(() => {
        if (undefined === basketArticleIds || undefined === requesterId) {
            dispatch(fetchBasket(basketId)).catch(debug);
        }
    }, []);
    return (_jsx(_Fragment, { children: basketArticleIds &&
            basketArticleIds.map((id) => (_jsx(Article, { basketArticleId: id }, id))) }));
};
export default Item;
