import { BECOME_SQUAD_CAPTAIN_FAIL, BECOME_SQUAD_CAPTAIN_SUCCESS, STEP_DOWN_AS_SQUAD_CAPTAIN_FAIL, STEP_DOWN_AS_SQUAD_CAPTAIN_SUCCESS, } from '@redux/actionTypes';
import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import { loggableError } from 'helpers/errors';
import { getCurrentUserId } from 'helpers/user';
const debug = Debug('redux:thunks:stores:toggleSquadCaptainRole');
const toggleSquadCaptainRole = ({ isSquadCaptain, storeId }) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const onFail = (error) => {
                const reason = loggableError(error);
                debug(`Failed ${isSquadCaptain === true ? 'unassigning' : 'assigning'} squad captain role`, reason);
                const eventToSend = isSquadCaptain === true ? STEP_DOWN_AS_SQUAD_CAPTAIN_FAIL : BECOME_SQUAD_CAPTAIN_FAIL;
                dispatch({
                    type: eventToSend,
                    payload: {
                        storeId,
                    },
                });
                reject(reason);
            };
            const url = isSquadCaptain === true
                ? `/api/v1/stores/${storeId}/unassign_captain`
                : `/api/v1/stores/${storeId}/assign_captain`;
            const body = isSquadCaptain === true ? null : { user_id: Number(getCurrentUserId()) };
            const eventToSend = isSquadCaptain === true ? STEP_DOWN_AS_SQUAD_CAPTAIN_SUCCESS : BECOME_SQUAD_CAPTAIN_SUCCESS;
            return axios
                .patch(url, body)
                .then(() => {
                dispatch({
                    type: eventToSend,
                    payload: {
                        storeId,
                    },
                    meta: {
                        analytics: {
                            eventName: eventToSend,
                            eventPayload: {
                                store_id: storeId,
                            },
                        },
                    },
                });
                resolve();
            })
                .catch(onFail);
        });
    };
};
export default toggleSquadCaptainRole;
