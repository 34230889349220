// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Location-module--root--kyYhN{display:flex}.Location-module--root--kyYhN .Location-module--storeAddress--Dxznb{margin-top:.25rem;font-weight:600;line-height:18px;display:flex;flex-wrap:wrap;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Location-module--root--kyYhN",
	"storeAddress": "Location-module--storeAddress--Dxznb"
};
export default ___CSS_LOADER_EXPORT___;
