import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import pick from 'lodash/pick';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import closeBasket from '@redux/thunks/baskets/closeBasket';
import fetchBasket from '@redux/thunks/baskets/fetchBasket';
import fetchBasketArticle from '@redux/thunks/baskets/fetchBasketArticle';
import { modalKeys, modalTrackingKeys } from 'config/modals';
import Debug from 'helpers/debug';
import { useModal } from 'hooks/application';
import Button from 'components/common/Button';
import { ConfirmationModal, ModalFooter, ModalTitle, } from 'components/common/Modal/ConfirmationModal';
import UserAvatar from 'components/common/UserAvatar';
const { CLOSE_BASKET_CONFIRMATION } = modalKeys;
const debug = Debug('components:basket:confirmation-modal');
const BasketConfirmationModal = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { closeModal, data: { basketId }, } = useModal(CLOSE_BASKET_CONFIRMATION);
    const basket = useAppSelector((state) => state.baskets.items[basketId]);
    const basketArticleIds = basket?.basketArticleIds;
    const articles = useAppSelector((state) => state.baskets.articles);
    const requesterId = useAppSelector((state) => state.baskets.items[basketId]?.requesterId);
    const { name: userName } = useAppSelector((state) => state.users.items[requesterId]);
    useEffect(() => {
        dispatch(fetchBasket(basketId)).catch(debug);
    }, []);
    useEffect(() => {
        if (basketArticleIds !== undefined) {
            basketArticleIds.forEach((id) => {
                dispatch(fetchBasketArticle(id)).catch(debug);
            });
        }
    }, [basketArticleIds]);
    const articleQuantity = useMemo(() => {
        if (articles === undefined || basketArticleIds === undefined) {
            return null;
        }
        const basketArticles = pick(articles, basketArticleIds);
        const articleValues = Object.values(basketArticles);
        const quantities = articleValues.map((article) => article.quantity);
        const initialReduceValue = 0;
        return quantities.reduce((a, b) => a + b, initialReduceValue);
    }, [articles, basketArticleIds]);
    const handleCloseBasket = () => {
        dispatch(closeBasket({ basketId }));
        closeModal();
    };
    return (_jsxs(ConfirmationModal, { id: "close-basket-confirmation-modal", modalKey: CLOSE_BASKET_CONFIRMATION, children: [_jsx(ModalTitle, { children: t('baskets.close-basket-confirmation-modal.header') }), _jsxs("p", { className: "modal-description", children: [_jsx("span", { children: t('baskets.close-basket-confirmation-modal.description1') }), _jsx("span", { className: "description-bold", children: t('baskets.close-basket-confirmation-modal.description2') }), _jsx("span", { children: t('baskets.close-basket-confirmation-modal.description3') })] }), _jsxs("div", { className: "requester-information", children: [_jsx(UserAvatar, { userId: requesterId, className: "requester-avatar" }), _jsxs("div", { children: [_jsx("h3", { children: userName }), _jsx("p", { children: articleQuantity && (_jsxs(_Fragment, { children: [_jsx("span", { children: t('baskets.close-basket-confirmation-modal.assigned-text') }), _jsx("span", { children: articleQuantity }), _jsx("span", { children: articleQuantity > 1
                                                ? t('baskets.close-basket-confirmation-modal.items-text')
                                                : t('baskets.close-basket-confirmation-modal.item-text') })] })) })] })] }), _jsxs(ModalFooter, { children: [_jsx(Button, { "data-tracking": modalTrackingKeys.reject, onClick: closeModal, secondary: true, children: t('baskets.close-basket-confirmation-modal.cancel-button') }), _jsx(Button, { "data-tracking": modalTrackingKeys.confirm, onClick: handleCloseBasket, children: t('baskets.close-basket-confirmation-modal.accept-button') })] })] }));
};
export default BasketConfirmationModal;
