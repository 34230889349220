import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from 'components/common/Button';
import SceneHeader from 'components/common/SceneHeader';
import { Spacer } from 'components/layout/primitives';
const DEFAULT_ERROR_CODE = '404';
const ERROR_NAMES = {
    400: 'clientError',
    401: 'unauthorized',
    404: 'notFoundError',
    500: 'internalError',
};
const ErrorScene = () => {
    const { t } = useTranslation();
    const { errorCode } = useParams() || {};
    const errorKey = () => ERROR_NAMES[errorCode] || ERROR_NAMES[DEFAULT_ERROR_CODE];
    return (_jsxs("div", { className: "container", children: [_jsx(Spacer, { x: "40px" }), _jsx(SceneHeader, { title: t(`errors.${errorKey()}.title`) }), _jsx(Spacer, { x: "40px" }), _jsxs("div", { className: "card", children: [_jsx("div", { className: "card-content", children: _jsx("p", { dangerouslySetInnerHTML: { __html: t(`errors.${errorKey()}.description`) } }) }), _jsx("div", { className: "card-action", children: _jsx(Button, { href: "/", children: t(`errors.${errorKey()}.button`) }) })] })] }));
};
export default ErrorScene;
