import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { trackEvent } from 'services/Analytics';
import { modalTrackingEvents } from 'config/modals';
import { useModal } from 'hooks/application';
import GenericModal from 'components/common/Modal/GenericModal';
import styles from './ConfirmationModal.module.scss';
const ConfirmationModal = ({ className, id, modalKey, actionOnClose, children, }) => {
    const { closeModal } = useModal(modalKey, { actionOnClose });
    const handleDismissClick = () => {
        trackEvent('MODAL_DISMISS', { modalKey });
        closeModal();
    };
    return (_jsx(GenericModal, { closeModal: closeModal, className: clsx(className, styles.confirmationModal), id: id, modalKey: modalKey, children: _jsxs("div", { className: styles.confirmationModalUpperSection, children: [_jsx("div", { className: styles.confirmationModalHeader, children: _jsx("button", { className: clsx('no-button-styling', styles.icon), onClick: handleDismissClick, type: "button", children: _jsx(MdOutlineCancel, { "data-testid": "closeIcon" }) }) }), children] }) }));
};
const ModalTitle = ({ children }) => {
    return _jsx("h2", { className: styles.modalTitle, children: children });
};
const ModalFooter = ({ children }) => {
    const openedModal = useSelector(({ application }) => application.openedModals.find(Boolean));
    const { key: modalKey } = openedModal;
    const childrenWithTracking = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                onClick: () => {
                    const trackingKey = child.props['data-tracking'];
                    if (Object.keys(modalTrackingEvents).some((key) => trackingKey === key)) {
                        trackEvent(modalTrackingEvents[trackingKey], { modalKey });
                    }
                    if (child.props.onClick) {
                        child.props.onClick();
                    }
                },
            });
        }
        return child;
    });
    return _jsx("div", { className: styles.buttonWrapper, children: childrenWithTracking });
};
export { ModalTitle, ModalFooter };
export default ConfirmationModal;
