import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { checkQuizAnswers } from 'helpers/inductions';
import Button from 'components/common/Button';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import { Stack } from 'components/layout/primitives';
const QuizFailure = ({ questions, selectedAnswers, hasVideo, playYoutubeVideo, retryQuiz, }) => {
    const { t, i18n } = useTranslation();
    const { correctAnswersCount, incorrectlyAnsweredQuestions } = checkQuizAnswers(questions, selectedAnswers);
    const incorrectAnswers = useMemo(() => {
        if (incorrectlyAnsweredQuestions.length === 1) {
            return incorrectlyAnsweredQuestions[0];
        }
        else {
            const formatter = new Intl.ListFormat(i18n.language, { style: 'long', type: 'conjunction' });
            const incorrectAnswers = incorrectlyAnsweredQuestions.map(String);
            return formatter.format(incorrectAnswers);
        }
    }, [incorrectlyAnsweredQuestions, i18n.language]);
    const resultText = t('inductions.page.show.quiz.results_description_2', {
        correct: `<b>${correctAnswersCount}</b>`,
        total: `<b>${questions.length}</b>`,
    });
    const incorrectAnswersText = t('inductions.page.show.quiz.results_description_3', {
        count: incorrectlyAnsweredQuestions.length,
        incorrectAnswers: `<b>${incorrectAnswers}</b>`,
    });
    return (_jsx("div", { className: "card induction-quiz-results", children: _jsx("div", { className: "card-content", children: _jsxs(Stack, { gap: "1rem", children: [_jsx("h2", { children: t('inductions.page.show.quiz.your_results') }), _jsx("p", { children: t('inductions.page.show.quiz.results_description_1') }), _jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: resultText }) }), hasVideo === true ? (_jsxs(_Fragment, { children: [_jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: incorrectAnswersText }) }), _jsx(Button, { onClick: playYoutubeVideo, children: t('inductions.page.show.quiz.play_video') })] })) : (_jsxs(_Fragment, { children: [_jsx("p", { children: t('inductions.page.show.quiz.please_remember') }), _jsx(Button, { onClick: retryQuiz, children: t('inductions.page.show.quiz.try_again') })] }))] }) }) }));
};
export default QuizFailure;
