const COLLECTION_TYPES = {
    ONGOING: 'ongoing',
    ADHOC: 'adhoc',
    TEMPORARY: 'temporary',
};
const COLLECTION_FREQUENCIES = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    FORTNIGHTLY: 'fortnightly',
};
export { COLLECTION_TYPES, COLLECTION_FREQUENCIES };
