// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvailableCollectionsListCard-module--lateBadge--QhmM9{display:flex;flex-direction:row;background-color:#fdf2eb;width:fit-content;border-radius:.25rem;padding:.125rem .5rem}.AvailableCollectionsListCard-module--lateBadge--QhmM9 .AvailableCollectionsListCard-module--label--Df3fz{padding-left:.5rem;font-size:.875rem;font-weight:600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lateBadge": "AvailableCollectionsListCard-module--lateBadge--QhmM9",
	"label": "AvailableCollectionsListCard-module--label--Df3fz"
};
export default ___CSS_LOADER_EXPORT___;
