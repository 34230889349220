import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdHelpOutline, MdHistory, MdLoop } from 'react-icons/md';
import { LOAD_AVAILABLE_COLLECTIONS, NO_COLLECTIONS_AVAILABLE } from '@redux/actionTypes';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences';
import { trackEvent } from 'services/Analytics';
import { MESSAGE } from 'config/alert';
import { FETCHING_STATUSES } from 'config/api';
import Debug from 'helpers/debug';
import { useFetchAvailableCollectionsWithStoresAndBusinesses } from 'hooks/collections';
import AvailableCollectionsList from 'components/AvailableCollections/AvailableCollectionsList';
import CollapsibleAlert from 'components/common/CollapsibleAlert';
import LoadingSpinner from 'components/common/LoadingSpinner';
import SceneHeader from 'components/common/SceneHeader';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import styles from './AvailableCollectionsScene.module.scss';
const debug = Debug('components:collections:available-collections');
const AvailableCollectionsScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { availableCollections, hasCollectionsFetched } = useFetchAvailableCollectionsWithStoresAndBusinesses();
    const currentUserFetchingStatus = useAppSelector((state) => state.users.currentUserFetchingStatus);
    useEffect(() => {
        if (hasCollectionsFetched === true && availableCollections.length === 0) {
            trackEvent(LOAD_AVAILABLE_COLLECTIONS, {
                businesses: 0,
                availableCollections: 0,
                ongoingCollections: 0,
                adhocCollections: 0,
                temporaryCollections: 0,
            });
            trackEvent(NO_COLLECTIONS_AVAILABLE);
        }
    }, [hasCollectionsFetched]);
    useEffect(() => {
        dispatch(fetchUserPreferences()).catch(debug);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(SceneHeader, { title: t('collection_slots.index.title') }), hasCollectionsFetched === true && availableCollections.length > 0 && (_jsxs(CollapsibleAlert, { icon: _jsx(MdHelpOutline, {}), title: t('available-collections.scene.info-header'), description: t('available-collections.scene.description1'), variant: MESSAGE, className: styles.info, children: [_jsx("p", { children: t('available-collections.scene.description2') }), _jsxs("p", { className: styles.type, children: [_jsx("span", { children: _jsx(MdLoop, { className: styles.iconOngoing }) }), _jsxs("span", { children: [_jsx("b", { children: t('collection_slots.index.type_filter.ongoing') }), t('available-collections.scene.ongoing')] })] }), _jsxs("p", { className: styles.type, children: [_jsx("span", { children: _jsx(MdHistory, { className: styles.iconAdhoc }) }), _jsxs("span", { children: [_jsx("b", { children: t('collection_slots.index.type_filter.adhoc') }), t('available-collections.scene.adhoc')] })] }), _jsx("p", { className: styles.more, children: _jsx(SetInnerHtmlSafe, { children: t('available-collections.scene.more', {
                                moreHighlightedLink: ` <a href=${t('available-collections.scene.more-link-url')}>${t('available-collections.scene.more-link-text')}</a>`,
                            }) }) })] })), hasCollectionsFetched === true && currentUserFetchingStatus === FETCHING_STATUSES.SUCCESS ? (_jsx(AvailableCollectionsList, { availableCollections: availableCollections })) : (_jsx(LoadingSpinner, { containerHeight: "400px" }))] }));
};
export default memo(AvailableCollectionsScene);
