import { useAppSelector } from '@redux/hooks';
import { selectAllDocuments } from '@redux/selectors/documents';
import { FETCHING_STATUSES } from 'config/api';
const { IDLE, LOADING } = FETCHING_STATUSES;
const useAllDocuments = () => {
    const documents = useAppSelector((state) => selectAllDocuments(state));
    const isLoadingDocuments = useAppSelector((state) => state.documents.documentsFetchingStatus === IDLE ||
        state.documents.documentsFetchingStatus === LOADING);
    return {
        data: documents,
        isLoading: isLoadingDocuments,
    };
};
export default useAllDocuments;
