import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppSelector } from '@redux/hooks';
import { modalKeys } from 'config/modals';
import BasketConfirmationModal from 'components/Baskets/BasketConfirmationModal';
const { CLOSE_BASKET_CONFIRMATION } = modalKeys;
const ApplicationModals = () => {
    const openedModals = useAppSelector((state) => state.application.openedModals);
    if (openedModals?.length === 0) {
        return null;
    }
    const isModalVisible = (key) => {
        return openedModals.filter((modal) => modal.key === key).length === 1;
    };
    return _jsx(_Fragment, { children: isModalVisible(CLOSE_BASKET_CONFIRMATION) && _jsx(BasketConfirmationModal, {}) });
};
export default ApplicationModals;
