// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YourCollectionSlotsWidget-module--card--wTZB3{height:fit-content}.YourCollectionSlotsWidget-module--card--wTZB3 .YourCollectionSlotsWidget-module--headingWrapper--jprYS{margin-bottom:1rem}.YourCollectionSlotsWidget-module--card--wTZB3 .YourCollectionSlotsWidget-module--cardButton--FVYzA{width:100%;margin-top:1.5rem}.YourCollectionSlotsWidget-module--card--wTZB3 .YourCollectionSlotsWidget-module--noSlotsTitle--a3_T3{padding-top:0}.YourCollectionSlotsWidget-module--card--wTZB3 .YourCollectionSlotsWidget-module--noSlotsDescription--VHopy{padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "YourCollectionSlotsWidget-module--card--wTZB3",
	"headingWrapper": "YourCollectionSlotsWidget-module--headingWrapper--jprYS",
	"cardButton": "YourCollectionSlotsWidget-module--cardButton--FVYzA",
	"noSlotsTitle": "YourCollectionSlotsWidget-module--noSlotsTitle--a3_T3",
	"noSlotsDescription": "YourCollectionSlotsWidget-module--noSlotsDescription--VHopy"
};
export default ___CSS_LOADER_EXPORT___;
