import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { isDevelopment, isTest } from 'helpers/environment'
import { loggableError } from 'helpers/errors'

const debug = Debug('services:analytics:airship')

const track = (eventName, eventPayload) => {
  if (isTest || isDevelopment) {
    return
  }

  return axios
    .post('/api/v1/event', {
      name: eventName,
      properties: eventPayload,
    })
    .then((_response) => {
      debug(`Track: ${eventName}, ${JSON.stringify(eventPayload)}`)
    })
    .catch((error) => debug(loggableError(error)))
}

export default { track }
