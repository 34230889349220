// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Note-module--wrapper--qY1xp{background-color:#f3f3f3;padding:.5rem;border-radius:8px}.Note-module--title--odYO9{font-size:1.125rem}@media(min-width: 992px){.Note-module--title--odYO9{padding-bottom:.5rem}}@media(max-width: 599px){.Note-module--title--odYO9{font-size:1rem}}.Note-module--text--AIWFG,.Note-module--text--AIWFG p,.Note-module--text--AIWFG strong,.Note-module--text--AIWFG a{font-size:.75rem}@media(min-width: 992px){.Note-module--text--AIWFG,.Note-module--text--AIWFG p,.Note-module--text--AIWFG strong,.Note-module--text--AIWFG a{font-size:1rem}}@media(max-width: 599px){.Note-module--text--AIWFG p{padding:.125rem 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Note-module--wrapper--qY1xp",
	"title": "Note-module--title--odYO9",
	"text": "Note-module--text--AIWFG"
};
export default ___CSS_LOADER_EXPORT___;
