import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFormatListBulleted, MdFormatListBulletedAdd, MdOutlineShoppingBag } from 'react-icons/md';
import { shallowEqual } from 'react-redux';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchMyActivity from '@redux/thunks/users/fetchMyActivity';
import Debug from 'helpers/debug';
import { getCurrentUserId } from 'helpers/user';
import Card from 'components/common/Cards/Card';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ActivityItem from './ActivityItem';
import styles from './MyVolunteerActivityWidget.module.scss';
const debug = Debug('components:dashboard:my-volunteer-activity-widget');
const MyVolunteerActivityWidget = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const activity = useAppSelector((state) => state.users.items[getCurrentUserId()]?.activity, shallowEqual);
    useEffect(() => {
        dispatch(fetchMyActivity()).catch(debug);
    }, []);
    return (_jsxs(Card, { className: styles.card, children: [_jsx(Card.Heading, { children: t('dashboard-scene.my-volunteer-activity.heading') }), _jsx(motion.div, { layout: true, transition: {
                    layout: { duration: 0.2 },
                }, children: activity === undefined ? (_jsx(LoadingSpinner, { containerHeight: "100px" })) : (_jsxs("div", { className: styles.grid, children: [_jsx(ActivityItem, { icon: _jsx(MdFormatListBulletedAdd, {}), name: t('dashboard-scene.my-volunteer-activity.listings-30-days.name'), value: activity.articlesLast30Days, description: t('dashboard-scene.my-volunteer-activity.listings-30-days.description') }), _jsx(ActivityItem, { icon: _jsx(MdFormatListBulleted, {}), name: t('dashboard-scene.my-volunteer-activity.total-listings.name'), value: activity.totalArticles, description: t('dashboard-scene.my-volunteer-activity.total-listings.description') }), _jsx(ActivityItem, { icon: _jsx(MdOutlineShoppingBag, {}), name: t('dashboard-scene.my-volunteer-activity.total-collections.name'), value: activity.totalCollections, description: t('dashboard-scene.my-volunteer-activity.total-collections.description') })] })) })] }));
};
export default MyVolunteerActivityWidget;
