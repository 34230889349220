import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import fetchCollection from '@redux/thunks/collections/fetchCollection';
import fetchCollectionInductions from '@redux/thunks/collections/fetchCollectionInductions';
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences';
import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses';
import fetchStores from '@redux/thunks/stores/fetchStores';
import { modalKeys } from 'config/modals';
import { AVAILABLE_COLLECTIONS_URL, MY_COLLECTIONS_URL } from 'config/routes';
import Debug from 'helpers/debug';
import { formatDateToTime24 } from 'helpers/time';
import { getCurrentUserId } from 'helpers/user';
import { useCollection } from 'hooks/collections';
import Late_Badge from 'images/Late_Badge.svg';
import carrotWithStore from 'images/carrotWithStore.webp';
import AvailableCollectionDetailsCard from 'components/AvailableCollections/AvailableCollectionDetailsCard';
import InductionsList from 'components/Inductions/InductionsList';
import CardWithButton from 'components/common/Cards/CardWithButton';
import LoadingSpinner from 'components/common/LoadingSpinner';
import SceneHeader from 'components/common/SceneHeader';
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe';
import styles from './AvailableCollectionDetailsScene.module.scss';
const debug = Debug('components:collections:available-collection-details');
const AvailableCollectionDetailsScene = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { collectionId } = useParams();
    const userId = Number(getCurrentUserId());
    const isClaimModalOpen = useAppSelector((state) => {
        const claimModal = state.application.openedModals.find((modal) => modal.key === modalKeys.CLAIM_COLLECTION_SUCCESS ||
            modal.key === modalKeys.CLAIM_COLLECTION);
        if (claimModal !== undefined)
            return true;
        return false;
    }, shallowEqual);
    const { data: collection, isLoading, isLoadingCollections, } = useCollection({
        collectionId,
        dataToInclude: { inductions: true },
    });
    const storeId = collection?.storeId;
    const store = collection?.store;
    const business = collection?.business;
    const businessId = store?.businessId;
    const inductions = collection?.requiredInductions;
    const nextCollection = collection?.nextCollection;
    const storeTimeZone = store?.timezone;
    const collectionTime = formatDateToTime24(nextCollection, storeTimeZone);
    const sortedInductions = useMemo(() => {
        const sortedResult = inductions !== undefined
            ? [...inductions].sort((a, b) => Number(a.isComplete) - Number(b.isComplete))
            : [];
        return sortedResult;
    }, [inductions]);
    const hasInductions = inductions?.length > 0;
    const isCollectionUnavailable = (isLoadingCollections === false && collection === undefined) ||
        collection?.collectionState === 'completed';
    useEffect(() => {
        dispatch(fetchCollection(collectionId)).catch(debug);
        dispatch(fetchCollectionInductions(collectionId)).catch(debug);
    }, [collectionId]);
    useEffect(() => {
        if (storeId !== undefined) {
            dispatch(fetchStores([storeId])).catch(debug);
        }
    }, [storeId]);
    useEffect(() => {
        if (businessId !== undefined) {
            dispatch(fetchBusinesses([businessId])).catch(debug);
        }
    }, [businessId]);
    useEffect(() => {
        const isCollectionClaimedByCurrentUser = collection?.userId === userId;
        if (isCollectionClaimedByCurrentUser === true && isClaimModalOpen === false) {
            const myCollectionDetailsUrl = `${MY_COLLECTIONS_URL}/${collectionId}?new=true`;
            navigate(myCollectionDetailsUrl);
        }
    }, [collection?.userId, userId]);
    useEffect(() => {
        dispatch(fetchUserPreferences()).catch(debug);
    }, []);
    if (isCollectionUnavailable) {
        return (_jsx(CardWithButton, { title: t('collection_slots.no_longer_available.title'), description: t('collection_slots.no_longer_available.subtitle'), description2: t('collection_slots.no_longer_available.description'), buttonText: t('collection_slots.no_longer_available.button_text'), buttonLink: `${window.location.origin}${AVAILABLE_COLLECTIONS_URL}`, icon: carrotWithStore }));
    }
    return (_jsxs("div", { id: "available-collection-details-scene", children: [_jsx(SceneHeader, { title: business?.name === undefined
                    ? t('collection_slot.index.title')
                    : `${business.name} | ${t('collection_slot.index.title')}`, description: t('collection_slot.index.description') }), isLoading === true &&
                (collection === undefined ||
                    collection?.business === undefined ||
                    collection?.store === undefined) ? (_jsx(LoadingSpinner, { containerHeight: "400px" })) : (_jsxs(_Fragment, { children: [collectionTime >= '22:00' && (_jsx("div", { className: styles.lateLabel, children: _jsxs("div", { className: styles.contentWrapper, children: [_jsx("img", { src: Late_Badge, alt: "Late Badge", className: styles.image }), _jsxs("div", { className: styles.wordWrapper, children: [_jsx("p", { className: styles.title, children: t('collection-slots.show.late-collection.title') }), _jsx("p", { children: _jsx(SetInnerHtmlSafe, { children: t('collection-slots.show.late-collection.description', {
                                                    moreInfoLink: ` <a target="_blank" class="${styles.link}" href=${t('collection-slots.show.late-collection.more-info-link')}>${t('collection-slots.show.late-collection.more-info')}</a>`,
                                                }) }) })] })] }) })), _jsx(AvailableCollectionDetailsCard, { collectionId: Number(collectionId) }), hasInductions === true && (_jsxs("div", { className: "required-inductions", children: [_jsx("h3", { children: t('collection-slots.show.inductions-title') }), _jsx(InductionsList, { description: t('collection-slots.show.inductions-description'), inductions: sortedInductions })] }))] }))] }));
};
export default memo(AvailableCollectionDetailsScene);
