import i18next from 'i18next'
import * as actionTypes from '@redux/actionTypes'

export const trackedEvents = [
  actionTypes.BASKET_CLOSED_SUCCESS,
  actionTypes.BASKET_ARTICLE_QUANTITY_ADJUST_SUCCESS,
  actionTypes.BASKET_FETCH_SUCCESS,
  actionTypes.COLLECTION_UNASSIGN_SUCCESS,
  actionTypes.COLLECTION_PAUSE_SUCCESS,
  actionTypes.COLLECTION_SWAP_SUCCESS,
  actionTypes.COLLECTION_CLAIM_SUCCESS,
  actionTypes.AUTHENTICATE_USER_SUCCESS,
  actionTypes.AUTHENTICATE_USER_ERROR,
  actionTypes.BECOME_SQUAD_CAPTAIN_SUCCESS,
  actionTypes.STEP_DOWN_AS_SQUAD_CAPTAIN_SUCCESS,
]

export const trackedRTKEvents = [
  'inductions/completeInduction/fulfilled',
  'preferences/updateUserPreferences/fulfilled',
]

export const getPayloadEnhancement = () => {
  return {
    locale: i18next.language,
    app: 'hub',
    platform: 'web',
  }
}
