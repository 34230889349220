// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IdCard-module--root--yj2Af{margin:1rem 0;overflow:hidden}@media(min-width: 600px){.IdCard-module--root--yj2Af{margin:2rem 1.5rem 1.5rem 0}}.IdCard-module--root--yj2Af .IdCard-module--header--iiqgf{background-color:#00a69c;padding:13px 1.25rem}@media(min-width: 600px){.IdCard-module--root--yj2Af .IdCard-module--header--iiqgf{padding:1.25rem 28px}}@media(max-width: 599px){.IdCard-module--root--yj2Af .IdCard-module--header--iiqgf img{height:22px}}.IdCard-module--root--yj2Af p{padding:0 !important}.IdCard-module--root--yj2Af strong{display:block}.IdCard-module--root--yj2Af .IdCard-module--content--Ln6gA{display:flex}.IdCard-module--root--yj2Af .IdCard-module--content--Ln6gA .IdCard-module--avatar--NNEtA{margin:1rem}@media(min-width: 600px){.IdCard-module--root--yj2Af .IdCard-module--content--Ln6gA .IdCard-module--avatar--NNEtA{margin:1.5rem 1.5rem 33px}}.IdCard-module--root--yj2Af .IdCard-module--content--Ln6gA .IdCard-module--userDetails--Edp40{padding:1rem 1.5rem}@media(min-width: 600px){.IdCard-module--root--yj2Af .IdCard-module--content--Ln6gA .IdCard-module--userDetails--Edp40{padding:1.5rem;padding-left:0}}.IdCard-module--root--yj2Af .IdCard-module--content--Ln6gA .IdCard-module--userDetails--Edp40 h3,.IdCard-module--root--yj2Af .IdCard-module--content--Ln6gA .IdCard-module--userDetails--Edp40 strong{padding-bottom:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IdCard-module--root--yj2Af",
	"header": "IdCard-module--header--iiqgf",
	"content": "IdCard-module--content--Ln6gA",
	"avatar": "IdCard-module--avatar--NNEtA",
	"userDetails": "IdCard-module--userDetails--Edp40"
};
export default ___CSS_LOADER_EXPORT___;
