import axios from 'helpers/axios';
import Debug from 'helpers/debug';
import Env from 'helpers/environment';
import { loggableError } from 'helpers/errors';
const debug = Debug('helpers:map');
const MAP_URL_PREFIX = 'https://www.google.com/maps/dir/?api=1&destination=';
export const getMapUrl = (location) => `${MAP_URL_PREFIX}${location.latitude},${location.longitude}`;
export const getSignedStaticMapUrl = async ({ latitude, longitude, width = 350, height = 350 }) => {
    const failedFetch = (error) => {
        const reason = loggableError(error);
        debug(`Failed signing Google Maps url`, reason);
    };
    const urlToSign = `https://maps.googleapis.com/maps/api/staticmap?key=${Env.PUBLIC_GOOGLE_MAPS_API_KEY}&center=${latitude},${longitude}&markers=color:0xff4c70%7C${latitude},${longitude}&size=${width}x${height}&zoom=16&scale=2`;
    return await axios
        .post('/api/v1/utilities/sign_url', {
        url: urlToSign,
    })
        .then((response) => {
        const signedUrl = response.data.signed_url;
        return signedUrl;
    })
        .catch(failedFetch);
};
