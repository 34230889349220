import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MdOutlinePlace } from 'react-icons/md';
import InformationIcon from './InformationIcon';
import styles from './Location.module.scss';
const Location = ({ storeAddress, type }) => {
    return (_jsxs("div", { className: styles.root, children: [_jsx(InformationIcon, { testId: "locationIcon", Icon: MdOutlinePlace, collectionType: type }), _jsx("address", { className: styles.storeAddress, children: storeAddress &&
                    Object.values(storeAddress).map((addressLine, index) => {
                        return _jsx("span", { children: addressLine }, index);
                    }) })] }));
};
export default Location;
