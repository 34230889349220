import axios from 'axios';
import { CANCELED_ERROR } from 'helpers/errors';
const instance = axios.create();
instance.defaults.headers.common['Content-Type'] = 'application/json';
const CSRF_TOKEN = document.querySelector("meta[name='csrf-token']")?.getAttribute('content');
if (CSRF_TOKEN) {
    instance.defaults.headers.common['X-CSRF-Token'] = CSRF_TOKEN;
}
const setupInterceptors = () => {
    const pendingRequests = new Map();
    const getRequestKey = (config) => {
        const { method, url, params, data } = config;
        return [method, url, JSON.stringify(params), JSON.stringify(data)].join('&');
    };
    instance.interceptors.request.use((config) => {
        const requestKey = getRequestKey(config);
        if (!pendingRequests.has(requestKey)) {
            const controller = new AbortController();
            config.signal = controller.signal;
            pendingRequests.set(requestKey, controller);
        }
        else {
            const dontCancelRequest = !requestKey.includes('get');
            if (dontCancelRequest) {
                return config;
            }
            pendingRequests.get(requestKey).abort('Cancelling duplicate request');
            deletePendingRequest(config);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    const deletePendingRequest = (config) => {
        const requestKey = getRequestKey(config);
        if (pendingRequests.has(requestKey)) {
            pendingRequests.delete(requestKey);
        }
    };
    instance.interceptors.response.use((response) => {
        deletePendingRequest(response.config);
        return response;
    }, (error) => {
        if (error.name === CANCELED_ERROR) {
            deletePendingRequest(error.config);
        }
        return Promise.reject(error);
    });
};
setupInterceptors();
export default instance;
