// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Inline-module--root--IxYBV{display:flex;flex-direction:column;gap:var(--gap)}.Inline-module--root--IxYBV .Inline-module--child--_O0Lw{flex:1}@media(min-width: 600px){.Inline-module--root--IxYBV{flex-direction:row}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Inline-module--root--IxYBV",
	"child": "Inline-module--child--_O0Lw"
};
export default ___CSS_LOADER_EXPORT___;
