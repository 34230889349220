// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DocumentCard-module--documentCard--Evd9u{height:12.375rem}@media(min-width: 600px){.DocumentCard-module--documentCard--Evd9u{height:13.75rem}}.DocumentCard-module--documentCard--Evd9u .DocumentCard-module--title--wDX4H{padding-bottom:.25rem}.DocumentCard-module--documentCard--Evd9u .DocumentCard-module--linkAndIcon--IYiHZ{display:flex;align-items:center}.DocumentCard-module--documentCard--Evd9u .DocumentCard-module--linkAndIcon--IYiHZ .DocumentCard-module--link--ZbQiA,.DocumentCard-module--documentCard--Evd9u .DocumentCard-module--linkAndIcon--IYiHZ .DocumentCard-module--icon--aRnME{color:#3b1869}.DocumentCard-module--documentCard--Evd9u .DocumentCard-module--linkAndIcon--IYiHZ .DocumentCard-module--link--ZbQiA{border-bottom:.125rem solid #3b1869}.DocumentCard-module--documentCard--Evd9u .DocumentCard-module--linkAndIcon--IYiHZ .DocumentCard-module--icon--aRnME{background-color:#dedbec;border-radius:50%;padding:4.5px;margin-right:.5rem;line-height:1;width:33px;height:33px}.DocumentCard-module--documentCard--Evd9u .DocumentCard-module--content--qigs8{display:flex;flex-direction:column;height:100%;justify-content:space-between}.DocumentCard-module--documentCard--Evd9u .DocumentCard-module--description--xQ0LI{text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;overflow-wrap:anywhere}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentCard": "DocumentCard-module--documentCard--Evd9u",
	"title": "DocumentCard-module--title--wDX4H",
	"linkAndIcon": "DocumentCard-module--linkAndIcon--IYiHZ",
	"link": "DocumentCard-module--link--ZbQiA",
	"icon": "DocumentCard-module--icon--aRnME",
	"content": "DocumentCard-module--content--qigs8",
	"description": "DocumentCard-module--description--xQ0LI"
};
export default ___CSS_LOADER_EXPORT___;
